/* eslint-disable react-hooks/rules-of-hooks */
import { db, fetchCollection } from "@kanpla/system";
import { EnhancedFlexMenu, FlexMenu, Product } from "@kanpla/types";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/contextProvider";
import { useContainer } from "unstated-next";

interface Props {
  products: Array<Product>;
}

const getMenus = ({ products }: Props) => {
  const { week } = useContainer(AppContext);
  const [menus, setMenus] = useState<Array<EnhancedFlexMenu>>([]);
  const productsTrigger = products.length;
  const weekTrigger = week[0].seconds;

  const getMenus = async () => {
    const promises = products.map(async (product) => {
      const menuDocuments = await fetchCollection<FlexMenu>(
        db
          .collection("flexMenus")
          .where("productId", "==", product.id)
          .where("week", "==", week[0])
      );
      const menuResult = menuDocuments[0];

      const menuData = menuResult;

      const completeData: EnhancedFlexMenu = {
        ...menuData,
        product,
        productId: product?.id,
      };

      return completeData;
    });

    const allResults = await Promise.all(promises);

    // if (allResults.length > 0)s
    setMenus(allResults);
  };

  useEffect(() => {
    getMenus();
  }, [productsTrigger, weekTrigger]);

  return { menus };
};

export default getMenus;
