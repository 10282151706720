import { fn } from "@kanpla/system";
import { Popup } from "@kanpla/types";
import React, { useEffect } from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Banner from "./Banner";
import Interaction from "./Interaction";
import Modal from "./Modal";

const Provider = () => {
  const { child, school } = useContainer(AppContext);
  const callPopups = fn.httpsCallable("popups-fetchPopup");
  const [savedPopup, setSavedPopup] = useLocalstorageState<Popup>(
    "saved-popup",
    null
  );

  const fetchPopup = async () => {
    try {
      if (!child?.id || !child?.schoolId) return;

      const popup = await callPopups({
        childId: child.id,
        schoolId: child.schoolId,
      });

      setSavedPopup(popup.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!child?.id || !school?.id) return;

    // Don't get a popup if there is a not closed one
    if (!savedPopup || savedPopup === null) fetchPopup();

    // Run function every 5 minuts to check for popups
    const interval = setInterval(() => {
      if (savedPopup && savedPopup !== null) return;

      fetchPopup();
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  }, [child?.id, school?.id, savedPopup]);

  if (!child || !school) return null;

  if (!savedPopup) return null;

  if (savedPopup.type === "banner")
    return <Banner popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "modal")
    return <Modal popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "interaction")
    return (
      <Interaction popup={savedPopup} closePopup={() => setSavedPopup(null)} />
    );

  return null;
};

export default Provider;
