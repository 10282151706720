import { faCards } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: () => void;
};

const MoreProductsButton = ({ onClick }: Props) => {
  const {t} = useTranslation(["mealplan2"])

  return (
    <div className="flex items-center my-12 justify-center">
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={faCards} className="mr-1" />
        {t("mealplan2:add-more")}
      </Button>
    </div>
  );
};

export default MoreProductsButton;
