import { faSignOutAlt } from "@fortawesome/pro-light-svg-icons";
import { message } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ButtonLine from "./ButtonLine";

const Logout = () => {
  const { setChildId, setMenuOpen, auth } = useContainer(AppContext);
  const router = useRouter();
  const { t } = useTranslation(["translation", "settings"]);

  return (
    <ButtonLine
      id="action-logOut"
      label={t("translation:log-out")}
      onClick={async () => {
        try {
          setChildId(null);
          setChildId(null);
          setMenuOpen(false);
          await auth.signOut();
          router.push("/login");
        } catch (e) {
          console.error(e);
          message.error(
            e?.message || t("settings:message.error.problem-logging-out")
          );
        }
      }}
      icon={faSignOutAlt}
      // gray
      // className="text-text-secondary"
      // className="mt-16 rounded-lg border shadow-sm active:shadow-none transform active:translate-y-0.5 shadow-divider-main"
    />
  );
};

export default Logout;
