import { useFetchDocument } from "@kanpla/system";
import { Homescreen, Product } from "@kanpla/types";
import { AppContext } from "apps/frontend/components/contextProvider";
import React from "react";
import { useContainer } from "unstated-next";
import SingleProduct from "./SingleProduct";

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const SingleProductBlock = ({ singleBlock }: Props) => {
  const productId = singleBlock?.props?.productId;

  const { module } = useContainer(AppContext);
  const [product, , productLoading] = useFetchDocument<Product>({
    collection: "products",
    id: productId,
  });

  if (!productId) return null;

  if (productLoading)
    return <div className="w-full h-full rounded-lg loader max-w-sm"></div>;

  return (
    <div className="max-w-sm">
      <SingleProduct product={product} module={module} />
    </div>
  );
};

export default SingleProductBlock;
