import { useWindowSize } from "@kanpla/system";
import { TimeNavigation } from "@kanpla/types";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import AnonymousTabs from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";
import DashboardSkeleton from "./DashboardSkeleton";
import FirstChild from "./FirstChild";
import FooterCanteen from "./FooterCanteen";
import Introduction from "./introduction";
import AddClassInfo from "./modals/AddClassInfo";
import AddReferenceInfo from "./modals/AddReferenceInfo";
import AnonymousModal from "./modals/Anonymous/AnonymousModal";
import EmptyNamePopup from "./modals/EmptyNamePopup";
import History from "./modals/History";
import NegativeBalancePopup from "./modals/NegativeBalancePopup";
import PleasePay from "./modals/PleasePay";
import SubscriptionPopup from "./modals/SubscriptionPopup";
import Navbar from "./Navbar";
import Settings from "./settings/index";
import SalesplaceEdit from "./settings/salesplace/SalesplaceEdit";
import Tabs from "./Tabs";

interface Props {
  viewName: string;
  children: any;
  timeNavigation?: TimeNavigation;
  withTabs?: boolean;
  disableIfBasket?: boolean;
  noPaddingOnTop?: boolean;
  noPadding?: boolean;
  /** Dynamic tabs show dynamically links to modules
   * @version 3.0
   */
  newDynamicTabs?: boolean;
}

const Wrapper = (props: Props) => {
  const { t } = useTranslation(["translation"]);

  const {
    children,
    pleasePayOpen,
    setPleasePayOpen,
    deficit,
    school,
    appLoading,
    innerAppLoading,
    modules,
    module,
    customBranding,
    moduleLoading,
    auth,
    historyOpen,
    setHistoryOpen,
    child,
    openSalesplaceModal,
    setOpenSalesplaceModal,
    os,
  } = useContainer(AppContext);

  /** Check how many available modules are, add or remove padding to the wrapper accordingly  */
  const activeModules = modules?.filter(
    (module) =>
      module?.scope?.generatedSchoolIds?.includes(school?.id) &&
      !module?.displayOptions?.hideMobileTab
  );

  const { width: screenWidth } = useWindowSize();

  return (
    <div
      id="dashboard"
      className="w-full flex flex-col justify-between relative min-h-full"
    >
      <Head>
        {module && (module.displayName || module.name) && (
          <title>
            {module.displayName || module.name} |{" "}
            {customBranding?.name
              ? `${customBranding.name} | powered by Kanpla`
              : "Kanpla"}
          </title>
        )}
      </Head>
      <Navbar
        timeNavigation={props.timeNavigation}
        disableIfBasket={props.disableIfBasket}
        module={module}
      />

      {/* {hasKanplaGo && usesCredit && !card && <CardBanner />} */}

      <div
        className="min-h-full flex-1 flex flex-col"
        style={{
          marginBottom:
            screenWidth < 768 && activeModules.length > 1 ? "80px" : "0px",
        }}
      >
        <div className="relative flex flex-col h-full min-h-full flex-1 container mx-auto md:px-2 lg:px-4">
          {/* If no user created, create it! */}
          {!appLoading &&
            !innerAppLoading &&
            children != null &&
            !children.length &&
            auth.user && <FirstChild />}

          {(children === null || children?.length > 0 || !auth.user) && (
            <>
              {(appLoading || innerAppLoading) && moduleLoading && (
                <DashboardSkeleton />
              )}
              <div
                className={`transition ${
                  (appLoading || innerAppLoading) && moduleLoading
                    ? "absolute opacity-0 inset-x-0"
                    : "relative"
                }`}
              >
                {props.children}
              </div>
              <FooterCanteen />
            </>
          )}
        </div>
      </div>

      {/** Mobile bottom navigation bar */}
      {screenWidth <= 768 && (auth.user ? <Tabs /> : <AnonymousTabs />)}

      {/* App Settings */}
      <Settings backLabel={t("translation:back")} />

      {/* Temporary, until we update all apps, then revert this commit */}
      {(os === "iOS" || os === "iPadOS") && (
        <SalesplaceEdit
          open={openSalesplaceModal}
          setOpen={setOpenSalesplaceModal}
          school={school}
          child={child}
        />
      )}

      {/* Please Pay Modal */}
      <PleasePay
        open={pleasePayOpen}
        setOpen={setPleasePayOpen}
        deficit={deficit}
        paymentMethod={module?.paymentMethod}
      />

      <AddClassInfo />
      <AddReferenceInfo />

      <EmptyNamePopup />

      <NegativeBalancePopup />

      {/* Temporary Milk Popup */}
      <SubscriptionPopup />

      {/* <Introduction /> */}

      <AnonymousModal />

      <History open={historyOpen} setOpen={setHistoryOpen} />
    </div>
  );
};

export default Wrapper;
