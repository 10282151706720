import { faArrowRightLong } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";

interface Props {
  text: string;
  onClick?: () => void;
  loading?: boolean;
  withIcon?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dataCy?: string;
}

const ConfirmButton = (props: Props) => {
  const {
    text,
    onClick = () => null,
    loading = false,
    withIcon = false,
    className = "",
    style = {},
    dataCy = ""
  } = props;
  const { t } = useTranslation(["translation"]);

  return (
    <Button
      onClick={onClick}
      type="primary"
      size="large"
      htmlType="submit"
      className={`${
        loading ? "animate-pulse" : ""
      } w-auto flex items-center justify-center ${className}`}
      style={style}
      data-cy={dataCy}
    >
      <span className="text-lg text-current">
        {loading ? t("translation:loading") : text}
      </span>
      {withIcon && !loading && (
        <FontAwesomeIcon icon={faArrowRightLong} className="ml-4" />
      )}
      {loading && <Spinner size="small" color="white" className="ml-4" />}
    </Button>
  );
};

export default ConfirmButton;
