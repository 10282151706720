import { priceFormatter } from "@kanpla/system";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const PosoneBalance = () => {
  const { t } = useTranslation(["design"]);
  const {
    posoneBalance,
    posoneBalanceLoading,
    posonePartnerData,
    customBranding,
  } = useContainer(AppContext);

  return (
    <Button
      href={`//${
        posonePartnerData?.prefix || customBranding?.url
      }.smartbetaling.dk/#addfunds`}
      target="_blank"
      title={t("design:refuel-via-posone")}
      type="text"
    >
      <span
        className={`font-semibold ${
          posoneBalanceLoading ? "animate-pulse" : ""
        } bg-background-secondary p-2 px-3 rounded -mx-4`}
        style={{ fontSize: 13, lineHeight: 1.2 }}
      >
        {priceFormatter(posoneBalance)}
      </span>
    </Button>
  );
};

export default PosoneBalance;
