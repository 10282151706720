import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateConfigTotal,
  configToDisplayString,
  editConfigAmount,
  priceFormatter,
} from "@kanpla/system";
import {
  BasketItemConfig,
  CombinedOfferItem,
  OrderConfig,
  OrderOrder,
  OrderOrderProduct,
  Plugins,
} from "@kanpla/types";
import {
  Badge,
  Image,
  InputAmount,
  TimeInputDisplay,
  TimeSelect,
  TooltipHiddenPrices,
} from "@kanpla/ui";
import { Button, Tooltip } from "antd";
import classnames from "classnames";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import { EditModeType, isEditModeEnabled } from "./BasketListTypes";

interface BasketListItemProps {
  item: BasketItemConfig;
  editMode?: boolean;
  editType?: EditModeType;
  basket?: OrderOrder;
  setBasket?: (order: OrderOrder) => void;
  isLast?: boolean;
  hasGap?: boolean;
  hideDeleteButton?: boolean;
}

export const BasketListItem: FC<BasketListItemProps> = ({
  editMode = false,
  editType = "both",
  item: { productId, config, price, name, photo },
  basket: basketFromProps,
  setBasket: setBasketFromProps,
  isLast,
  hasGap,
  hideDeleteButton = false,
}) => {
  const { i18n, t } = useTranslation("mealplan2");

  const { items, module } = useContainer(MealplanContext);
  const {
    activePlugins,
    dateSeconds,
    basket: basketFromContext,
    setBasket: setBasketFromContext,
  } = useContainer(AppContext);

  const basket = basketFromProps || basketFromContext;
  const setBasket = setBasketFromProps || setBasketFromContext;

  const isRequiredProduct =
    productId === module?.plugins?.requiredProduct?.productId;
  const isAdhoc = productId.startsWith("ad-hoc-");
  const isEditable = editMode && !isAdhoc && !isRequiredProduct;
  const plugins = module?.plugins || ({} as Plugins.Array);

  // Original amount is used for pay-per-order
  const [originalAmount] = useState(config.amount);

  const amountEditable = isEditModeEnabled(editType, "amount");
  const timeEditable = isEditModeEnabled(editType, "time");

  const product: CombinedOfferItem = items?.find(
    (p: CombinedOfferItem) => p.productId === productId
  );
  const { photo: photoFromOffer, name: productName } =
    product || ({} as CombinedOfferItem);

  const totalConfigPrice = calculateConfigTotal(config);

  const { hidePrices } = module?.config || {};

  const configText = useMemo(() => configToDisplayString(config), [config]);

  const isSignupOfferProduct =
    activePlugins.signupOffer && plugins?.signupOffer?.productId === productId;

  const isPayPerOrder = activePlugins?.payPerOrder;

  let stock = product?.dates?.[dateSeconds]?.stock;
  if (isSignupOfferProduct && stock > 1) stock = 1;

  const changeAmount = useCallback(
    (value: number) => {
      setBasket(
        editConfigAmount({
          order: basket,
          productId,
          choice: config.options,
          amountChange: value,
          replaceAmount: true,
        })
      );
    },
    [basket, setBasket, productId, config.options]
  );

  const setTime = useCallback(
    (newTimeValue: number) => {
      if (newTimeValue && newTimeValue === config.deliveryTime) return;

      const targetProduct: OrderOrderProduct = basket[productId];
      const productConfigs = targetProduct.config.map((c: OrderConfig) => {
        if (c.uid !== config.uid) return c;

        return {
          ...config,
          deliveryTime: newTimeValue,
        };
      });

      setBasket({
        ...basket,
        [productId]: {
          ...targetProduct,
          config: productConfigs,
        },
      });
    },
    [config, basket, productId, setBasket]
  );

  return (
    <div className={classnames({ "mb-3": editMode && !isLast })}>
      <div className="text-text-primary">
        <div
          className={`${
            hasGap ? "gap-x-16" : ""
          } flex justify-between items-center py-2 w-full h-full`}
        >
          <div className="flex items-center flex-shrink">
            <Badge count={config.amount} hidden={isEditable}>
              <div
                className="h-16 w-16 rounded-lg bg-background-secondary flex-shrink-0 relative overflow-hidden border border-divider-main"
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {photo || photoFromOffer ? (
                  <Image
                    src={`${photo || photoFromOffer}?w=300`}
                    alt={productName || name}
                    className="absolute inset-0 min-w-full min-h-full h-full w-full object-cover object-center shadow-inner"
                  />
                ) : (
                  <div
                    className="absolute w-full h-full bg-primary-main flex items-center justify-center"
                    style={{ backgroundColor: product?.color }}
                  >
                    <FontAwesomeIcon icon={faCameraSlash} />
                  </div>
                )}
              </div>
            </Badge>
            <div
              className="text-text-secondary w-full md:w-auto ml-3"
              data-cy="product-order-name"
            >
              <p className="font-medium" title={productName || name}>
                {productName || name}
                {isAdhoc && (
                  <Tooltip title="Købt i kantinen" placement="right">
                    <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-primary-dark">
                      <FontAwesomeIcon icon={faStore} />
                    </span>
                  </Tooltip>
                )}
              </p>
              {config.options && (
                <>
                  {configText && (
                    <p className="text-sm mt-1 text-text-secondary">
                      {configText}
                    </p>
                  )}
                  {config?.deliveryTime &&
                    !(activePlugins.timeInput && timeEditable && editMode) && (
                      <div className="mt-1 text-sm text-text-secondary">
                        <TimeInputDisplay timeInput={config.deliveryTime} />
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="h-full whitespace-nowrap flex items-center">
            <div className="text-primary-dark flex items-center py-1 text-right">
              <p className="text-right">
                {priceFormatter(
                  config.amount * (price + totalConfigPrice),
                  {
                    language: i18n.language,
                  },
                  false,
                  hidePrices,
                  {
                    wrapper: () => "-",
                    component: () => <TooltipHiddenPrices />,
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isEditable && (
        <div className="flex items-center justify-between">
          <div className="flex items-end">
            {amountEditable && editMode && (
              <InputAmount
                amount={config.amount}
                setAmount={(value: number) => changeAmount(value)}
                minAmount={hideDeleteButton ? 0 : 1}
                maxAmount={
                  isSignupOfferProduct
                    ? 1
                    : isPayPerOrder
                    ? originalAmount
                    : stock
                }
                size="small"
                className="mr-3"
              />
            )}
            {editMode && activePlugins.timeInput && timeEditable && (
              <TimeSelect
                interval={plugins.timeInput?.interval}
                endAt={plugins.timeInput?.endAt}
                startAt={plugins.timeInput?.startAt}
                /** Sometimes the deliveryTime is undefined */
                value={config.deliveryTime || plugins.timeInput?.startAt}
                onChange={setTime}
                defaultText={t("default-delivery-time")}
              />
            )}
          </div>
          {amountEditable && !hideDeleteButton && (
            <Button
              className="ml-3"
              type="primary"
              danger
              onClick={() => changeAmount(0)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
