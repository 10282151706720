import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onClick: () => void;
  label: string;
  className?: string;
  icon?: any;
}

const SettingsItem = ({ onClick, label, className, icon }: Props) => {
  return (
    <button
      className={`py-4 px-3 block w-full text-left border-b border-divider-main text-text-secondary hover:text-text-primary transition duration-200 hover:bg-background-secondary ${className}`}
      onClick={onClick}
    >
      {label}
      {icon && <FontAwesomeIcon icon={icon} size="sm" className="ml-3" />}
    </button>
  );
};

export default SettingsItem;
