import { SubscriptionProduct } from "@kanpla/types";
import { Category } from "@kanpla/ui";
import { useContext } from "react";
import { SubscriptionContext } from "../.";
import Product from "./Product";

const Products = () => {
  const { periods } = useContext(SubscriptionContext);

  return (
    <>
      {Object.keys(periods).map((type) => (
        <ProductCategory type={type} />
      ))}
    </>
  );
};

export default Products;

const ProductCategory = ({ type }) => {
  const { periods } = useContext(SubscriptionContext);

  const allAvailableProducts = periods[type]
    ? periods[type].reduce(
        (acc, period) => ({
          ...acc,
          ...period.products.reduce(
            (a, product) => ({
              ...a,
              [product.id]: product,
            }),
            {}
          ),
        }),
        {}
      )
    : {};

  return (
    <Category name={type} top={0} noMarginTop>
      {Object.values(allAvailableProducts).map(
        (product: SubscriptionProduct) => (
          <Product
            product={product}
            periods={periods[type].filter((p) =>
              p.products.some((pr) => pr.id === product.id)
            )}
          />
        )
      )}
    </Category>
  );
};
