import { ActionsAuthenticationModal, PaymentProvider } from "@kanpla/types";
import { AppContext } from "apps/frontend/components/contextProvider";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../..";
import Slider from "../elements/Slider";

interface Props {
  onPurchase: () => Promise<void>;
  onlyCredit: boolean;
  onlyPosoneCredit: boolean;
  canProcessPayment: boolean;
  loading: boolean;
  selectedPaymentMethod: PaymentProvider;
}

const useBasketActions = (props: Props) => {
  const {
    onPurchase,
    onlyCredit,
    onlyPosoneCredit,
    canProcessPayment,
    loading,
    selectedPaymentMethod,
  } = props;
  const {
    auth,
    setDataAuthenticationModal,
    setOpenAuthenticationModal,
    mobile,
    balance,
    posoneBalance,
    basketContainerUtils,
  } = useContainer(AppContext);

  const { module } = useContainer(MealplanContext);
  const basketPrice = basketContainerUtils.totalPrice;

  const { t } = useTranslation([
    "mealplan2",
    "payment",
    "translation",
    "anonymous",
  ]);

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal
  ) => {
    setDataAuthenticationModal({
      title,
      subtitle,
      action,
    });
    setOpenAuthenticationModal(true);
  };

  const authenticatedActions = [
    module.paymentMethod !== "billing" && canProcessPayment && mobile ? (
      <div className="w-full">
        <Slider
          submit={onPurchase}
          checkoutLoading={loading}
          label={t("mealplan2:swipe-to-pay")}
          disabled={!selectedPaymentMethod}
        />
      </div>
    ) : (
      {
        label:
          module.paymentMethod === "billing"
            ? t("translation:approve")
            : canProcessPayment && basketPrice > balance
            ? t("payment:pay-and-checkout", {
                price: basketPrice - balance,
              })
            : t("payment:checkout"),
        onClick: onPurchase,
        type: "primary",
        loading,
        disabled:
          (onlyCredit && basketPrice > balance) ||
          (onlyPosoneCredit && basketPrice > posoneBalance),
      }
    ),
  ];

  const unauthenticatedActions = [
    !auth.user && {
      label: t("translation:log-in"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:welcome-back"),
          t("translation:log-in-to-your-account"),
          "login"
        );
      },
      type: "primary",
      dataCy: "login-anonymous",
    },
    {
      label: t("translation:sign-up"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:sign-up"),
          t("anonymous:modal.subtitles.info-authenticate-signup"),
          "signup"
        );
      },
      type: "ghost",
      dataCy: "signup-anonymous",
    },
  ];

  return {
    actions: auth?.user?.uid ? authenticatedActions : unauthenticatedActions,
  };
};

export default useBasketActions;
