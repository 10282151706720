import { faArrowRotateRight, faCoin } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../contextProvider";
import PaymentModal from "../../modals/payment";
import { useContainer } from "unstated-next";

interface AlertProps {
  balance: number;
  cost: number;
}

const PaidAlert = ({ balance, cost }: AlertProps) => {
  const { t } = useTranslation(["mealplan2", "translation", "modals"]);
  const [openPaymentWindow, setOpenPaymentWindow] = useState(false);
  const {
    isPosone,
    posonePartnerData,
    customBranding,
    getPosoneBalance,
    posoneBalanceLoading,
  } = useContainer(AppContext);

  if (isPosone) {
    if (cost > balance)
      return (
        <Space direction="vertical">
          <Alert
            showIcon
            type="warning"
            message={`${t("translation:not-enough-credit")}! ${t(
              "modals:you-are-missing-kr",
              { value: cost - balance }
            )}`}
          />
          <div className="flex items-center">
            <Button
              type="primary"
              className="w-full mr-2"
              size="large"
              icon={<FontAwesomeIcon icon={faCoin} className="mr-2" />}
              href={`//${
                posonePartnerData?.prefix || customBranding?.url
              }.smartbetaling.dk/#addfunds`}
              target="_blank"
            >
              {t("modals:refuel-here")}
            </Button>
            <Tooltip title={t("mealplan2:check-balance")}>
              <Button
                size="large"
                onClick={getPosoneBalance}
                loading={posoneBalanceLoading}
              >
                <FontAwesomeIcon icon={faArrowRotateRight} className="mx-2" />
              </Button>
            </Tooltip>
          </div>

          <PaymentModal
            open={openPaymentWindow}
            setOpen={setOpenPaymentWindow}
          />
        </Space>
      );

    return (
      <Alert
        showIcon
        message={t("mealplan2:paid-from-posone", {
          value: Math.round(balance * 100) / 100,
        })}
      />
    );
  }

  if (cost > balance)
    return (
      <Space direction="vertical">
        <Alert
          showIcon
          type="warning"
          message={`${t("translation:not-enough-credit")}! ${t(
            "modals:you-are-missing-kr",
            { value: cost - balance }
          )}`}
        />
        <Button
          type="primary"
          className="w-full"
          size="large"
          icon={<FontAwesomeIcon icon={faCoin} className="mr-2" />}
          onClick={() => setOpenPaymentWindow(true)}
        >
          {t("modals:refuel-here")}
        </Button>
        <PaymentModal open={openPaymentWindow} setOpen={setOpenPaymentWindow} />
      </Space>
    );

  return (
    <Alert
      showIcon
      message={t("mealplan2:paid-from", {
        value: Math.round(balance * 100) / 100,
      })}
    />
  );
};

const PaidFromCredit = () => {
  return null;
};

PaidFromCredit.Alert = PaidAlert;

export default PaidFromCredit;
