import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { TooltipHiddenPrices } from "@kanpla/ui";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import OrderDetail from "./../OrderDetail";
import { Item as ItemType } from "./../UseHistory";
import BigItem from "./BigItem";

interface Props {
  item: ItemType;
  isBig?: boolean;
}

const Item = ({ item, isBig }: Props) => {
  const { i18n } = useTranslation(["translation", "mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const [open, setOpen] = useState(false);
  const hasDetails = item.order;

  const { hidePrices } = item.module?.config || {};

  if (isBig)
    return (
      <>
        <BigItem item={item} setOpen={setOpen} />
        {hasDetails && (
          <OrderDetail
            open={open}
            setOpen={setOpen}
            order={item.order}
            salesplaceName={item.salesplaceName}
            module={item.module}
          />
        )}
      </>
    );

  const hasARefund = item.order?.refundIds?.length;

  return (
    <>
      <div
        className={`flex w-full pb-3 items-center text-text-primary ${
          hasDetails && "cursor-pointer"
        }`}
        onClick={() => {
          if (hasDetails) setOpen(true);
        }}
      >
        <div className="bg-background-secondary rounded-lg w-12 h-12 flex items-center justify-center">
          <FontAwesomeIcon icon={item.icon} className="text-xl" />
        </div>
        <div className="pl-3">
          <div className={`${hasARefund ? "line-through" : ""}`}>
            {item?.order?.displayName
              ? `${item.order.displayName.trim()}, `
              : ""}
            {item.title}
          </div>
          <div className="text-xs text-text-secondary">
            {moment.unix(item.dateSeconds).format("l")}
          </div>
        </div>
        <div className="text-right ml-auto">
          <div
            className={`text-text-primary ${hasARefund ? "line-through" : ""}`}
          >
            {priceFormatter(
              item.paymentAmount ?? "-",
              { language: i18n.language },
              false,
              hidePrices,
              {
                wrapper: () => <span className="text-primary-dark">-</span>,
                component: () => (
                  <TooltipHiddenPrices color="text-primary-dark" />
                ),
              }
            )}
          </div>
          <div className="text-xs text-text-secondary">
            {item.paymentDescription}
          </div>
        </div>
      </div>
      {hasDetails && (
        <OrderDetail
          open={open}
          setOpen={setOpen}
          order={item.order}
          salesplaceName={item.salesplaceName}
          module={item.module}
        />
      )}
    </>
  );
};

export default Item;
