import { faClock, faCookieBite } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  deadlineFormatter,
  isDatePastDeadline,
  Timestamp,
} from "@kanpla/system";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import React from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";

interface Props {
  individualDeadline: number;
  hidden?: boolean | number;
  nextAvailableDate?: Moment | null;
  showChildrenAnyway?: boolean;
  outOfStock?: boolean;
  isPastDeadline?: boolean | null;
  hideNextDay?: boolean;
}

const DeadlineInfo = (props: Props) => {
  const {
    individualDeadline,
    hidden,
    nextAvailableDate = null,
    showChildrenAnyway = false,
    outOfStock = false,
    isPastDeadline = null,
    hideNextDay = false,
  } = props;
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { week, dayIndex, setDate } = useContainer(AppContext);
  const { deadline, deadlineExcludingWeekends, deadlineWeekRelative } =
    useContainer(MealplanContext);
  const hasIndividualDeadline = typeof individualDeadline === "number";
  const isPast =
    isPastDeadline !== null
      ? isPastDeadline
      : isDatePastDeadline({
          date: week[dayIndex],
          deadline: hasIndividualDeadline ? individualDeadline : deadline,
          deadlineExcludingWeekends,
          deadlineWeekRelative,
        });

  const isSameAsMainDeadline = individualDeadline === deadline;

  if (isPast && !showChildrenAnyway) return null;

  if (hasIndividualDeadline && isPast && !hideNextDay) {
    if (!nextAvailableDate) return null;

    const tomorrow = moment().add(1, "day");
    const isTomorrow = tomorrow
      .startOf("day")
      .isSame(nextAvailableDate.startOf("day"));
    const message = t("mealplan2:too-late-to-order", {
      value: isTomorrow
        ? t("mealplan2:tomorrow")
        : nextAvailableDate.format("dddd [d]. D/M"),
    });

    return (
      <div className={`absolute flex flex-col items-start -mt-2 w-full z-10`}>
        <Deadline
          date={week[dayIndex]}
          individualDeadline={individualDeadline}
          className="ml-auto mb-1 animate-appear cursor-pointer transition-all hover:bg-primary-main"
          message={message}
          onClick={() => setDate(new Timestamp(nextAvailableDate.unix(), 0))}
        />
      </div>
    );
  }

  const shouldShowMessage =
    hasIndividualDeadline && !hidden && !isSameAsMainDeadline;

  // return children;

  if (shouldShowMessage || outOfStock || isPast)
    return (
      <div className={`absolute flex flex-col items-start -mt-2`}>
        {shouldShowMessage && (
          <Deadline
            date={week[dayIndex]}
            individualDeadline={individualDeadline}
            className="ml-auto mb-1 animate-appear"
          />
        )}
        {outOfStock && (
          <div
            className={`text-xs animate-appear text-background-primary pb-1 bg-danger-main font-medium px-2 pt-1 rounded-lg bg-opacity-80`}
            style={{
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
            }}
          >
            <FontAwesomeIcon icon={faCookieBite} className="mr-1" />
            {t("mealplan2:sold-out")}
          </div>
        )}
        {isPast && (
          <div
            className={`text-xs animate-appear text-background-primary pb-1 bg-warning-main font-medium px-2 pt-1 rounded-lg bg-opacity-80`}
            style={{
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
            }}
          >
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            {t("mealplan2:too-late-to-order-message")}
          </div>
        )}
      </div>
    );

  return null;
};

export const Deadline = ({
  individualDeadline,
  className,
  date,
  message = "",
  onClick = () => {},
}) => {
  const { t } = useTranslation(["mealplan2"]);
  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    useContainer(MealplanContext);
  const isExtended = deadline < individualDeadline;

  const deadlineDisplay = deadlineFormatter({
    deadline: individualDeadline,
    date,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  return (
    <div
      className={`text-xs text-background-primary pb-1 bg-primary-light font-medium px-2 pt-1 rounded-lg ${className} bg-opacity-60`}
      style={{
        backdropFilter: "blur(3px)",
        WebkitBackdropFilter: "blur(3px)",
      }}
      onClick={onClick}
    >
      <div className="flex flex-row md:items-center">
        <FontAwesomeIcon icon={faClock} className="mr-2 mt-1 md:mt-0" />
        <div>
          {!message
            ? t("mealplan2:before", {
                word: isExtended
                  ? t("mealplan2:values.until")
                  : t("mealplan2:values.before"),
                deadlineDisplay,
              })
            : message}
        </div>
      </div>
    </div>
  );
};

export default DeadlineInfo;
