import { Homescreen } from "@kanpla/types";
import { CustomSlider } from "@kanpla/ui";
import React, { useContext } from "react";
import { HomescreenContext } from "..";
import SectionHeader from "../elements/SectionHeader";
import serializer from "../utils/serializer";
import Button from "./Button";

interface Props {
  singleBlock: Homescreen.ContentInner;
  scrollInfo?: {
    rows: 1 | 2;
    direction: "scrollable" | "stacked";
  };
  title?: string;
  subtitle?: string;
  buttonProps?: Homescreen.ContentInner;
}

export const SectionInner = ({
  singleBlock,
  scrollInfo,
  title,
  buttonProps,
  subtitle,
}: Props) => {
  const { content } = useContext(HomescreenContext);

  const nodeIds = singleBlock.nodes;

  if (scrollInfo?.direction === "scrollable")
    return (
      <main className="rounded-lg -mx-4 md:-mx-4 lg:-mx-8 p-2 px-4 md:px-4 lg:px-8 relative overflow-hidden">
        <CustomSlider
          title={title}
          subtitle={subtitle}
          linkButton={buttonProps && <Button singleBlock={buttonProps} />}
          items={nodeIds}
          rows={scrollInfo?.rows || 1}
          renderer={(nodeId) => {
            const node = content[nodeId];

            return serializer(node, nodeId);
          }}
        />
      </main>
    );

  return (
    <main className="rounded-lg -mx-2 md:-mx-4 lg:-mx-8 p-2 px-2 md:px-4 lg:px-8 relative ">
      <div
        className={`grid grid-cols-1 ${
          nodeIds.length > 1 ? "sm:grid-cols-2" : ""
        } ${
          nodeIds.length > 2
            ? "md:grid-cols-3 md:gap-4"
            : nodeIds.length > 1
            ? "md:grid-cols-2 md:gap-8"
            : ""
        } gap-2 `}
      >
        {nodeIds.map((nodeId) => {
          const node = content[nodeId];

          return serializer(node, nodeId);
        })}
      </div>
    </main>
  );
};

const Section = ({ singleBlock }: Props) => {
  const { content } = useContext(HomescreenContext);

  const buttonArea = content[singleBlock?.linkedNodes?.buttonArea];
  const sectionData = content[singleBlock?.linkedNodes?.sectionData];

  const { direction, rows } = singleBlock.props;

  const buttonProps = content[buttonArea?.nodes?.[0]];

  return (
    <section>
      {direction !== "scrollable" && (
        <SectionHeader
          buttonProps={buttonProps}
          title={singleBlock.props?.title}
          subtitle={singleBlock.props?.subtitle}
        />
      )}
      <SectionInner
        title={singleBlock.props?.title}
        subtitle={singleBlock.props?.subtitle}
        singleBlock={sectionData}
        buttonProps={buttonProps}
        scrollInfo={{ direction, rows }}
      />
    </section>
  );
};

export default Section;
