import { faLanguage } from "@fortawesome/pro-light-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../modals/ChangeLanguage";
import ButtonLine from "./ButtonLine";

const Language = () => {
  const { t } = useTranslation(["settings"]);

  const [languageOpen, setLanguageOpen] = useState(false);

  return (
    <>
      <ButtonLine
        label={t("settings:language-preference")}
        onClick={() => setLanguageOpen(true)}
        icon={faLanguage}
        dataCy="language-preferences-btn"
      />
      <ChangeLanguage open={languageOpen} setOpen={setLanguageOpen} />
    </>
  );
};

export default Language;
