import { editLanguage } from "@kanpla/system";
import { DrawerOrModal } from "@kanpla/ui";
import { Form, message, Radio, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ChangeLanguage = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation(["translation", "modals"]);
  const { user } = useContainer(AppContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const allLanguages = [
    {
      value: "da",
      label: "Dansk",
      icon: () => (
        <span aria-label="dan-flag" role="img" id="flag">
          🇩🇰
        </span>
      ),
    },
    {
      value: "en",
      label: "English",
      icon: () => (
        <span aria-label="usa-flag" role="img" id="flag">
          🇬🇧
        </span>
      ),
    },
  ];

  const onFinish = async (values: { [key: string]: string }) => {
    const lang = values["language-radio-button"] || i18n.language;
    setLoading(true);
    try {
      await editLanguage({
        collection: "users",
        docId: user.userId,
        language: lang,
      });
      i18n.changeLanguage(lang);
      message.success(t("translation:message.success.language-changed"));
    } catch (error) {
      message.error(t("translation:message.error.could-not-change-language"));
    }
    setOpen(false);
    setLoading(false);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("modals:modal.titles.change-language")}
      subtitle={t("modals:modal.subtitles.change-application-language")}
      actions={[
        {
          label: t("modals:modal.titles.change-language"),
          onClick: () => form.submit(),
          className: "primary",
          loading,
        },
      ]}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="language-radio-button" className="m-0 mt-8">
          <Radio.Group defaultValue={i18n.language}>
            <Space direction="vertical">
              {allLanguages.map((language, index) => (
                <Radio
                  key={`lang-${index}`}
                  value={language.value}
                  className="text-text-primary"
                >
                  {language.icon()} {language.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangeLanguage;
