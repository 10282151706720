import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Module } from "@kanpla/types";
import { Button, Input, Select, Table } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import { cloneDeep } from "lodash";

interface Titles {
  totalPrice: string;
  date: string;
  nameOfTheOrder: string;
  numberOfProducts: string;
  orderedBy: string;
  deliveryTime: string;
  invoiceReference: string;
}

const predefinedPaths = (module: Module, titles: Titles) =>
  [
    {
      title: titles.totalPrice,
      path: "calculateTotal",
    },
    {
      title: titles.date,
      path: "date",
    },
    {
      title: titles.nameOfTheOrder,
      path: "info//name",
    },
    {
      title: titles.numberOfProducts,
      path: "productAmount",
    },
    {
      title: titles.orderedBy,
      path: "displayName",
    },
    {
      title: "Bestilt af (gruppe)",
      path: "groupName",
    },
    module?.plugins?.timeInput && {
      title: titles.deliveryTime,
      path: "info//timeInput",
    },
    module?.plugins?.invoiceReference && {
      title: titles.invoiceReference,
      path: "info//reference",
    },
  ].filter((p) => p);

const extraPaths = (module: Module) =>
  (module?.plugins?.textInput?.fields?.map((field) => ({
    title: field.title,
    path: `info//textInput//${field.key}`,
  })) || []) as Array<{ title: string; path: string }>;

interface Props {
  columns: Array<
    Partial<{
      title: string;
      path: string;
    }>
  >;
  setColumns: Dispatch<
    SetStateAction<
      Array<
        Partial<{
          title: string;
          path: string;
        }>
      >
    >
  >;
}

const headerCell = () => {
  return {
    style: {
      backgroundColor: "var(--main-color-600)",
      borderColor: "var(--main-color-800)",
    },
    className: "text-text-primary",
  };
};

const cellDesign = () => {
  return {
    style: {
      backgroundColor: "var(--main-color-400)",
      borderColor: "var(--main-color-600)",
    },
    className: "text-text-primary",
  };
};

/**
 * TODO: use instead libs/ui/src/forms/ColumnTable/ColumnTable.tsx once it handle i18n
 */
const ColumnTable = ({ columns, setColumns }: Props) => {
  const { t } = useTranslation(["mealplan2", "translation"]);
  const { module } = useContainer(AppContext);
  const titles = {
    totalPrice: t("mealplan2:predefined-path.total-price"),
    date: t("mealplan2:predefined-path.date"),
    nameOfTheOrder: t("mealplan2:predefined-path.name-of-the-order"),
    numberOfProducts: t("mealplan2:predefined-path.number-of-products"),
    orderedBy: t("mealplan2:predefined-path.ordered-by"),
    deliveryTime: t("mealplan2:predefined-path.delivery-time"),
    invoiceReference: t("mealplan2:predefined-path.invoice-reference"),
  };

  const predefinedPth = predefinedPaths(module, titles as any) || [];
  const extraPth = extraPaths(module) || [];

  const jointPaths = [...predefinedPth, ...extraPth];

  return (
    <div>
      <Table
        pagination={false}
        size="small"
        dataSource={columns}
        className="bg-background-secondary"
        footer={() => (
          <Button
            type="dashed"
            onClick={() =>
              setColumns(() => {
                const newD = cloneDeep(columns);
                newD.push({});
                return newD;
              })
            }
          >
            {t("mealplan2:add-column")}
          </Button>
        )}
        columns={[
          {
            title: t("translation:name"),
            dataIndex: "title",
            width: "40%",
            render: (title: string, row, index) => (
              <Input
                placeholder={t("translation:form.placeholders.title")}
                value={title}
                onChange={(e) =>
                  setColumns((d) => {
                    const newD = d.slice();

                    newD[index] = {
                      ...newD[index],
                      title: e.target.value,
                    };

                    return newD;
                  })
                }
                required
              />
            ),
            onHeaderCell: headerCell,
            onCell: cellDesign,
          },
          {
            title: "Data",
            dataIndex: "path",
            width: "40%",
            render: (p: string, row, index) => {
              return (
                <Select
                  value={p}
                  className="w-40"
                  aria-required
                  placeholder={t("mealplan2:form.placeholders.select-data")}
                  onChange={(path: string) =>
                    setColumns((d) => {
                      const newD = d.slice();

                      newD[index] = {
                        title: jointPaths.find((p) => p.path === path)?.title,
                        path,
                      };

                      return newD;
                    })
                  }
                >
                  <Select.OptGroup
                    label={t("mealplan2:form.labels.defined-fields")}
                  >
                    {predefinedPth.map((path) => (
                      <Select.Option key={path.title} value={path.path}>
                        {path.title}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                  {extraPth.length > 0 && (
                    <Select.OptGroup
                      label={t("mealplan2:form.labels.additional-fields")}
                    >
                      {extraPth.map((path) => (
                        <Select.Option key={path.title} value={path.path}>
                          {path.title}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  )}
                </Select>
              );
            },
            onHeaderCell: headerCell,
            onCell: cellDesign,
          },
          {
            title: t("translation:delete"),
            dataIndex: "title",
            render: (title, row, index) => (
              <Button
                danger
                onClick={() =>
                  setColumns((d) => {
                    delete d[index];

                    return d.filter((da) => da);
                  })
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            ),
            onHeaderCell: headerCell,
            onCell: cellDesign,
          },
        ]}
      />
    </div>
  );
};

export default ColumnTable;
