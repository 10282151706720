import { faTruckClock } from "@fortawesome/pro-duotone-svg-icons";
import { getActivePlugins, isDefaultReferenceValid } from "@kanpla/system";
import { Module, OrderPersonal, Plugins } from "@kanpla/types";
import {
  InvoiceReference,
  ProductSettingsHeader,
  RequiredProduct,
  TextInput,
  TimeSelect,
} from "@kanpla/ui";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Space,
} from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";

interface DefaultProps {
  withIndividualTimeEdit?: boolean;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  initialValues: any;
  moduleFromProps?: Module;
  extraKey?: string;
  onChange?: (
    newData: OrderPersonal["info"],
    moduleId: string,
    dateSeconds: number
  ) => void;
  dateSeconds?: number;
}

interface PropsWithIndividualTimeEdit extends DefaultProps {
  withIndividualTimeEdit: true;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

type Props = PropsWithIndividualTimeEdit | DefaultProps;

const OrderInfoFields = ({
  withIndividualTimeEdit = false,
  setEditMode,
  form,
  initialValues = {},
  moduleFromProps = null,
  extraKey = "",
}: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "libs"]);

  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });
  const {
    module: moduleFromContext,
    hasRequiredProduct,
    mealOptions,
    items,
  } = useContainer(MealplanContext);

  const module = moduleFromProps || moduleFromContext;

  const {
    defaultReference,
    schoolId,
    basket,
    setBasket,
    child,
    week,
    dayIndex,
    activePlugins: activePluginsFromContext,
  } = useContainer(AppContext);

  const activePlugins = useMemo(
    () =>
      moduleFromProps ? getActivePlugins({ module }) : activePluginsFromContext,
    [activePluginsFromContext, module, moduleFromProps]
  );
  const plugins = module?.plugins || ({} as Plugins.Array);

  const shouldHideReference =
    plugins?.invoiceReference?.hideInBasket &&
    isDefaultReferenceValid({ child, module });

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        ...initialValues,
        [`timeInput${extraKey}`]:
          activePlugins?.timeInput &&
          (initialValues?.timeInput || plugins?.timeInput?.startAt || 27000),
        [`reference${extraKey}`]:
          activePlugins?.invoiceReference && defaultReference,
      }}
    >
      {module?.flow === "meeting" && (
        <Space align="baseline">
          <div className="">
            <ProductSettingsHeader title={t("mealplan2:name-meeting")} noBg />
            <Form.Item name={`name${extraKey}`}>
              <Input
                className="mt-1"
                placeholder={t("mealplan2:name-meeting")}
              />
            </Form.Item>
          </div>
          <div>
            <ProductSettingsHeader title={t("mealplan2:meeting-date")} noBg />
            <DatePicker
              className="mt-1"
              placeholder={t("mealplan2:meeting-date")}
              disabled={true}
              allowClear={false}
              value={moment(week[dayIndex].toDate())}
              format={(m) => m.format("ll")}
              name={`date${extraKey}`}
            />
          </div>
        </Space>
      )}
      <div>
        {activePlugins.timeInput && (
          <>
            <Form.Item
              name={`timeInput${extraKey}`}
              label={
                <ProductSettingsHeader
                  className="w-full"
                  title={
                    plugins?.timeInput?.fieldName || t("libs:delivery-time")
                  }
                  subtitle={
                    plugins?.timeInput?.fieldDescription ||
                    t("libs:desired-delivery-time")
                  }
                  icon={faTruckClock}
                  noBg
                />
              }
              rules={[
                {
                  required: true,
                  type: "number",
                  message: isEmpty(i18n)
                    ? "Dette felt er påkrævet"
                    : t("libs:specific-field-required", {
                        fieldName: t("libs:delivery-time"),
                      }),
                },
              ]}
            >
              {/* @ts-ignore */}
              <TimeSelect
                interval={plugins?.timeInput?.interval}
                endAt={plugins?.timeInput?.endAt}
                startAt={plugins?.timeInput?.startAt}
              />
            </Form.Item>
            {withIndividualTimeEdit && setEditMode && (
              <Button
                onClick={() => setEditMode((mode) => !mode)}
                className="whitespace-normal h-auto mb-4"
              >
                {t("mealplan2:edit-delivery-time-for-each-item")}
              </Button>
            )}
          </>
        )}
      </div>
      {activePlugins.invoiceReference && (
        <div
          className={classNames({
            "absolute h-0 w-0 opacity-0 pointer-events-none":
              shouldHideReference,
          })}
        >
          <InvoiceReference
            noBg
            module={module}
            defaultReference={defaultReference}
            required
            formItemName={`reference${extraKey}`}
          />
        </div>
      )}

      {activePlugins.textInput && (
        <TextInput
          noBg
          orderInfo={initialValues}
          settings={plugins?.textInput}
          schoolId={schoolId}
          groupName={child?.groupName}
          extraKey={extraKey}
        />
      )}

      {hasRequiredProduct && (
        <RequiredProduct
          settings={plugins?.requiredProduct}
          requiredProductOrder={basket}
          setRequiredProductOrder={(requiredOrder) =>
            setBasket({ ...basket, ...requiredOrder })
          }
          mealOptions={mealOptions}
          module={module}
          order={basket}
          offerItems={items}
        />
      )}

      {activePlugins.orderForAmountOfPeople && (
        <div className="mx-2">
          <ProductSettingsHeader title={t("mealplan2:number-of-people")} noBg />
          <Form.Item
            name={`numberOfPeople${extraKey}`}
            rules={[{ type: "number" }]}
          >
            <InputNumber pattern="[0-9]*" inputMode="numeric" required={true} />
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

export default OrderInfoFields;
