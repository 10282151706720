import { CarouselProvider } from "pure-react-carousel";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../..";
import Carousel from "./Carousel";

const ProductsCarousel = () => {
  const { items } = useContainer(MealplanContext);

  if (items?.length === 0)
    return (
      <div className="h-64 flex justify-center items-center text-center bg-background-secondary mx-6 my-6 rounded-lg">
        <h3 className="h400 text-text-disabled">Ingen produkter i dag</h3>
      </div>
    );

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={80}
      visibleSlides={1}
      className="receipt-slider"
      totalSlides={items.length}
      dragEnabled
    >
      <Carousel />
    </CarouselProvider>
  );
};

export default ProductsCarousel;
