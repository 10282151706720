import { priceFormatter } from "@kanpla/system";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";

interface Props {
  basketPrice: number;
  isCredit: boolean;
  hasMoney: boolean;
}

const PriceOverview = ({ basketPrice, isCredit, hasMoney }: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "translation", "modals"]);

  const { activePlugins, module, balance } = useContainer(AppContext);

  const { hidePrices } = module?.config || {};

  const deductedFromCredit = useCallback((): number => {
    const diff = balance - basketPrice;
    if (diff < 0) return balance;
    if (diff >= 0) return basketPrice;
  }, [balance, basketPrice]);

  return (
    <div className="flex pt-2 md:pt-4 flex-col">
      <h1 className="h500">{t("translation:price-overview")}</h1>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-text-secondary">
          {t("mealplan2:include-exclude", {
            value:
              module?.paymentMethod === "billing"
                ? t("mealplan2:values.exclude")
                : t("mealplan2:values.include"),
          })}
        </h2>
        <p className="text-text-secondary font-medium text-xl">
          {priceFormatter(
            basketPrice,
            { language: i18n.language },
            false,
            hidePrices,
            { wrapper: () => "-" }
          )}
        </p>
      </div>
      {isCredit && (
        <div className="flex pt-1 justify-between">
          <h2 className="text-md text-text-secondary">
            {t("mealplan2:from-balance")}
          </h2>
          <p className="text-text-secondary text-md">
            {priceFormatter(deductedFromCredit() || 0, {
              language: i18n.language,
            })}
          </p>
        </div>
      )}
      {isCredit &&
        !hasMoney &&
        !activePlugins?.payPerOrder &&
        !activePlugins?.kanplaGo && (
          <div className="flex pt-1 justify-between">
            <h2 className="text-md text-warning-main">
              {t("modals:you-are-missing")}
            </h2>
            <p className="text-warning-main text-md">
              {priceFormatter(basketPrice - deductedFromCredit(), {
                language: i18n.language,
              })}
            </p>
          </div>
        )}
      {isCredit &&
        !hasMoney &&
        (activePlugins?.payPerOrder || activePlugins?.kanplaGo) && (
          <div className="flex pt-1 justify-between">
            <h2 className="text-md text-warning-main">
              {t("translation:you-will-be-charged")}
            </h2>
            <p className="text-warning-main text-md">
              {priceFormatter(basketPrice - deductedFromCredit(), {
                language: i18n.language,
              })}
            </p>
          </div>
        )}
    </div>
  );
};

export default PriceOverview;
