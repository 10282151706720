import React from "react";

interface Props {
  noHeader?: boolean;
}

const DashboardSkeleton = ({ noHeader }: Props) => {
  return (
    <div className="h-full w-full flex flex-col px-2 lg:px-4 py-12 container mx-auto">
      {!noHeader && (
        <>
          <div className="flex flex-col items-center md:items-start mt-8">
            <div className="h-8 md:h-10 w-32 rounded loader" />
            <div className="h-4 md:h-6 w-48 rounded loader mt-2" />
            <div className="h-4 md:h-6 w-36 rounded loader mt-2" />
          </div>
          <div className="flex flex-col items-start mt-10">
            <div className="h-8 w-28 rounded loader" />
            <div className="h-44 w-full max-w-lg rounded loader mt-4" />
          </div>
        </>
      )}
      <div className="flex flex-col items-start mt-8">
        {!noHeader && <div className="h-8 w-28 rounded-sm loader" />}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-full mt-4">
          <div className="h-20 md:h-32 w-full rounded loader" />
          <div className="h-20 md:h-32 w-full rounded loader" />
          <div className="h-20 md:h-32 w-full rounded loader" />
          <div className="h-20 md:h-32 w-full rounded loader" />
          <div className="h-20 md:h-32 w-full hidden md:block rounded loader" />
          <div className="h-20 md:h-32 w-full hidden md:block rounded loader" />
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
