import { Module } from "@kanpla/types";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import KanplaGoIntro from "./modals/KanplaGoIntro";

interface Props {
  module: Module;
  align?: "left" | "center" | "right";
}

const ModuleDescription = ({ module, align }: Props) => {
  const { t } = useTranslation(["translation"]);
  const [kanplaGoIntroOpen, setKanplaGoIntroOpen] = useState(false);

  const text = module?.text;

  const isKanplaGo = module?.plugins?.kanplaGo?.active;
  const hideSkipQueue = isKanplaGo && module?.plugins?.kanplaGo?.hideSkipQueue;

  const kanplaGoTitle =
    text?.["kanplaGoHeader/title"] || t("translation:order-in-the-canteen");
  const kanplaGoSubtitle =
    text?.["kanplaGoHeader/subtitle"] || t("translation:buy-food-single-swipe");
  const kanplaGoButtonText =
    text?.["kanplaGoHeader/readMoreButton"] || t("translation:read-more");

  const descTitle =
    isKanplaGo && kanplaGoTitle ? kanplaGoTitle : text?.["description/title"];
  const descText =
    isKanplaGo && kanplaGoSubtitle
      ? kanplaGoSubtitle
      : text?.["description/text"];

  if (!descTitle && !descText) return null;

  return (
    <div
      className={`px-0 md:px-2 mb-6 md:mb-8 text-center ${
        align === "left"
          ? "sm:text-left sm:mx-0 sm:px-0"
          : align === "right"
          ? "sm:text-right sm:mx-0 sm:px-0"
          : ""
      } text-text-secondary mx-auto max-w-md`}
    >
      {descTitle !== "" && (
        <h2 className="h600 mx-auto leading-tight text-primary-main">
          {descTitle}
        </h2>
      )}
      <div className="flex flex-col">
        {descText !== "" && (
          <p className="mt-2 text-text-secondary text-sm">{descText}</p>
        )}
        {isKanplaGo &&
          kanplaGoButtonText &&
          !module?.plugins?.kanplaGo?.hideGuide && (
            <div>
              <Button
                type="primary"
                className="mt-4"
                onClick={() => setKanplaGoIntroOpen(true)}
              >
                {kanplaGoButtonText}
              </Button>
              <KanplaGoIntro
                automaticallyOpen
                open={kanplaGoIntroOpen}
                setOpen={setKanplaGoIntroOpen}
                hideSkipQueue={hideSkipQueue}
                module={module}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ModuleDescription;
