import {
  editConfigAmount,
  getLastFlexOrderChange,
  isDatePastDeadline,
} from "@kanpla/system";
import { CombinedOfferItem, DayIndex, OrderConfig } from "@kanpla/types";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import { AppContext } from "../contextProvider";
import classnames from "classnames";

interface Props {
  value: number;
  disabled?: boolean;
  expandProduct: () => void;
  choice: OrderConfig["options"];
  product: CombinedOfferItem;
  dayIndex: DayIndex;
  isBelow?: boolean;
}

const Input = (props: Props) => {
  const {
    value,
    disabled = false,
    expandProduct,
    product,
    dayIndex,
    choice,
    isBelow,
  } = props;

  const { t } = useTranslation(["flex-bulk"]);

  const {
    defaultDate,
    isStandardView,
    orderDocuments,
    deadline,
    softDeadlineMaxAmount,
    defaultSoftDate,
    orders,
    setOrders,
    daysHolidays,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  } = useContainer(FlexBulkContext);
  const { week } = useContainer(AppContext);

  const [inputValue, setLocalValue] = useState<string>(`${value}`);
  const [focusing, setFocusing] = useState<boolean>(false);

  const date = week[dayIndex];

  const overDeadline = isDatePastDeadline({
    date: week[dayIndex],
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  // Disabled
  const pastDate = (week[dayIndex]?.seconds || 0) < (defaultDate?.seconds || 0);
  const pastSoftDate = week[dayIndex].seconds < defaultSoftDate.seconds;
  const inSoftMode = !pastSoftDate && pastDate && softDeadlineMaxAmount > 0;

  const availableChanges = inSoftMode
    ? getLastFlexOrderChange({
        order: orderDocuments?.[dayIndex],
        date,
        deadline,
        softDeadlineMaxAmount,
        localOrder: orders[dayIndex],
      })?.availableChanges || 0
    : Infinity;

  const deadlineDisabled = overDeadline && !isStandardView;

  const isHoliday = daysHolidays[dayIndex];

  useEffect(() => {
    setLocalValue(`${value}`);
  }, [value]);

  const parsedValue = parseInt(inputValue);
  const isEmpty = !parsedValue;

  const emptyStyles = inSoftMode
    ? "text-warning-main font-medium"
    : isStandardView
    ? "text-info-main font-medium"
    : "text-primary-main font-medium";

  const filledStyles = inSoftMode
    ? "text-warning-dark font-medium"
    : isStandardView
    ? "text-info-dark font-semibold"
    : "text-text-primary font-semibold";

  const styles = `w-16 text-center text-xl ${
    disabled || deadlineDisabled
      ? "text-text-secondary font-medium"
      : `border focus:border-primary-main ${
          isEmpty ? emptyStyles : filledStyles
        }`
  }`;

  const inputClassNames = classnames({
    "focus:border-danger-main text-danger-main": isBelow,
  });

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(e.target.value);

    // If not a readable value, just return
    if (
      typeof parsedValue !== `number` ||
      parsedValue < 0 ||
      parsedValue === value
    ) {
      setLocalValue(e.target.value);
      return;
    }

    // Construct new order
    const newOrder = editConfigAmount({
      order: orders[dayIndex],
      productId: product.id,
      choice,
      amountChange: parsedValue,
      replaceAmount: true,
    });

    // Check if over softLimit, if inSoftMode
    const nextAvailableChanges = inSoftMode
      ? getLastFlexOrderChange({
          order: orderDocuments?.[dayIndex],
          date,
          deadline,
          softDeadlineMaxAmount,
          localOrder: newOrder,
        })?.availableChanges || 0
      : Infinity;

    // Return without updating the local value
    if (nextAvailableChanges < 0) {
      console.log("returning");
      return;
    }

    // Update local value
    setLocalValue(e.target.value);

    // Modify orders
    setOrders((oldOrders) => {
      const newOrders = { ...oldOrders };
      newOrders[dayIndex] = newOrder;
      return newOrders;
    });
  };

  if (disabled || deadlineDisabled || isHoliday)
    return (
      <button
        className={`${styles} text-text-disabled cursor-not-allowed`}
        onClick={() => expandProduct()}
      >
        {value}
      </button>
    );

  return (
    <Tooltip
      placement="top"
      title={t("flex-bulk:changes-left", { value: availableChanges })}
      visible={inSoftMode && focusing}
    >
      <input
        pattern="[0-9]*"
        inputMode="numeric"
        type="number"
        value={parsedValue === 0 ? "" : inputValue}
        onFocus={() => setFocusing(true)}
        placeholder="0"
        onBlur={() => {
          setLocalValue(`${value}`);
          setFocusing(false);
        }}
        min={0}
        onChange={onChangeInput}
        className={`${styles} ${inputClassNames} bg-background-primary rounded-lg border border-divider-main`}
      />
    </Tooltip>
  );
};

export default Input;
