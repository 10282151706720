import { OrderInfo as OrderInfoType } from "@kanpla/types";
import { Modal } from "@kanpla/ui";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setWith } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import OrderInfoFields from "./OrderInfoFields";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (info: OrderInfoType) => Promise<void>;
}

const OrderInfo = (props: Props) => {
  const { open, setOpen, onSubmit } = props;

  const { t } = useTranslation(["mealplan2", "translation"]);

  const { isSaving } = useContainer(AppContext);
  const { orderInfo } = useContainer(MealplanContext);

  const [form] = useForm();

  const submit = async () => {
    try {
      const data = await form.validateFields();

      const newData = Object.entries(data).reduce((acc, [path, value]) => {
        if (!value) return acc;

        const newAcc = setWith(acc, path, value, Object);
        return newAcc;
      }, {});

      await onSubmit(newData);

      form.resetFields();
    } catch (e) {
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors.join(", ") || e?.message);
      console.log(e);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("mealplan2:modal.titles.edit-order")}
      containerClassName="z-50"
      className="z-50 p-0"
      actions={[
        {
          label: t("translation:cancel"),
          onClick: () => setOpen(false),
          type: "default",
        },
        {
          label: t("translation:confirm"),
          onClick: submit,
          className: "primary",
          loading: isSaving > 0,
        },
      ]}
    >
      <OrderInfoFields form={form} initialValues={orderInfo} />
    </Modal>
  );
};

export default OrderInfo;
