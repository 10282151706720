import React from "react";
import HTMLReactParser from "react-html-parser";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  topTitle: string;
  overlayOpacity?: number;
}

const BannerFour = ({
  title,
  imageUrl,
  topTitle,
  subTitle,
  overlayOpacity,
}: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg`}
      style={{ minHeight: 288 }}
    >
      <BannerBackgroundImage src={imageUrl} alt={title} size={{ w: width }} />
      <div
        className={`relative h-full inset-0 bg-gradient-to-t from-black opacity-60 ${getProportionalHeightPadding(
          width,
          "bottom",
          true
        )}`}
        style={{ minHeight: 288, opacity: overlayOpacity / 140 }}
      />
      <div className="flex flex-col h-full absolute inset-0 p-4 justify-end text-white">
        {topTitle && (
          <p
            className={`text-left text-white ${getProportionalFontSize(
              width,
              "text-xs"
            )}`}
          >
            {topTitle}
          </p>
        )}
        {title && (
          <h3
            className={`h600 text-white text-left ${getProportionalFontSize(
              width,
              "text-lg"
            )}`}
          >
            {title}
          </h3>
        )}
        {subTitle && (
          <p
            className={`text-white text-left ${getProportionalFontSize(width)}`}
          >
            {HTMLReactParser(subTitle)}
          </p>
        )}
      </div>
    </div>
  );
};

export default BannerFour;
