import { ActionsAuthenticationModal, AnonymousPropsFlow } from "@kanpla/types";
import React from "react";
import LoginContent from "./../login/LoginContent";
import SignUpFlow from "./../signup-flow";

interface Props extends AnonymousPropsFlow {
  /** Determine which component to render */
  action?: ActionsAuthenticationModal;
}

const Authentication = ({
  action,
  isFromAPrivateModule = false,
  isFromAnonymousFlow = false,
}: Props) => {
  const renderLoginOrSignUp = (action: ActionsAuthenticationModal) => {
    switch (action) {
      case "signup":
        return isFromAPrivateModule ? (
          <div className="w-full p-3">
            <div className="text-center my-5 mx-auto p-3 rounded-sm shadow-lg w-full lg:w-3/4 xl:w-2/4">
              <SignUpFlow
                isFromAnonymousFlow={isFromAnonymousFlow}
                isFromAPrivateModule={isFromAPrivateModule}
              />
            </div>
          </div>
        ) : (
          <SignUpFlow
            isFromAnonymousFlow={isFromAnonymousFlow}
            isFromAPrivateModule={isFromAPrivateModule}
          />
        );
      case "login":
      default:
        return isFromAPrivateModule ? (
          <div className="w-full p-3">
            <div className="text-center my-5 mx-auto p-3 rounded-sm shadow-lg w-full lg:w-3/4 xl:w-2/4">
              <LoginContent
                isFromAnonymousFlow={isFromAnonymousFlow}
                isFromAPrivateModule={isFromAPrivateModule}
              />
            </div>
          </div>
        ) : (
          <LoginContent
            isFromAnonymousFlow={isFromAnonymousFlow}
            isFromAPrivateModule={isFromAPrivateModule}
          />
        );
    }
  };

  return renderLoginOrSignUp(action);
};

export default Authentication;
