import { NextRouter } from "next/router";

interface Props {
  router?: NextRouter;
  /** Current link, the redirect module in which the user will landing from the homescreen */
  link?: string;
}

/**
 * Redirect to a specific module adding backLink query.
 */
export const redirectToModule = ({ router, link }: Props) => {
  const { asPath } = router;
  const formattedLink = asPath.replace("/app", "");
  router.push(`${link}?backLink=${formattedLink}`);
};
