import { useLocationHostname } from "@kanpla/system";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NewKid from "../modals/newKid";
import Credit from "./credit";
import Kid from "./kid";
import KidLoader from "./KidLoader";
import SalesplaceKid from "./salesplace/SalesplaceKid";

const Kids = () => {
  const { t } = useTranslation(["settings"]);

  const { children, isPosone, isChildSalesplace, appLoading } =
    useContainer(AppContext);

  /** HARDCODED! */
  const domainsWithSingleUser = [
    "serwiz.kanpla.dk",
    "gastrokantiner.kanpla.dk",
    "zoffmannjensen.kanpla.dk",
  ];

  const hostname = useLocationHostname({});
  const singleUserApp = domainsWithSingleUser.includes(hostname);

  if (appLoading)
    return (
      <div>
        <h2 className="h500 pb-2 border-b">{t("settings:user")}</h2>
        <KidLoader />
        <div className="opacity-60">
          <KidLoader />
        </div>
        <div className="opacity-40">
          <KidLoader />
        </div>
      </div>
    );

  return (
    <div>
      {!isChildSalesplace ? (
        <div>
          <Credit className="mb-8" />
          <h2 className="h500 pb-2 border-b">{t("settings:user")}</h2>
          {!children?.length && (
            <p className="my-4 text-center text-text-disabled">
              {t("settings:no-user")}
            </p>
          )}
          {children !== null &&
            children.map((child) => <Kid key={child.id} child={child} />)}
          {!isPosone && !singleUserApp && <NewKid />}
        </div>
      ) : (
        <div className="w-full -mt-2">
          <SalesplaceKid />
        </div>
      )}
    </div>
  );
};

export default Kids;
