import { db, useCollectionListener } from "@kanpla/system";
import { SubscriptionOrder } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";

const useSubscriptions = () => {
  const { child, userId } = useContainer(AppContext);
  const ref = db
    .collection("subscriptionOrders")
    .where("child", "==", db.collection("children").doc(child?.id) || null)
    .where("userId", "==", userId || null);

  const [all = []] = useCollectionListener<SubscriptionOrder>(ref);

  const subscriptions = all
    .filter((s) => s.valid !== false)
    .sort((a, b) => b?.from?.seconds - a?.from?.seconds);

  return subscriptions;
};

export default useSubscriptions;
