import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChildView } from "@kanpla/ui";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import useInteractionPopup from "../lib/useInteractionPopup";
import useNotifications from "../lib/useNotifications";
import { AppContext } from "./contextProvider";
import PopupNotification from "./popups/PopupNotification";

const NotificationCenter = () => {
  const { t, i18n } = useTranslation(["components"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { setShowPopupInteractionAnimation, showPopupInteractionAnimation } =
    useContainer(AppContext);

  const [open, setOpen] = useState(false);
  const { notifications } = useNotifications();
  const { eventData: events, refreshEventsData } = useInteractionPopup();

  const now = new Date().getTime() / 1000;
  const isToday = (notification) =>
    notification.dateCreated && notification.dateCreated.seconds > now - 86400;

  const hasTodayNotification =
    notifications[0] && notifications.filter((n) => isToday(n))?.length > 0;

  useEffect(() => {
    if (!showPopupInteractionAnimation) return;
    if (showPopupInteractionAnimation) {
      setTimeout(() => {
        setShowPopupInteractionAnimation(false);
      }, 5000);
    }
  }, [showPopupInteractionAnimation]);

  if (!hasTodayNotification && isEmpty(events)) return null;

  return (
    <div>
      <Tooltip
        placement="bottomRight"
        title={t("components:always-access")}
        visible={showPopupInteractionAnimation}
      >
        <button
          className="w-8 h-8 flex justify-center items-center bg-primary-main text-primary-contrast rounded-full relative mr-4"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon
            icon={faBell}
            className={`${showPopupInteractionAnimation ? "animate-ring" : ""}`}
          />
          {hasTodayNotification && (
            <div
              className="absolute -top-1 -right-1 bg-danger-main text-danger-contrast rounded-full flex items-center justify-center font-bold"
              style={{ fontSize: 10, width: 18, height: 18 }}
            >
              1
            </div>
          )}
        </button>
      </Tooltip>
      <ChildView
        open={open}
        setOpen={setOpen}
        title={t("components:notifications")}
      >
        {!isEmpty(events) && (
          <label className="form-label uppercase text-sm font-semibold mb-4">
            {t("components:interaction")}
          </label>
        )}
        <PopupNotification events={events} refresh={refreshEventsData} />

        {!isEmpty(events) && (
          <label className="form-label uppercase text-sm font-semibold mb-4 mt-6">
            {t("components:notifications")}
          </label>
        )}
        {notifications
          .filter(
            (notification) =>
              !events.map((e) => e.title).includes(notification.body)
          )
          .map((notification, i) => {
            const highlight = isToday(notification);

            return (
              <div
                className={`py-0 border-l-4 pl-3 ${
                  i === 0 ? "mb-6" : "my-6"
                } pr-2 ${
                  highlight ? "border-primary-main" : "border-divider-main"
                }`}
                key={notification.id}
              >
                <div className="h300 mb-1 font-medium">
                  {moment.unix(notification.dateCreated?.seconds).format("ll")}
                </div>
                <p className="text-text-primary leading-relaxed">
                  {notification.body}
                </p>
              </div>
            );
          })}
      </ChildView>
    </div>
  );
};

export default NotificationCenter;
