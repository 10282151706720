import { DrawerOrModal } from "@kanpla/ui";
import { Form, Input, message } from "antd";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ChangePassword = ({ open, setOpen }) => {
  const { t } = useTranslation(["translation", "modals", "login"]);
  const { auth } = useContainer(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const { password, newPassword, newPasswordAgain } = values;

    try {
      if (newPassword !== newPasswordAgain)
        throw new Error(t("login:passwords-dont-match"));

      const credential = EmailAuthProvider.credential(
        auth?.user?.email,
        password
      );

      await reauthenticateWithCredential(auth.user, credential);
      await updatePassword(auth.user, newPassword);

      message.success(t("modals:message.success.password-changed"));
      setOpen(false);
    } catch (err) {
      if (err?.code === "auth/wrong-password") {
        message.error(t("modals:message.error.old-password-incorrect"));
        return;
      }
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:change-password")}
      subtitle={t("modals:modal.subtitles.enter-old-password")}
      actions={[
        {
          label: t("translation:change-password"),
          onClick: () => form.submit(),
          className: "primary",
          loading,
          dataCy: "change-password-button",
        },
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={t("modals:old-password")}
          id="password"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            placeholder={t("modals:old-password")}
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          label={t("translation:new-password")}
          id="newPassword"
          name="newPassword"
          rules={[{ required: true }]}
        >
          <Input.Password
            placeholder={t("translation:new-password")}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label={t("modals:confirm-password")}
          id="newPasswordAgain"
          name="newPasswordAgain"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("modals:form.errors.passwords-are-not-the-same"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t("modals:confirm-password")}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangePassword;
