interface ExtraProps {
  isBulk?: boolean;
  extraQueries?: string;
}

export const constructNewUrl = (
  schoolId: string,
  moduleId: string,
  extra?: ExtraProps
) =>
  `/app/s/${schoolId}/m/${moduleId}${extra?.isBulk ? "/v/admin" : ""}${
    extra?.extraQueries || ""
  }`;
