import { db, fetchDocument } from "@kanpla/system";
import { School } from "@kanpla/types";

interface Props {
  /** Could come from a product bank or directly from a school */
  id: string;
}

export const getSchool = async ({ id }: Props) => {
  const school = await fetchDocument<School>(
    db.collection("schools").doc(id),
    true
  );

  return school;
};
