import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useBoundingclientrectRef } from "rooks";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";
import BannerBackgroundImage from "./BannerBackgroundImage";
import HTMLReactParser from "react-html-parser";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  link: string;
  linkText: string;
  overlayOpacity?: number;
}

const BannerSix = ({
  title,
  imageUrl,
  link,
  linkText,
  subTitle,
  overlayOpacity,
}: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  const width = boundingClientRect?.width || 0;

  if (!title && !imageUrl) return null;

  return (
    <div
      className={`w-full h-auto rounded-lg overflow-hidden relative shadow-lg bg-gray-900`}
      style={{ minHeight: 160 }}
      ref={bannerRef}
    >
      <BannerBackgroundImage
        src={imageUrl}
        alt={title}
        opacity={1 - overlayOpacity / 140}
        size={{ w: width }}
      />
      <div
        className={`flex flex-col h-full relative text-white inset-0 p-4 ${getProportionalHeightPadding(
          width
        )}`}
        style={{ minHeight: 160 }}
      >
        <h3
          className={`h700 my-2 text-white text-center ${getProportionalFontSize(
            width,
            "text-3xl"
          )}`}
        >
          {title}
        </h3>
        <div
          className={`text-white text-center max-w-md mx-auto ${getProportionalFontSize(
            width
          )}`}
        >
          {HTMLReactParser(subTitle)}
        </div>

        {link && linkText && (
          <div className="mt-5 mb-2">
            <p className="text-center text-white transition-colors group">
              <div className="flex items-center justify-center">
                <span className="border-b">{linkText}</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-xs ml-2 transform transition-transform group-hover:translate-x-1"
                />
              </div>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerSix;
