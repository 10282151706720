import { faCards } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateAmountOfOrderItems } from "@kanpla/system";
import { Actions } from "@kanpla/ui";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import useBasketPurchaseLogic from "../basket/useBasketPurchaseLogic";
import Header from "./Header";
import ProductsCarousel from "./ProductsCarousel";
import MoreProductsButton from "./MoreProductsButton";
import ProductsAdvanced from "./ProductsAdvanced";
import Receipt from "../Receipt";

type Props = {};

const Registering = (props: Props) => {
  const { order, items, receiptOpen } = useContainer(MealplanContext);
  const { actions } = useBasketPurchaseLogic();

  const [showReceipt, setShowReceipt] = useState(false);
  const hasOrder = calculateAmountOfOrderItems(order);
  useEffect(() => {
    if (hasOrder || receiptOpen) setShowReceipt(true);
  }, [hasOrder, receiptOpen]);

  const [advancedOrdering, setAdvancedOrdering] = useState(false);

  if (showReceipt)
    return (
      <div>
        <Receipt />
        <MoreProductsButton
          onClick={() => {
            // setAdvancedOrdering(true);
            setShowReceipt(false);
          }}
        />
      </div>
    );

  if (advancedOrdering)
    return (
      <div className="max-w-md mx-auto md:border rounded-lg mt-8">
        <Header />
        <ProductsAdvanced />
        <Actions actions={actions} />
      </div>
    );

  return (
    <div className="max-w-md mx-auto md:border rounded-lg mt-8">
      <Header />
      <ProductsCarousel />
      {items.length > 0 && (
        <>
          <Actions actions={actions} />
          <MoreProductsButton
            onClick={() => {
              setAdvancedOrdering(true);
            }}
          />
        </>
      )}
    </div>
  );
};

export default Registering;
