import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateAmountOfOrderItems } from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import React from "react";
import OrderOptions from "./OrderOptions";

interface Props {
  order: OrderMealplan;
  index: number;
}

const OrderCard = (props: Props) => {
  const { order } = props;
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const dateMoment = moment.unix(order.dateSeconds);
  const formatDay = dateMoment.format("dddd D. M. Y");
  const time = order?.info?.timeInput;

  return (
    <div className="w-full mt-4 md:mt-0 flex flex-col bg-background-secondary rounded-lg shadow-lg py-4 text-text-secondary mr-4">
      <div className="flex flex-col px-4 mt-4">
        <span className="text-md text-text-secondary">{formatDay}</span>
        <div className="flex mt-1">
          <h2 className="text-lg font-semibold h600">
            <span>
              {order.info?.name ||
                t("mealplan2:order-to-d", { value: dateMoment.format("D/M") })}
            </span>
            {time && <>, {moment.utc(time * 1000 || 0).format("HH:mm")}</>}
          </h2>
        </div>
      </div>
      <div className="mt-6 w-full border-dashed border-t border-divider-main flex justify-between px-4 pt-4">
        <div className="flex items-center">
          <b>{calculateAmountOfOrderItems(order.order)}</b>
          <FontAwesomeIcon
            icon={faShoppingBag}
            className="text-text-secondary ml-2"
          />
        </div>

        <OrderOptions order={order} />
      </div>
    </div>
  );
};

export default OrderCard;
