import { callFunction, mergeOrders } from "@kanpla/system";
import { OrderInfo, OrderMealplan, Plugins } from "@kanpla/types";
import { Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { AppContext } from "apps/frontend/components/contextProvider";
import { setWith } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "rooks";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "../..";
import { MealplanContext } from "../../..";
import OrderInfoFields from "../../../OrderInfoFields";
import useSubmit from "../../../useSubmit";
import ConfirmButton from "../../ConfirmButton";
import Receipt from "../../Receipt";
import StepWrapper from "../../Wrapper";
import PaidFromAlert from "../PaidFromAlert";

interface Props {
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

const Finalize = ({ setEditMode }: Props) => {
  const { t } = useTranslation(["mealplan2"]);
  const [form] = useForm();
  const { newOrderInfo, setNewOrderInfo, orderDocument } =
    useContainer(MealplanContext);
  const { editingOrder } = useContext(MealplanMeetingContext);
  const { week, dayIndex, basket, childId, module, moduleId } =
    useContainer(AppContext);
  const { setIsConfirming } = useContext(MealplanMeetingContext);
  const [loading, setLoading] = useState(false);
  const [receiptOpen, setReceiptOpen] = useState(false);
  const { innerWidth: screenWidth } = useWindowSize();

  const submitOrder = useSubmit();

  const plugins = module?.plugins || ({} as Plugins.Array);

  useEffect(() => {
    form.resetFields();
  }, [orderDocument?.id, newOrderInfo]);

  const onPurchase = async () => {
    try {
      const data = await form.validateFields();

      const newData: OrderInfo = Object.entries(data).reduce(
        (acc, [path, value]) => {
          if (!value) return acc;

          const newAcc = setWith(acc, path, value, Object);
          return newAcc;
        },
        {}
      );

      setNewOrderInfo(newData);

      if (plugins?.timeInput?.active && !newData?.timeInput) {
        message.error(t("mealplan2:message.error.fill-delivery-time"));
        return;
      }

      if (
        Object.entries(basket).some(([key, product]) => {
          return product.config.find((item) => item.duplicated);
        })
      ) {
        message.error(t("mealplan2:message.error.set-the-delivery-time"));
        return;
      }

      const mergeBasket = mergeOrders([basket]);

      const infoUpdater = (i: OrderMealplan["info"]) => ({
        ...i,
        ...newData,
      });

      setLoading(true);
      if (editingOrder) {
        await submitOrder({
          updater: () => mergeBasket,
          infoUpdater,
        });
      } else {
        await callFunction("ordering-submitNewOrder", {
          info: newData,
          dateSeconds: week[dayIndex].seconds,
          childId,
          order: basket,
          moduleId,
        });
      }

      form.resetFields();
      setNewOrderInfo(newData);
      setReceiptOpen(true);
    } catch (e) {
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors?.join(", ") || e?.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto">
      <StepWrapper
        pageTitle={t("mealplan2:info-ordering")}
        hideBackButton={screenWidth > 768}
        onBack={() => setIsConfirming(false)}
      >
        <Form onFinish={onPurchase} layout="vertical">
          <div className="flex flex-col w-full justify-center mt-8">
            <OrderInfoFields
              form={form}
              withIndividualTimeEdit
              setEditMode={setEditMode}
              initialValues={newOrderInfo}
            />

            <div className="mt-4">
              <PaidFromAlert />
            </div>
            <ConfirmButton
              loading={loading}
              text={t("mealplan2:confirm-order")}
              className="mt-20"
              dataCy="confirm-order-meeting"
              // onClick={onPurchase}s
            />
          </div>
        </Form>
      </StepWrapper>
      <Receipt
        open={receiptOpen}
        setOpen={setReceiptOpen}
        order={basket}
        orderInfo={newOrderInfo}
        date={week[dayIndex]}
      />
    </div>
  );
};

export default Finalize;
