import { faRedo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateSubscriptionPrice,
  getDeliveryDates,
  priceFormatter,
} from "@kanpla/system";
import { Module, SubscriptionPeriod } from "@kanpla/types";
import { Image, ProductSettingsHeader, TooltipHiddenPrices } from "@kanpla/ui";
import { Checkbox } from "antd";
import moment, { Moment } from "moment";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "..";
import { AppContext } from "../../contextProvider";
import CardPreview from "../../modals/payment/CardPreview";

interface Props {
  isChoosingName: boolean;
  selectedPeriod: SubscriptionPeriod;
  productId: string;
  startDate: Moment;
  loading: boolean;
  existingCardSelected: boolean;
  setExistingCardSelected: (newState: boolean) => void;
  acceptedTerms: boolean;
  setAcceptedTerms: (acceptedTerms: boolean) => void;
  module?: Module;
}

const OrderSummary = (props: Props) => {
  const {
    selectedPeriod,
    productId,
    startDate,
    loading,
    existingCardSelected,
    setExistingCardSelected,
    isChoosingName,
    setAcceptedTerms,
    module,
  } = props;
  const { t, i18n } = useTranslation(["subscription", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { child, card } = useContainer(AppContext);

  const selectedProduct = selectedPeriod?.products?.find(
    (p) => p.id === productId
  );

  const { hidePrices } = module?.config || {};

  if (!selectedPeriod || !selectedProduct) return null;

  const deliveryDates = getDeliveryDates(startDate, selectedPeriod);
  const numberOfDates = deliveryDates.length;

  const fullPrice = calculateSubscriptionPrice(
    selectedProduct,
    numberOfDates,
    "price"
  );
  const fullSubsidies = calculateSubscriptionPrice(
    selectedProduct,
    numberOfDates,
    "subsidies"
  );
  const resultingPrice = calculateSubscriptionPrice(
    selectedProduct,
    numberOfDates
  );

  return (
    <div className="flex flex-col justify-center ">
      <div className="px-2">
        <ProductSettingsHeader
          title={t("subscription:order-overview", {
            value: isChoosingName ? "3." : "2.",
          })}
        />
      </div>

      <div className="text-center mb-6 pt-6 text-text-primary">
        <p>
          <Trans t={t} i18nKey="subscription:to-at">
            Til
            <b>
              {{ childName: child.name.trim() }}
              {{ childGroupName: child.groupName && `, ${child.groupName}` }}
            </b>
            på <b>{{ schoolName: child.school_name }}</b>
          </Trans>
        </p>

        <Image
          src={selectedProduct.photo || ""}
          className="h-40 w-full object-contain mt-6"
          alt=""
        />
        <h4 className="h400 mt-2">{selectedProduct.name}</h4>
        {hidePrices ? null : (
          <p className="h100">
            {priceFormatter(resultingPrice, { language: i18n.language })}{" "}
            {t("subscription:per-days", { value: numberOfDates })}
          </p>
        )}
      </div>

      <span className="form-label font-medium  mb-2">
        {t("subscription:time-overview")}
      </span>

      <InfoLine
        name={t("subscription:delivered-from")}
        value={startDate.format("D/M YYYY")}
      />
      <InfoLine
        name={t("translation:number-of-delivery-days")}
        value={numberOfDates}
      />
      <InfoLine
        name={t("translation:next-invoicing")}
        value={moment.unix(selectedPeriod.to.seconds).format("D/M YYYY")}
      />

      <span className="py-1 text-primary-light text-xs">
        <FontAwesomeIcon icon={faRedo} className="mr-1" />
        {t("subscription:subscription-renewed-automatically")}
      </span>

      <span className="form-label font-medium mt-4 mb-2">
        {t("translation:price-overview")}
      </span>

      <InfoLine
        name={t("subscription:price", {
          value: fullSubsidies > 0 ? t("subscription:values.without") : "",
        })}
        value={fullPrice}
        config={{ type: "money", hasTooltip: false }}
      />
      {fullSubsidies > 0 && (
        <InfoLine
          name={t("subscription:eu-grants")}
          value={fullSubsidies}
          config={{ type: "money", hasTooltip: false }}
        />
      )}
      <p className="flex justify-between py-2">
        <span className="h400">{t("subscription:for-payment")}</span>
        <span className="h400">
          {priceFormatter(
            resultingPrice,
            { language: i18n.language },
            false,
            hidePrices,
            { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
          )}
        </span>
      </p>

      <hr className="mb-4" />

      {card && (
        <div className="form-block">
          <span className="form-label">{t("translation:payment-method")}</span>
          <div className="mt-2">
            <div className="">
              <label className="flex items-center bg-background-secondary rounded py-2 px-1">
                <div className="mx-3">
                  <input
                    type="radio"
                    className="form-radio"
                    name="radio"
                    disabled={loading}
                    checked={existingCardSelected}
                    onChange={(e) => setExistingCardSelected(!!e.target.value)}
                  />
                </div>
                <CardPreview card={card} />
              </label>
            </div>
            <div className="">
              <label className="flex items-center px-4 py-3 bg-background-secondary rounded mt-2">
                <input
                  type="radio"
                  className="form-radio"
                  name="radio"
                  disabled={loading}
                  checked={!existingCardSelected}
                  onChange={(e) => setExistingCardSelected(!e.target.value)}
                />
                <span className="ml-2 text-sm text-text-primary">
                  {t("translation:add-card-short")}
                </span>
              </label>
            </div>
          </div>
        </div>
      )}

      <label className="text-text-secondary mb-2 text-left flex">
        <Checkbox
          className="mr-2"
          onChange={(event) => setAcceptedTerms(event.target.checked)}
        />
        <span className="text-sm leading-relaxed">
          <Trans t={t} i18nKey="subscription:accept">
            Jeg accepterer
            <a
              href="/kanpla_abonnementsvilkar.pdf"
              className="underline"
              target="_blank"
            >
              Abonnementsvilkår
            </a>
          </Trans>
        </span>
      </label>

      {loading && (
        <p className="text-center text-sm text-text-secondary pb-4">
          {" "}
          {t("translation:payment-can-be-slow")}{" "}
        </p>
      )}
    </div>
  );
};

export default OrderSummary;

const InfoLine = ({
  name,
  value,
  config = { type: "", hasTooltip: false },
}) => {
  const { i18n } = useTranslation();

  const { module } = useContext(SubscriptionContext);

  const { hidePrices } = module?.config || {};

  const { type, hasTooltip } = config;

  const configObj = hasTooltip
    ? { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
    : { wrapper: () => "-" };

  const targetValue =
    type === "money"
      ? priceFormatter(
          value,
          { language: i18n.language },
          false,
          hidePrices,
          configObj
        )
      : value;

  return (
    <p className="flex justify-between pt-1 border-b pb-1 text-xs text-text-secondary">
      <span>{name}</span>
      <span>{targetValue}</span>
    </p>
  );
};
