import { db } from "@kanpla/system";
import { DrawerOrModal } from "@kanpla/ui";
import { Checkbox, Form, message } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const Notifications = ({ open, setOpen }) => {
  const { t } = useTranslation(["modals", "translation"]);
  const { user, userId } = useContainer(AppContext);
  const [notificationPreferences, setNotificationPreferences] = useState({
    emailOnOrder: false,
    emailOnPayment: true,
  });

  const updatePreference = (name, value) =>
    setNotificationPreferences((preferences) => ({
      ...preferences,
      [name]: value,
    }));

  useEffect(() => {
    if (user && user.notificationPreferences) {
      Object.entries(user.notificationPreferences).forEach(([name, value]) =>
        updatePreference(name, value)
      );
    }
  }, [user]);

  const handleSubmit = async () => {
    if (!userId) return;

    await db
      .collection("users")
      .doc(userId)
      .update({ notificationPreferences });

    message.success(t("modals:message.success.mail-preferences-saved"));
    setOpen(false);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:mail-preferences")}
      actions={[
        {
          label: t("translation:confirm"),
          onClick: handleSubmit,
          className: "primary",
        },
      ]}
    >
      <Form.Item>
        <Checkbox
          onChange={() =>
            updatePreference(
              "emailOnOrder",
              !notificationPreferences.emailOnOrder
            )
          }
          checked={notificationPreferences.emailOnOrder}
        >
          {t("modals:order-confirmation")}
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          onChange={() =>
            updatePreference(
              "emailOnPayment",
              !notificationPreferences.emailOnPayment
            )
          }
          checked={notificationPreferences.emailOnPayment}
        >
          {t("modals:payment-confirmation")}
        </Checkbox>
      </Form.Item>
    </DrawerOrModal>
  );
};

export default Notifications;
