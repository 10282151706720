import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  receiptTime: number;
  onBack: () => void;
  childName: string;
  schoolName: string;
}

const Header = (props: Props) => {
  const { t, i18n } = useTranslation(["translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { receiptTime, childName, schoolName } = props;

  const [now, setNow] = useState(moment().unix());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(() => moment().unix());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const secondsSincePurchase = Math.abs(now - receiptTime);

  const formatMinutes = Math.floor(secondsSincePurchase / 60);
  const formatSeconds = secondsSincePurchase % 60;

  const valid = secondsSincePurchase < 120;
  const isLate = secondsSincePurchase > 600;
  const isPastMinute = secondsSincePurchase > 60;

  const backgroundColor = valid
    ? `bg-success-main`
    : isLate
    ? `bg-danger-main`
    : `bg-warning-main`;

  return (
    <div
      className={`flex justify-center items-center z-20 p-10 transition-colors ease-in-out -mt-10 md:-mt-0 relative ${backgroundColor}`}
      style={{
        borderBottomLeftRadius: "90% 10%",
        borderBottomRightRadius: "90% 10%",
      }}
    >
      <div className="flex flex-col items-center text-background-primary pt-4">
        <h1 className={`h800 text-background-primary`}>
          {isLate
            ? `${moment.unix(receiptTime).fromNow()}`
            : isPastMinute
            ? `${formatMinutes}m ${formatSeconds}s ${t("translation:ago")}`
            : `${formatSeconds}s ${t("translation:ago")}`}
        </h1>
        <p className="mt-1 font-2xl text-background-primary">
          {childName.trim()}, {schoolName}
        </p>
      </div>
    </div>
  );
};

export default Header;
