import { Image } from "@kanpla/ui";
import React from "react";

interface Props {
  src: string;
  alt: string;
  className?: string;
  /** 0 - 100 */
  opacity?: number;
  size?: {
    w?: number;
    h?: number;
  };
}

const BannerBackgroundImage = ({
  src,
  alt,
  className = "",
  opacity,
  size = {},
}: Props) => {
  if (!src) return null;

  return (
    <div
      className={`absolute inset-0 overflow-hidden ${className}`}
      style={{ opacity }}
    >
      <Image
        size={size}
        src={src}
        alt={alt}
        className="relative h-full w-full object-center object-cover"
      />
    </div>
  );
};

export default BannerBackgroundImage;
