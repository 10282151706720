import { callFunction, getActivePlugins } from "@kanpla/system";
import { Child, School } from "@kanpla/types";
import {
  DrawerOrModal,
  FormInput,
  InvoiceReference,
  SelectorInputWrapper,
} from "@kanpla/ui";
import { useForm } from "antd/lib/form/Form";
import { Button, Form, message, Popconfirm } from "antd";
import { isEmpty } from "lodash";
import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  child: Child;
  school: School;
}

const SalesplaceEdit: FC<Props> = ({ open, setOpen, child, school }) => {
  const [selectors, setSelectors] = useState<Child["selectors"]>(null);
  const [newCode, setNewCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();

  const { children, allModules, triggerReload } = useContainer(AppContext);

  const { t } = useTranslation(["translation", "settings"]);

  const moduleWithReference = allModules
    .filter((module) =>
      module?.scope?.generatedSchoolIds?.includes(child?.schoolId)
    )
    .filter(
      (module) =>
        getActivePlugins({
          module,
          schoolId: child?.schoolId,
          groupName: child?.groupName,
        }).invoiceReference
    )?.[0];
  const hasReferencePlugin = !!moduleWithReference;

  const allAvailableCodes = allModules
    .filter((module) =>
      module?.scope?.generatedSchoolIds?.includes(child?.schoolId)
    )
    .map((module) => {
      if (!module || isEmpty(module)) return null;

      const schoolSpecificSettings = school?.modules?.[module.id] || {};

      return [
        ...(module.config?.codes || []),
        ...(module.config?.bulkCodes || []),
        ...((!schoolSpecificSettings.individualDisabled &&
          schoolSpecificSettings.codes) ||
          []),
        ...((schoolSpecificSettings.bulkEnabled &&
          schoolSpecificSettings.bulkCodes) ||
          []),
      ];
    })
    .flat();

  const hasAccessCodes = allAvailableCodes.length > 0;

  const handleDeleteSalesplace = async () => {
    try {
      const originalChild = children.find((child) => child?.isMainChild);

      const originalChildId =
        originalChild?.id ||
        children.find((c) => c.copyFromChildId)?.copyFromChildId;

      await callFunction("salesplaces-deleteChildSalesplace", {
        originalChildId,
        schoolId: school.id,
      });

      triggerReload();

      message.success(t("settings:message.success.salesplace-deleted"));
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setOpen(false);
    }
  };

  const onConfirm = async (data) => {
    try {
      setLoading(true);

      console.log(data);
      console.log(selectors);

      const originalChild = children.find((child) => child?.isMainChild);
      const originalChildId = originalChild?.id;

      const childCodes = child?.codes || [];

      await callFunction("salesplaces-editChildSalesplace", {
        originalChildId,
        schoolId: school.id,
        defaultReference: data.invoiceReference || null,
        selectors: data.selectors,
        codes: newCode ? [...childCodes, newCode] : childCodes,
      });

      message.success(t("settings:message.success.salesplace-updated"));
      setOpen(false);
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!child) return null;

  return (
    <DrawerOrModal
      title={t("settings:edit-locations")}
      zIndex={40}
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("translation:confirm"),
          onClick: () => form.submit(),
          type: "primary",
          loading,
        },
      ]}
    >
      <Form
        form={form}
        onFinish={onConfirm}
        initialValues={{
          invoiceReference: child?.defaultReference,
          selectors: child?.selectors,
        }}
      >
        {school?.selectors && (
          <>
            <h3 className="h400 mb-2 text-text-primary">
              {t("translation:selectors")}
            </h3>
            <Form.Item name="selectors">
              <SelectorInputWrapper school={school} />
            </Form.Item>
          </>
        )}

        {hasReferencePlugin && (
          <InvoiceReference
            noBg
            module={moduleWithReference}
            defaultReference={null}
          />
        )}

        {hasAccessCodes && (
          <FormInput
            label={t("translation:form.labels.special-password")}
            note={t("translation:form.notes.grandet-access")}
            value={newCode}
            setValue={(v) => setNewCode(v)}
            name="special-password"
          />
        )}

        <h3 className="h400 mt-4 text-text-primary">
          {t("translation:other-options")}
        </h3>
        <Popconfirm
          title={t("translation:are-you-sure")}
          onConfirm={handleDeleteSalesplace}
          onCancel={() => true}
          okText={t("translation:yes")}
          cancelText={t("translation:cancel")}
        >
          <Button danger size="small" className="mt-2">
            {t("settings:delete-location")}
          </Button>
        </Popconfirm>
      </Form>
    </DrawerOrModal>
  );
};

export default SalesplaceEdit;
