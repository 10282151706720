import { useForceUpdate } from "@kanpla/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import { default as JumpLink } from "../JumpLink";
import PastDateWrapper, { PastDateBanner } from "../PastDateWrapper";
import SubscriptionBanner from "../SubscriptionBanner";
import Category from "./ProductCategory";
import ProductSuggestion from "./ProductSuggestion";
import { useCategories } from "./useCategories";

const Products = () => {
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { child, school, basket, setBasket, dateSeconds } =
    useContainer(AppContext);
  const {
    noMealplan,
    deadlineFormatted,
    defaultDate,
    pastDate,
    module,
    nextOfferDate,
  } = useContainer(MealplanContext);

  const categories = useCategories();

  useEffect(() => {
    return () => setBasket(basket);
  }, [deadlineFormatted]);

  const [isExtraModalOpen, setIsExtraModalOpen] = useState(false);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [basket, forceUpdate]);

  const selectedDate = moment.unix(dateSeconds);

  if (selectedDate.isValid() && selectedDate.isBefore(moment(), "day"))
    return <PastDateBanner defaultDate={nextOfferDate} />;

  if (noMealplan)
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary h800">
            <Trans t={t} i18nKey="mealplan2:come-back-later">
              Køkkenet har endnu ingen madplan.
              <br />
              Kom tilbage senere.
            </Trans>
          </h2>
          <p className="text-text-secondary">
            {defaultDate?.seconds ? (
              <Trans t={t} i18nKey="mealplan2:if-you-are-hungry">
                Hvis du er sulten kan du allerede nu bestille til
                <JumpLink defaultDate={defaultDate} />!
              </Trans>
            ) : (
              <span>{t("translation:there-are-no-meal-plans")}</span>
            )}
          </p>
        </div>
      </section>
    );

  // Temp (milk banner on top for Steffen's schools)
  const schoolId = child?.school_ref?.id;
  const bannerOnTop =
    schoolId === "o9Yp7yGoJijZESdsIBhO" || schoolId === "0YPMr00awHyXZdobICI3";

  return (
    <PastDateWrapper
      pastDate={pastDate}
      defaultDate={defaultDate}
      setOpenModal={setIsExtraModalOpen}
    >
      <>
        {school && !pastDate && (
          <>
            {/* {bannerOnTop && <SubscriptionBanner noBorder />} */}
            {Object.entries(categories).map(([name, items], index) => (
              <Category
                noMarginTop={index === 0}
                key={name}
                name={name}
                items={items}
                showChildrenAnyway
                isHidden={items.length === 0}
                overrideVisible={isExtraModalOpen}
                index={index}
              />
            ))}
          </>
        )}

        {/* Loading */}
        {!school && (
          <div>
            <div className="h-6 w-32 mt-3 lg:mt-0 loader rounded mb-2" />
            <LoaderCategory>
              <div className="w-full h-48 md:h-56 loader rounded-lg" />
            </LoaderCategory>
            <LoaderCategory>
              <div className="w-full h-24 loader rounded-lg" />
              <div className="w-full h-24 loader rounded-lg" />
              <div className="w-full h-24 loader rounded-lg" />
            </LoaderCategory>
            <LoaderCategory>
              <div className="w-full h-24 loader rounded-lg" />
              <div className="w-full h-24 loader rounded-lg" />
              <div className="w-full h-24 loader rounded-lg" />
            </LoaderCategory>
          </div>
        )}

        {!bannerOnTop && <SubscriptionBanner noBorder={false} />}
        {module.plugins?.userSuggestions?.active && <ProductSuggestion />}
      </>
    </PastDateWrapper>
  );
};

export default Products;

const LoaderCategory = ({ children }) => (
  <div className="pb-6 pt-1">
    <div className="h-6 w-32 mt-3 lg:mt-0 loader rounded mb-3" />
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
      {children}
    </div>
  </div>
);
