import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, isPartnerAdmin, exportOrders } from "@kanpla/system";
import { ColumnTableList, Modal } from "@kanpla/ui";
import { Button, DatePicker, message, Space, Steps, Switch } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import ColumnTable from "./ColumnTable";
import { ExportOrderColumns } from "@kanpla/types";

const Export = () => {
  const { t, i18n } = useTranslation(["translation", "mealplan2"]);
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });
  const [open, setOpen] = useState(false);
  const { userId, partnerId, module, allModules, children, schoolId} = useContainer(AppContext);

  const moduleIds: string[] = useMemo(() => allModules.map(m => m.id), [allModules]);
  const childrenIds: string[] = useMemo(() => children.map(c => c.id), [children]);
  /**
   * false = Just my orders
   * true = Whole salesplace's orders
   */
  const [wholeSchoolOrders, setWholeSchoolOrders] = useState(false);

  const [userIsAdmin, setUserIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await isPartnerAdmin({
        db,
        userId,
        partnerId,
      });
      setUserIsAdmin(res);
    })();
  }, [userId, partnerId]);

  const [columnSettings, setColumnSettings] = useLocalstorageState<ColumnTableList>(
    `export-${module?.id}-columns`,
    [
      {
        title: t("translation:date"),
        path: "date",
      },
      {
        title: t("mealplan2:name-of-the-order"),
        path: "info//name",
      },
      {
        title: t("translation:total-price"),
        path: "calculateTotal",
      },
    ],
  );

  useEffect(() => {
    if (
      columnSettings &&
      (columnSettings as any) !== "undefined" &&
      (columnSettings as any) !== "null"
    )
      return;

    setColumnSettings([
      {
        title: t("translation:date"),
        path: "date",
      },
      {
        title: t("mealplan2:name-of-the-order"),
        path: "info//name",
      },
      {
        title: t("translation:total-price"),
        path: "calculateTotal",
      },
    ]);
  }, []);

  const [range, setRange] = useState<[Moment, Moment]>([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);

  const [loading, setLoading] = useState(false);

  const downloadExcel = useCallback(async (): Promise<void> => {
    const [start, end] = range;
    const selectedColumnsConfig: ExportOrderColumns = {};
    columnSettings.filter(item => item.path?.trim()).forEach(({ path, title }) => {
      selectedColumnsConfig[path] = {
        displayName: title?.trim(),
      };
    });

    setLoading(true);
    try {
      await exportOrders({
        fromSeconds: start.unix(),
        toSeconds: end.unix(),
        moduleIds,
        childrenIds,
        schoolIds: [schoolId],
        returnType: 'xls',
        columns: selectedColumnsConfig,
      });
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  }, [range, moduleIds, schoolId, childrenIds, columnSettings]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Space>
          {t("mealplan2:export-history")} <FontAwesomeIcon icon={faDownload} />
        </Space>
      </Button>
      <Modal
        open={open}
        setOpen={setOpen}
        title={t("mealplan2:export-your-history")}
        containerClassName="flex items-center"
        className="max-w-none md:max-w-lg lg:max-w-xl"
      >
        <Steps current={-1} direction="vertical" className="text-text-primary">
          <Steps.Step
            title={t("mealplan2:select-time")}
            description={
              <DatePicker.RangePicker
                allowClear={false}
                value={range}
                onChange={setRange}
                ranges={{
                  [t("mealplan2:this-month")]: [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  [t("mealplan2:last-month")]: [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  [t("translation:this-week")]: [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  [t("mealplan2:last-week")]: [
                    moment().subtract(1, "week").startOf("week"),
                    moment().subtract(1, "week").endOf("week"),
                  ],
                }}
              />
            }
          />
          <Steps.Step
            title={t("mealplan2:select-columns")}
            description={
              <ColumnTable
                columns={columnSettings}
                setColumns={setColumnSettings}
              />
            }
          />
          {userIsAdmin && (
            <Steps.Step
              title={t("mealplan2:what-orders")}
              description={
                <Space>
                  <Switch
                    checked={wholeSchoolOrders}
                    onChange={(ch) => setWholeSchoolOrders(ch)}
                  />
                  <p className="text-text-primary">
                    {t("mealplan2:export-point-of-sale-or-my-orders", {
                      value: wholeSchoolOrders
                        ? t("mealplan2:values.point-of-sale")
                        : t("mealplan2:values.only-my-orders"),
                    })}
                  </p>
                </Space>
              }
            />
          )}
          <Steps.Step
            description={
              <Button type="primary" loading={loading} onClick={downloadExcel}>
                {t("mealplan2:export-data")}
              </Button>
            }
          />
        </Steps>
      </Modal>
    </>
  );
};

export default Export;
