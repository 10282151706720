import {
  db,
  deadlineDisplay,
  deadlineFormatter,
  fn,
  getFlexOrder,
  getFlexStandard,
  getOrderConfigs,
  groupAllItems,
  groupItemsByDayAndByCategory,
  hasAccessToModule,
  isDatePastDeadline,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  GroupedCombinedOffer,
  IBaseProducts,
  OrderInfo,
  OrderOrder,
  OrderOrderProduct,
} from "@kanpla/types";
import {
  ProductInfosModal,
  SelectProductModal,
  VariantsModal,
} from "@kanpla/ui";
import { message } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import { isEmpty } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import FlexLoader from "./FlexLoader";
import Menu from "./flexView/Menu";
import StandardSettings from "./StandardSettings";

const ContextState = () => {
  const { t } = useTranslation(["flex", "translation"]);

  const {
    school,
    week,
    dayIndex,
    child,
    userId,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    offer,
  } = useContainer(AppContext);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [allowOrder, setAllowOrder] = useState(false);

  const router = useRouter();

  const hasVariantsLimitPlugin = module?.plugins?.variantsLimit?.active;
  const [hasVariantsLimit, setHasVariantsLimit] = useState(false);

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.individual && ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(true);
      router.replace(newUrl);
    } else if (!ha.individual && !ha.bulk) {
      setIsBulk(false);
      router.replace("/app");
    }
  }, []);

  // Offers
  const dateSeconds = week[dayIndex]?.seconds;
  const { items, holidayDates, deadlineInfo, mealOptions } = offer || {};

  const groupedItems = groupItemsByDayAndByCategory({
    items: items as CombinedOfferItem[],
    week,
  });

  const allGroupedItems = groupAllItems({
    items: items as CombinedOfferItem[],
    week,
  });

  // Deadline Jump
  useDeadlineJump({ defaultDate: deadlineInfo?.defaultDate });

  // Standard
  const { standard, standardLoading } = getFlexStandard({
    moduleId,
    childId,
    userId,
    schoolId,
    isBulk: false,
    db,
  });

  // Order
  const { order, orderLoading, orderDocument, orderInfo, weekOrders } =
    getFlexOrder({
      schoolId,
      moduleId,
      childId,
      isBulk: false,
      db,
      date: date || null,
      standard,
      week,
    });

  const numberOfItems = Object.values(order).reduce(
    (a: number, c: OrderOrderProduct) => a + c.amount,
    0
  ) as number;
  const hasOrdered = numberOfItems > 0;

  useEffect(() => {
    setTimeNavigation("todaySwitch");
  }, []);

  // Submit order
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(
    () =>
      saving
        ? message.loading(t("flex:message.loading.order-being-saved"), 0)
        : message.destroy(),
    [saving]
  );

  useEffect(() => {
    if (hasVariantsLimitPlugin && order) {
      const orderConfigs = getOrderConfigs(order);
      const variantsLimit = module?.plugins?.variantsLimit?.limit;

      if (orderConfigs.length === variantsLimit) {
        setHasVariantsLimit(true);
      } else {
        setHasVariantsLimit(false);
      }
    }
  }, [order]);

  const submit = async (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string
  ) => {
    if (!schoolId) return;

    // const orderData = isBulk ? newOrder : newOrder;
    const orderData = newOrder;

    try {
      setSaving(true);

      // Check if some text inputs are required
      if (module?.plugins?.textInput?.active) {
        const { textInput } = orderInfo;
        const getRequiredField = module.plugins?.textInput?.fields.find(
          (field) => field?.required && isEmpty(textInput?.[field.key])
        );
        const shouldThrowError = !textInput || !!getRequiredField;

        if (shouldThrowError) {
          throw new Error(
            t("translation:form.errors.is-required", {
              value: getRequiredField.title,
            })
          );
        }
      }

      if (hasVariantsLimitPlugin) {
        const orderConfigs = getOrderConfigs(orderData);
        const variantsLimit = module?.plugins?.variantsLimit?.limit;

        if (orderConfigs.length > variantsLimit)
          throw new Error(
            t("translation:plural.limitOrder", {
              value: variantsLimit,
              count: variantsLimit,
            })
          );
      }

      const submitOrderToServer = fn.httpsCallable("submitFlexOrder");
      await submitOrderToServer({
        schoolId,
        dateSeconds: Number(dateSeconds),
        order: orderData,
        orderInfo: info,
        moduleId,
        childId,
      });

      setAllowOrder(false);
    } catch (err) {
      console.error(err);
      message.error(err?.message || t("translation:something-went-wrong"));
    } finally {
      setSaving(false);
    }
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
  } = deadlineInfo || {};
  const pastDate = isDatePastDeadline({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  const showReceipt = !allowOrder;
  const loading = orderLoading || standardLoading;

  const activeHoliday = holidayDates?.[dateSeconds];

  /**
   * Default selected product should be "No Lunch" || "Ingen Frokost"
   */
  const baseProducts = useCallback(
    ({ setStandard, both }: { setStandard: boolean; both: boolean }) => {
      return Object.keys(allGroupedItems).reduce((acc, dateSeconds) => {
        const orderedProduct = weekOrders?.[dateSeconds]
          ? {
              id:
                items?.find((item) => {
                  const prop = setStandard
                    ? weekOrders?.[dateSeconds]
                    : weekOrders?.[dateSeconds]?.order;

                  const bothProductIds =
                    Object.keys(weekOrders?.[dateSeconds]?.order || {})[0] ||
                    Object.keys(weekOrders?.[dateSeconds] || {})[0];

                  const productId = Object.keys(prop || {})[0];

                  return item.id === (both ? bothProductIds : productId);
                })?.id || "noLunch",
              dateSeconds: dateSeconds.toString(),
              isStandard: weekOrders?.[dateSeconds]?.order ? false : true,
            }
          : {
              id: "noLunch",
              dateSeconds: dateSeconds.toString(),
            };

        return {
          ...acc,
          [dateSeconds]: orderedProduct,
        };
      }, {});
    },
    [JSON.stringify(weekOrders)]
  );

  const baseStandardProducts = useMemo(() => {
    return Object.keys(allGroupedItems).reduce((acc, dateSeconds, index) => {
      const standardProduct = {
        id:
          items?.find((item) => {
            const standardIds = Object.keys(standard?.[index] || {});
            const itemIsPresent = standardIds?.includes(item?.id);

            return itemIsPresent;
          })?.id || "noLunch",
        dateSeconds: dateSeconds.toString(),
      };
      return {
        ...acc,
        [dateSeconds]: standardProduct,
      };
    }, {});
  }, [JSON.stringify(weekOrders)]);

  /** NEW FLOW FOR INDIVIDUAL FLEX */
  /** Standard products */
  const [standardProducts, setStandardProducts] = useState<IBaseProducts>(null);
  /** Day by day products */
  const [weekProducts, setWeekProducts] = useState<IBaseProducts>(null);

  /** Set the week products every time that the week switch */
  useEffect(() => {
    setWeekProducts(baseProducts({ setStandard: false, both: true }));
    setStandardProducts(baseStandardProducts);
  }, [JSON.stringify(weekOrders)]);

  /** Week standard products to save on db */
  const [standardWeek, setStandardWeek] =
    useState<FlexBulkStandard["standard"]>(standard);

  useEffect(() => {
    setStandardWeek(standard);
  }, [JSON.stringify(standard)]);

  /** Standard products modal */
  const [selectProductOpen, setSelectProductOpen] = useState(false);
  /** Products to display for each day inside the modal */
  const [dayProducts, setDayProducts] =
    useState<{ [key: string]: CombinedOfferItem[] }>(null);
  /** Seconds needed to find the current products */
  const [dayDateSeconds, setDayDateSeconds] = useState("");

  const [selectedProduct, setSelectedProduct] =
    useState<CombinedOfferItem>(null);

  const [isStandard, setIsStandard] = useState(false);

  /** Variants and Infos of the product */
  const [openProductInfos, setOpenProductInfos] = useState(false);
  const [openVariants, setOpenVariants] = useState(false);
  const [data, setData] = useState<CustomOrderContent>(Object);

  /** Find the products to display based on the seconds of the week */
  const getCurrentProducts = ({
    label,
    isStandard = false,
    allGroupedItems,
  }: {
    label: string;
    isStandard?: boolean;
    allGroupedItems?: GroupedCombinedOffer;
  }) => {
    const dateSeconds = Object.entries(allGroupedItems || groupedItems).find(
      ([dateSeconds, _]) => {
        const dayName = moment
          .unix(Number(dateSeconds))
          .format("dddd")
          .toLowerCase();

        const isSameDay = dayName === (label as string).toLowerCase();

        return isSameDay;
      }
    )?.[0];

    if (dateSeconds) {
      setDayProducts((allGroupedItems || groupedItems)[dateSeconds]);
      setDayDateSeconds(dateSeconds);
    }

    setIsStandard(isStandard);
    setSelectProductOpen(true);
  };

  const selectProductByDay = ({
    product,
    dateSeconds,
    noLunch,
  }: {
    dateSeconds: string;
    product?: CombinedOfferItem;
    noLunch?: boolean;
  }) => {
    (isStandard ? setStandardProducts : setWeekProducts)((prevState) => {
      const date = Object.keys(prevState).find((prev) => prev === dateSeconds);
      const productId = noLunch ? "noLunch" : product["id"];

      return {
        ...prevState,
        [date]: {
          id: productId,
          dateSeconds,
        },
      };
    });
  };

  const onPurchase = async (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => {
    const newOrder: OrderOrder = {
      [product?.id]: {
        amount: 1,
        price: product?.price,
        name: product?.name,
        config: [
          {
            name: product?.name,
            amount: 1,
            options: data?.optionChoices,
          },
        ],
      },
    };

    const newInfo: OrderInfo = {};

    await submit(newOrder, newInfo, date);
  };

  const submitStandard = async ({ fromAdmin }: { fromAdmin?: boolean }) => {
    try {
      message.loading({
        key: "loading-saving-standard",
        content: t("translation:message.loading.standards-are-saved"),
      });

      // Delete empty lunches before submitting
      delete standardWeek?.[0]?.no_lunch;
      delete standardWeek?.[1]?.no_lunch;
      delete standardWeek?.[2]?.no_lunch;
      delete standardWeek?.[3]?.no_lunch;
      delete standardWeek?.[4]?.no_lunch;

      const submitToServer = fn.httpsCallable("submitFlexStandard");

      await submitToServer({
        standard: standardWeek,
        schoolId,
        moduleId,
        userId,
        childId,
        fromAdmin,
      });

      message.destroy();
      message.success({
        key: "success-saving-standard",
        content: t("translation:message.success.standards-updated"),
      });
    } catch (err) {
      message.error({
        key: "error-saving-standard",
        content: t("translation:message.error.changes-could-not-be-saved"),
      });
    } finally {
      setSettingsOpen(false);
    }
  };

  return {
    module,
    items,
    groupedItems,
    moduleId: module.id,

    deadline,
    deadlineWeekRelative,
    defaultDate,
    pastDate,
    softDeadline: deadlineSoft,

    softDeadlineMaxAmount,
    deadlineFormatted,

    standard,
    standardLoading,

    orderDocument,
    order,
    numberOfItems,
    hasOrdered,
    submit,
    saving,
    setSaving,
    isBulk: false,
    settingsOpen,
    setSettingsOpen,

    hasVariantsLimit,
    setHasVariantsLimit,

    orderInfo,
    showReceipt,
    loading,
    activeHoliday,
    setAllowOrder,
    mealOptions,

    getCurrentProducts,

    standardProducts,
    setStandardProducts,

    selectProductOpen,
    setSelectProductOpen,

    dayProducts,
    setDayProducts,

    dayDateSeconds,
    setDayDateSeconds,

    weekProducts,
    setWeekProducts,

    isStandard,

    openProductInfos,
    setOpenProductInfos,

    openVariants,
    setOpenVariants,

    data,
    setData,

    selectedProduct,
    setSelectedProduct,

    onPurchase,

    selectProductByDay,

    standardWeek,
    setStandardWeek,

    submitStandard,

    deadlineExcludingWeekends,

    allGroupedItems,
  };
};

export const FlexContext = createContainer(ContextState);

const Index = () => (
  <FlexContext.Provider>
    <View />
  </FlexContext.Provider>
);

const View = () => {
  const {
    activeHoliday,
    defaultDate,
    deadlineFormatted,
    loading,
    module,
    selectProductOpen,
    setSelectProductOpen,
    dayProducts,
    isBulk,
    standardProducts,
    dayDateSeconds,
    weekProducts,
    isStandard,
    openProductInfos,
    setOpenProductInfos,
    openVariants,
    setOpenVariants,
    data,
    setData,
    selectedProduct,
    setSelectedProduct,
    order,
    orderInfo,
    mealOptions,
    submit,
    onPurchase,
    selectProductByDay,
    setDayDateSeconds,
    setStandardWeek,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  } = useContainer(FlexContext);

  const { innerAppLoading, week, mobile } = useContainer(AppContext);

  const { hidePrices } = module?.config || {};

  if (activeHoliday)
    return (
      <>
        <NavbarSecondary
          timeNavigation="weekly"
          deadlineFormatted={deadlineFormatted}
        />
        <CanteenClosed
          defaultDate={defaultDate}
          holidayDesc={activeHoliday.design}
        />
      </>
    );

  return (
    <>
      <NavbarSecondary
        timeNavigation="weekly"
        deadlineFormatted={deadlineDisplay({
          deadline,
          deadlineWeekRelative,
          deadlineExcludingWeekends,
        })}
      />
      <div className="py-3 md:pb-12 wrapper select-none overflow-hidden">
        <ModuleDescription align="center" module={module} />
        <ModuleLoadingWrapper loading={innerAppLoading}>
          {loading ? <FlexLoader /> : <Menu />}
          <StandardSettings />
          <SelectProductModal
            open={selectProductOpen}
            setOpen={setSelectProductOpen}
            products={dayProducts}
            isBulk={isBulk}
            selectedProducts={isStandard ? standardProducts : weekProducts}
            date={dayDateSeconds}
            week={week}
            deadlineFormatted={deadlineFormatted}
            isStandard={isStandard}
            mobile={mobile}
            hidePrices={hidePrices}
            setSelectedProduct={setSelectedProduct}
            setOpenVariants={setOpenVariants}
            setOpenProductInfos={setOpenProductInfos}
            setDayDateSeconds={setDayDateSeconds}
            selectProductByDay={selectProductByDay}
            onPurchase={onPurchase}
            submit={submit}
            setStandardWeek={setStandardWeek}
            weekProducts={weekProducts}
          />
          <ProductInfosModal
            open={openProductInfos}
            setOpen={setOpenProductInfos}
            product={selectedProduct}
            currentDay={selectedProduct?.dates[dayDateSeconds]}
            date={dayDateSeconds}
            order={order}
            orderInfo={orderInfo}
          />
          <VariantsModal
            open={openVariants}
            setOpen={setOpenVariants}
            product={selectedProduct}
            currentDay={selectedProduct?.dates[dayDateSeconds]}
            mealOptions={mealOptions}
            module={module}
            data={data}
            setData={setData}
            onPurchase={onPurchase}
            date={dayDateSeconds}
            selectProductByDay={selectProductByDay}
          />
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};

export default Index;
