import { Homescreen } from "@kanpla/types";
import { Space } from "antd";
import React, { useContext } from "react";
import { HomescreenContext } from "..";
import serializer from "../utils/serializer";

const Editor = ({ singleBlock }: { singleBlock: Homescreen.ContentInner }) => {
  const { content } = useContext(HomescreenContext);

  const childNodes = singleBlock.nodes;

  return (
    <div className="w-full">
      <Space direction="vertical" size="large" className="w-full">
        {childNodes.map((nodeId) => {
          const node = content[nodeId];

          return serializer(node, nodeId);
        })}
      </Space>
    </div>
  );
};

export default Editor;
