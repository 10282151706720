import { ActionType, DrawerOrModal } from "@kanpla/ui";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  actions: ActionType[];
  children: ReactNode;
  zMax?: boolean;
}

const EditBasketModal = ({
  children,
  open,
  setOpen,
  title,
  actions,
  zMax,
}: Props) => {
  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={title}
      actions={actions}
      modalProps={{
        closableOutside: false,
      }}
      zMax={zMax}
    >
      {children}
    </DrawerOrModal>
  );
};

export default EditBasketModal;
