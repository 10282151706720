import { Homescreen } from "@kanpla/types";
import Banner from "../blocks/Banner";
import Button from "../blocks/Button";
import Editor from "../blocks/Editor";
import FlexMenu from "../blocks/FlexMenu";
import Image from "../blocks/Image";
import Products from "../blocks/Products";
import SingleProductBlock from "../blocks/Products/SingleProductBlock";
import Root from "../blocks/Root";
import Section, { SectionInner } from "../blocks/Section";
import Text from "../blocks/Text";

const serializer = (singleBlock: Homescreen.ContentInner, key: string) => {
  if (!singleBlock) return null;

  if (singleBlock.type === "div") return <Root singleBlock={singleBlock} />;

  switch (singleBlock.type?.resolvedName || singleBlock?.type) {
    case "EditorBlock":
      return <Editor singleBlock={singleBlock} key={key} />;
    case "SectionComponent":
      return <Section singleBlock={singleBlock} key={key} />;
    case "ButtonComponent":
      return <Button singleBlock={singleBlock} key={key} />;
    case "FlexMenuComponent":
      return <FlexMenu singleBlock={singleBlock} key={key} />;
    case "SectionInner":
      return <SectionInner singleBlock={singleBlock} key={key} />;
    case "ImageComponent":
      return <Image singleBlock={singleBlock} key={key} />;
    case "ProductsComponent":
      return <Products singleBlock={singleBlock} key={key} />;
    case "BannerComponent":
      return <Banner singleBlock={singleBlock} key={key} index={key} />;
    case "TextComponent":
      return <Text key={key} singleBlock={singleBlock} />;
    case "ProductComponent":
      return <SingleProductBlock key={key} singleBlock={singleBlock} />;
    default: {
      console.warn(`Missing homescreen block!`, singleBlock.type?.resolvedName);
      return null;
    }
  }
};

export default serializer;
