import { faCartCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateOrderTotal, db, getOrderConfigs } from "@kanpla/system";
import { Module, Order, OrderPersonal, Refund } from "@kanpla/types";
import { DrawerOrModal, SafetyPaymentBackground } from "@kanpla/ui";
import classnames from "classnames";
import { capitalize, isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import Item from "../../mealplan2/Item";
import TotalOrder from "./TotalOrder";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  order: Order | OrderPersonal;
  module: Module;
  salesplaceName?: string;
};

interface DetailProps {
  items: Array<{
    title: string;
    data: string;
    hide?: boolean;
  }>;
}

const DetailWrapper = ({ items }: DetailProps) => (
  <div className="rounded-md border mb-3">
    {items
      .filter(({ hide }) => !hide)
      .map(({ title, data }, key) => (
        <div
          key={key}
          className={classnames({
            "py-2 px-3": true,
            "border-t": key > 0,
          })}
        >
          {title && <span className="opacity-60">{title}: </span>}
          {data}
        </div>
      ))}
  </div>
);

const OrderDetail = ({
  open,
  setOpen,
  order,
  module,
  salesplaceName,
}: Props) => {
  const { t } = useTranslation(["translation", "modals", "mealplan2"]);

  const { customBranding } = useContainer(AppContext);
  const configs = getOrderConfigs(order.order);

  const orderTotal = calculateOrderTotal(order.order || {});
  const includingTax =
    module?.paymentMethod === "credit" || order.paymentMethod === "credit";
  const taxTotal = includingTax ? orderTotal * 0.2 : orderTotal * 0.25;

  const isRefunded =
    (orderTotal === 0 && !configs.length) || order.refundIds?.length;

  const [refund] = useDocumentDataOnce<Refund>(
    order.refundIds?.length
      ? db.collection("refunds").doc(order?.refundIds?.[0])
      : null
  );

  const refundConfigs = getOrderConfigs(refund?.order);
  const refundTotal = calculateOrderTotal(refund?.order || {});
  const refundTaxTotal = includingTax ? refundTotal * 0.2 : refundTotal * 0.25;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:order-until", {
        value: module?.displayName || module?.name || "—",
      })}
      showCloseButton={true}
    >
      <SafetyPaymentBackground customBranding={customBranding} />

      <div className="relative z-10 text-text-primary">
        <DetailWrapper
          items={[
            {
              title: t("mealplan2:pick-up-date"),
              data: moment.unix(order.dateSeconds).format("DD. MMM YYYY "),
            },
            {
              title: t("translation:form.labels.point-of-sale"),
              data: salesplaceName,
              hide: !salesplaceName,
            },
            {
              title: t("translation:user-name"),
              data: (order as OrderPersonal)?.displayName,
              hide: !(order as OrderPersonal)?.displayName,
            },
            {
              title: capitalize(t("mealplan2:refunded")),
              data: moment
                .unix(refund?.createdAtSeconds)
                .format("DD. MMM YYYY HH:mm"),
              hide: isEmpty(refund),
            },
          ]}
        />

        {isRefunded && (
          <div className="rounded-md bg-background-secondary p-3 py-5 flex flex-col items-center">
            <FontAwesomeIcon
              icon={faCartCircleExclamation}
              className="pb-3 text-3xl"
            />
            {t("mealplan2:this-order-is-refunded")}
          </div>
        )}

        {[...configs, ...refundConfigs].map((item) => {
          const { productId, config, price, uid, name } = item;

          return (
            <Item
              key={`${productId}-${uid}-${name}`}
              name={name}
              price={price}
              config={config}
              productId={productId}
              module={module}
              hidePicture
              dataCy="history-order-confirm"
            />
          );
        })}
        <div className="border-t border-double border-divider-main text-right py-3 mt-3 relative z-index">
          <TotalOrder
            paymentMethod={module?.paymentMethod}
            orderTotal={isRefunded ? orderTotal + refundTotal : orderTotal}
            taxTotal={isRefunded ? taxTotal + refundTaxTotal : taxTotal}
            module={module}
          />
        </div>
      </div>
    </DrawerOrModal>
  );
};

export default OrderDetail;
