import { OrderInfo } from "@kanpla/types";
import { isNumber } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Props = {
  orderInfo: OrderInfo;
};

type queryData = {
  /** DDMMYY */
  date?: string | Array<string>;
  reference?: string | Array<string>;
  meetingName?: string | Array<string>;
  /** HHMM */
  deliveryTime?: string | Array<string>;
  [textInputName: string]: string | Array<string>;
};

const useOrderInfo = ({ orderInfo }: Props) => {
  const [newOrderInfo, setNewOrderInfo] = useState<OrderInfo>({});
  const router = useRouter();

  const queryData = router.query as queryData;

  const queryTimeSeconds = () => {
    if (!queryData.deliveryTime) return undefined;
    const time = queryData.deliveryTime;
    const hours = parseInt(`${time[0]}${time[1]}`);
    const minutes = parseInt(`${time[2]}${time[3]}`);
    if (!isNumber(hours) || !isNumber(minutes)) return undefined;
    return hours * 3600 + minutes * 60;
  };

  const queryTextInputs = () => {
    const textInputs = Object.entries(queryData || {}).filter(([key, value]) =>
      key.startsWith("text-")
    );
    if (textInputs.length === 0) return undefined;

    return textInputs.reduce((acc, [key, value]) => {
      acc[key.replace("text-", "")] = value;
      return acc;
    }, {});
  };

  const orderInfoTrigger = JSON.stringify(orderInfo);
  useEffect(() => {
    setNewOrderInfo({
      name: (queryData?.meetingName as string) || "",
      reference: (queryData?.reference as string) || null,
      timeInput: queryTimeSeconds() || null,
      textInput: queryTextInputs() || null,
      ...orderInfo,
    });
  }, [orderInfoTrigger]);

  return { newOrderInfo, setNewOrderInfo };
};

export default useOrderInfo;
