import { db, fetchCollection } from "@kanpla/system";
import { ProductBank, School } from "@kanpla/types";

interface Props {
  partnerId: string;
  /** School or Product Bank ID */
  code: string;
}

export const getSchoolsOrProductBanksByCode = async ({
  partnerId,
  code,
}: Props) => {
  const setRef = (collection: string) => {
    return partnerId
      ? db
          .collection(collection)
          .where("canteenId", "==", parseInt(code))
          .where("partnerId", "==", partnerId)
      : db.collection(collection).where("canteenId", "==", parseInt(code));
  };

  const refSchools = setRef("schools");
  const refProductBanks = setRef("productBanks");

  const allSchools = await fetchCollection<School>(refSchools, true);
  const allProductBanks = await fetchCollection<ProductBank>(
    refProductBanks,
    true
  );

  return {
    allSchools,
    allProductBanks,
  };
};
