import { DataAuthenticationModal } from "@kanpla/types";
import { DrawerOrModal } from "@kanpla/ui";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import Authentication from "../../anonymous/Authentication";
import { AppContext } from "../../contextProvider";

const AnonymousModal = () => {
  const {
    openAuthenticationModal,
    setOpenAuthenticationModal,
    dataAuthenticationModal,
    auth,
  } = useContainer(AppContext);

  /**
   * If the user just did the log in and the modal for the authentication
   * is open, we will close the modal itself
   */
  useEffect(() => {
    if (auth.user && openAuthenticationModal) setOpenAuthenticationModal(false);
  }, [auth.user, openAuthenticationModal]);

  const getDataInLoginModal = (data: DataAuthenticationModal, prop: string) => {
    return data.action === "login" && data[prop];
  };

  return (
    <DrawerOrModal
      open={openAuthenticationModal}
      setOpen={setOpenAuthenticationModal}
    >
      <Authentication
        action={dataAuthenticationModal.action}
        isFromAnonymousFlow={true}
        isFromAPrivateModule={false}
      />
    </DrawerOrModal>
  );
};

export default AnonymousModal;
