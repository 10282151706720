import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checks } from "@kanpla/system";
import { Module, ProductType } from "@kanpla/types";
import { BottomNavigation, getTabIcons } from "@kanpla/ui";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import { isArray, isEmpty } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { useContainer } from "unstated-next";
import useBasketPreventClick from "../BasketPreventClick";
import { AppContext } from "../contextProvider";

const moduleDefaultIcons: { [type in ProductType]: [IconPrefix, IconName] } = {
  mealplan: ["fad", "plate-utensils"],
  homescreen: ["fad", "home"],
  flex: ["fad", "pot-food"],
  subscription: ["fad", "bullseye-pointer"],
  // shop: ["fad", "shop"],
};

const AnonymousTabs = () => {
  const { modules, module, mobile } = useContainer(AppContext);

  if (isEmpty(module)) return null;

  /** Create the tab link */
  const createAnonymousTabLink = (module: Module) => {
    return (
      <AnonymousTabLink
        key={`${module.id}-public`}
        moduleProp={module}
        label={module?.displayName || module?.name}
      />
    );
  };

  const tabs = modules.reduce((previousValue, module) => {
    const { hide, isFlexAndMenuPreview, isHomescreen, isPaymentMethodCredit } =
      checks({ module });
    if (hide) return previousValue;
    /**
     * Show the following modules:
     * - If a module is public
     * - If a module has a payment method to credit
     * - If a module is type flex, flow set to menuPreview and hidden to false
     * - If a module is type homescreen and hidden to false
     */
    if (module?.public) {
      previousValue.push(createAnonymousTabLink(module));
      return previousValue;
    }
    if (module?.public === undefined) {
      if (isPaymentMethodCredit || isFlexAndMenuPreview || isHomescreen) {
        previousValue.push(createAnonymousTabLink(module));
        return previousValue;
      }
    }
    return previousValue;
  }, []);

  if (mobile)
    return <BottomNavigation>{tabs.map((tab) => tab)}</BottomNavigation>;

  return (
    <div className="overflow-x-auto mx-4 rounded-lg lg:mx-0">
      <div className="hidden md:flex pb-1 pt-1 text-center text-text-primary flex-none relative z-10 lg:justify-end">
        {tabs.map((tab) => tab)}
      </div>
    </div>
  );
};

const AnonymousTabLink = ({ moduleProp, label }) => {
  const { module, mobile, schoolId, isBulk } = useContainer(AppContext);
  const router = useRouter();

  /** If the current tab is selected and is not and admin tab, the active property will be true */
  const active = moduleProp.id === module.id && !isBulk;

  const confirm = useBasketPreventClick({ disabled: active });

  const handleClick = async () => {
    await confirm();
    const url = constructNewUrl(schoolId, moduleProp.id);
    router.push(url);
  };

  const iconKey =
    moduleProp?.displayIcon || moduleDefaultIcons[moduleProp.type];

  return (
    <button
      onClick={handleClick}
      id={`navbar_${moduleProp.id}`}
      className={`focus:no-underline items-center justify-center flex ${
        mobile
          ? "flex-col flex-1 w-0 px-0"
          : "rounded-lg mr-1 group relative overflow-hidden px-4"
      } transition-colors py-2 whitespace-nowrap font-medium ${
        active ? "text-primary-dark" : "text-text-primary"
      }`}
    >
      {!mobile && (
        <div
          className={`z-0 ${
            active ? "text-primary-main" : "text-background-secondary"
          }`}
        >
          <div
            className={`${
              active ? "group-hover:opacity-10" : "group-hover:opacity-100"
            } opacity-0 transition-opacity bg-current absolute inset-0`}
          />
        </div>
      )}
      <div
        className={`relative flex items-center ${
          mobile ? "flex-col flex-1 w-full" : "mr-1"
        }`}
      >
        {isArray(iconKey) ? (
          <FontAwesomeIcon icon={iconKey as any} />
        ) : (
          getTabIcons(mobile ? "regular" : "small", "currentColor").find(
            (icon) => icon.key === iconKey
          )?.icon
        )}
        <span
          className={`${
            mobile ? "mt-1 w-full overflow-hidden" : "ml-2"
          } text-sm`}
          style={{ fontSize: mobile && 11 }}
        >
          {label}
        </span>
      </div>
    </button>
  );
};

export default AnonymousTabs;
