import { faLeaf } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  capitalizeFirstLetter,
  detectProductPrice,
  isDatePastDeadline,
} from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { AutoScrollContainer, Category } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import { Deadline } from "./DeadlineInfo";
import Product from "./Product";

interface Props {
  name: string;
  items: Array<CombinedOfferItem>;
  index: number;
  showChildrenAnyway?: boolean;
  isHidden?: boolean;
  overrideVisible?: boolean;
  noMarginTop?: boolean;
}

const ProductCategory = ({
  name,
  index,
  items = [],
  showChildrenAnyway = false,
  isHidden = false,
  overrideVisible = false,
  noMarginTop,
}: Props) => {
  const { t } = useTranslation(["translation"]);
  const { dayIndex, week, schoolId, module, navbarHasContent } =
    useContainer(AppContext);
  const {
    hasRequiredProduct,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
    setSelectedCategoryIndex,
    highlightedCategoryIndex,
  } = useContainer(MealplanContext);

  const allPastDeadline = isDatePastDeadline({
    date: week[dayIndex],
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  const filteredItems = items.filter((item) => {
    // Hide if it's a required product
    const hiddenRequredProduct =
      hasRequiredProduct &&
      module?.plugins?.requiredProduct?.productId === item.productId;
    if (hiddenRequredProduct) return null;

    return !hiddenRequredProduct;
  });

  if (!filteredItems.length) return null;

  // Seeing if there is a common deadline
  const commonDeadline: number | boolean = (() => {
    const productDeadlines = filteredItems.map((item) =>
      typeof item.individualDeadline === `number`
        ? item.individualDeadline
        : deadline
    );

    const uniqueProductDeadlines: any = [...new Set(productDeadlines)];
    return uniqueProductDeadlines.length === 1
      ? uniqueProductDeadlines[0]
      : false;
  })();

  const isSameAsMainDeadline = commonDeadline === deadline;

  const header = commonDeadline && !isSameAsMainDeadline && (
    <Deadline
      date={week[dayIndex]}
      individualDeadline={commonDeadline}
      className="ml-3 mr-auto inline-block"
    />
  );

  const displayName =
    capitalizeFirstLetter(name) ||
    module?.text?.["noCategory/text"] ||
    t("translation:dish-of-the-day");

  return (
    <AutoScrollContainer
      className="boundary-element -mx-1"
      scrollIntoView={index === highlightedCategoryIndex}
      onIntercept={() => setSelectedCategoryIndex(index)}
      topOffsetPx={180}
    >
      <Category
        name={
          <>
            {displayName || ""}
            {displayName === "Leftovers" ? (
              <FontAwesomeIcon
                icon={faLeaf}
                color="#298f54"
                className="ml-2"
                size="sm"
              />
            ) : (
              ""
            )}
            {header || ""}
          </>
        }
        top={!navbarHasContent && "0"}
        noMarginTop={noMarginTop}
      >
        {filteredItems.map((item) => {
          const isPastDeadline = isDatePastDeadline({
            date: week[dayIndex],
            deadline: item?.individualDeadline,
            deadlineExcludingWeekends,
            deadlineWeekRelative,
          });
          return (
            <Product
              product={{
                ...item,
                price: detectProductPrice(item, module, schoolId),
              }}
              key={`${item.productId}-${week[dayIndex]?.seconds}`}
              commonDeadline={commonDeadline}
              pastDeadline={
                (isHidden && !showChildrenAnyway) ||
                (allPastDeadline && !item.individualDeadline) ||
                isPastDeadline
              }
              overrideVisible={overrideVisible}
              showChildrenAnyway
            />
          );
        })}
      </Category>
    </AutoScrollContainer>
  );
};

export default ProductCategory;
