import { DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import FooterKanpla from "../FooterKanpla";

const SupportButtons = () => {
  const { t } = useTranslation(["translation", "components"]);

  const [contactOpen, setContactOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);

  const { school } = useContainer(AppContext);
  const { contact } = school || {};

  return (
    <div className="flex flex-col md:flex-row mt-8">
      <Button
        type="primary"
        className="mx-1 mb-2"
        onClick={() => setContactOpen(true)}
      >
        {t("translation:contact-the-kitchen")}
      </Button>
      <Button className="mx-1 mb-2" onClick={() => setSupportOpen(true)}>
        {t("components:technical-issues")}
      </Button>
      {/* Contact Kitchen */}
      <DrawerOrModal
        open={contactOpen}
        setOpen={setContactOpen}
        title={t("translation:contact-the-kitchen")}
        subtitle={t("components:modal.subtitles.can-contact-the-kitchen")}
        showCloseButton={true}
        // containerClassName="flex"
      >
        <div className="text-text-primary text-center">
          {contact?.email && (
            <div className="mt-4">
              <p className="h200">E-mail</p>
              <a className="font-medium" href={`mailto:${contact.email}`}>
                {contact.email}
              </a>
            </div>
          )}
          {contact?.tel && (
            <div className="mt-4">
              <p className="h200">{t("translation:telephone")}</p>
              <a className="font-medium" href={`tel:${contact.tel}`}>
                {contact.tel}
              </a>
            </div>
          )}
        </div>
      </DrawerOrModal>

      {/* Kanpla: Technical Questions */}
      <DrawerOrModal
        open={supportOpen}
        setOpen={setSupportOpen}
        noPadding={true}
        className="overflow-hidden"
        containerClassName="flex"
      >
        <FooterKanpla />
      </DrawerOrModal>
    </div>
  );
};

export default SupportButtons;
