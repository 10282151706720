/* eslint-disable react-hooks/rules-of-hooks */
import { useWindowSize } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import React from "react";
import { useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import { AppContext } from "../contextProvider";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import Day from "./Day";
import DayDetail from "./DayDetail";
import { MenuPreviewContainer, MenuPreviewContext } from "./MenuPreviewContext";

const MenuPreview = () => {
  const { innerAppLoading } = useContainer(AppContext);

  return (
    <MenuPreviewContainer>
      <NavbarSecondary timeNavigation="weekly" />
      <ModuleLoadingWrapper loading={innerAppLoading}>
        <View />
      </ModuleLoadingWrapper>
    </MenuPreviewContainer>
  );
};

const View = () => {
  const { week } = useContainer(AppContext);
  const { activeHoliday, defaultDate } = useContainer(MenuPreviewContext);
  const { isMobileSize } = useWindowSize();

  if (activeHoliday)
    return (
      <CanteenClosed
        defaultDate={defaultDate}
        holidayDesc={activeHoliday.design}
      />
    );

  return (
    <div className="bg-background-primary pt-6 lg:pt-12 pb-2 max-w-4xl xl:max-w-5xl 2xl:max-w-6xl mx-auto">
      <div className="relative">
        <div className="flex flex-row">
          <div
            className="md:py-4 sticky h-fit"
            style={{ top: "85px", minWidth: "300px", flex: 2 }}
          >
            {week.map((date, dayIndex: DayIndex) => (
              <Day
                key={`${dayIndex}-${date.toDate()}`}
                date={date}
                dayIndex={dayIndex}
              />
            ))}
          </div>
          {!isMobileSize && <DayDetail />}
        </div>
      </div>
    </div>
  );
};

export default MenuPreview;
