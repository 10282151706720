import { faCoin } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import PaymentModal from "./payment";

const NegativeBalancePopup = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["payment", "modals"]);

  const { balance } = useContainer(AppContext);

  useEffect(() => {
    if (!balance || balance >= 0) return message.destroy("negativeBalance");
    message.error({
      key: "negativeBalance",
      duration: 0,
      content: (
        <div className="flex flex-col">
          <p>{t("payment:message.negative-balance")}</p>
          <Button
            onClick={() => setOpen(true)}
            size="small"
            className="text-small mt-2 ml-auto"
            icon={<FontAwesomeIcon icon={faCoin} className="mr-2" />}
          >
            {t("modals:refuel-here")}
          </Button>
        </div>
      ),
    });
  }, [balance]);

  return <PaymentModal open={open} setOpen={setOpen} />;
};

export default NegativeBalancePopup;
