import { Homescreen } from "@kanpla/types";
import { Image as KanplaImage } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import React from "react";

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Image = ({ singleBlock }: Props) => {
  const { t } = useTranslation(["homescreen"]);

  if (!singleBlock || !singleBlock.props) return null;

  const { imageUrl, altText } = singleBlock.props;

  if (!imageUrl) return null;

  return (
    <figure className="rounded-lg overflow-hidden">
      <KanplaImage
        src={imageUrl}
        alt={altText || t("homescreen:image-without-description")}
        className="w-full"
      />
    </figure>
  );
};

export default Image;
