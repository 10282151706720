import { escapeHTMLString } from "@kanpla/system";
import React from "react";

interface Props {
  title?: string;
}

const Title = ({ title }: Props) => {
  if (!title) return <span></span>;

  return <h2 className="h600">{escapeHTMLString(title)}</h2>;
};

export default Title;
