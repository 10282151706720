import { NextRouter } from "next/router";

interface Props {
  /** If the query is concatenated you could pass the string to replace */
  stringToReplace?: string;
  router?: NextRouter;
}

/** Return an array of slugs, schools and modules */
type Return = string[];

/**
 * This method create an array of slugs from a query.
 * If the query is concatenated the lasts values will be taken.
 */
export const constructSlugsFromQuery = ({
  router,
  stringToReplace,
}: Props): Return => {
  /** Get the backLink query */
  const backLinkQuery =
    typeof router.query?.backLink === "string"
      ? router.query?.backLink
      : router.query?.backLink?.[router.query?.backLink?.length - 1];

  /** Take the singles slugs of the query (comprehend also nested queries) */
  const querySlugs = (backLinkQuery || "")
    .replace(/\/m\/|\/s\//g, " ")
    .trim()
    .split(" ");

  /** Remove unnecessary strings */
  const mappedQuerySlugs = querySlugs.map((querySlug) => {
    const newQuerySlug = querySlug.replace(stringToReplace, "");
    return newQuerySlug;
  });

  /** Get the last module */
  const module = mappedQuerySlugs[mappedQuerySlugs.length - 1];
  /** Get the last school */
  const school = mappedQuerySlugs[mappedQuerySlugs.length - 2];

  /** Construct the slugs */
  const newQuerySlugs = ["s", school, "m", module];

  return newQuerySlugs;
};
