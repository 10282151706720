import { db, fetchCollection, fetchDocument } from "@kanpla/system";
import { Homescreen, Product, ProductBank } from "@kanpla/types";
import { CustomSlider } from "@kanpla/ui";
import { cloneDeep, shuffle } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { HomescreenContext } from "../..";
import SectionHeader from "../../elements/SectionHeader";
import Button from "../Button";
import SingleProduct from "./SingleProduct";

interface WrapperProps {
  children: React.ReactChild | Array<React.ReactChild>;
  title?: string;
  buttonProps?: Homescreen.ContentInner;
}

const ProductsSectionWrapper = ({
  children,
  title,
  buttonProps,
}: WrapperProps) => {
  return (
    <section>
      <SectionHeader buttonProps={buttonProps} title={title} />
      <main className="rounded-lg -mx-2 md:-mx-4 lg:-mx-8 p-2 px-2 md:px-4 lg:px-8 relative overflow-hidden">
        {children}
      </main>
    </section>
  );
};

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Products = ({ singleBlock }: Props) => {
  const { module, content } = useContext(HomescreenContext);

  const [allProducts, setAllProducts] = useState<Array<Product>>([]);

  useEffect(() => {
    if (!singleBlock || !singleBlock.props) return;
    (async () => {
      const prods = await getProducts();
      setAllProducts(prods);
    })();
  }, [module?.id]);

  if (!singleBlock || !singleBlock.props) return null;

  const {
    category,
    direction,
    productIds,
    rows,
    showAmount,
    showType,
    title,
    moduleId,
    onClick = "nothing",
    targetDay,
    hideAfter,
  } = singleBlock.props;
  const buttonArea = content[singleBlock?.linkedNodes?.buttonArea];

  const clickInfo = {
    moduleId,
    onClick,
    targetDay,
  };

  const getProducts = async () => {
    switch (showType) {
      case "first": {
        const products = await fetchCollection<Product>(
          db.collection("products").limit(showAmount)
        );
        return products.filter((p) => !p.deleted);
      }
      case "random": {
        const productBank = await fetchDocument<ProductBank>(
          db.collection("productBanks").doc(module.productBankId)
        );

        const productIds = module?.list || [];

        if (productIds.length > 0) {
          const correctProductIdArray = shuffle(cloneDeep(productIds)).slice(
            0,
            showAmount
          );
          const productPromise = correctProductIdArray.map(
            async (productId) =>
              await fetchDocument<Product>(
                db.collection("products").doc(productId)
              )
          );
          const products = await Promise.all(productPromise);
          return products.filter((p) => !p.deleted);
        }

        const allProducts = await fetchCollection<Product>(
          db.collection("products").where("productBankId", "==", productBank.id)
        );

        const products = shuffle(allProducts).slice(0, showAmount);

        return products.filter((p) => !p.deleted);
      }
      case "choose": {
        const productPromise = productIds.map(
          async (productId: string) =>
            await fetchDocument<Product>(
              db.collection("products").doc(productId)
            )
        );
        const products = await Promise.all(productPromise);
        return products.filter((p) => !p.deleted);
      }
      case "category": {
        const products = await fetchCollection<Product>(
          db.collection("products").where("category", "==", category)
        );

        return products.filter((p) => !p.deleted).slice(0, showAmount);
      }
      default: {
        const products = await fetchCollection<Product>(
          db.collection("products").limit(showAmount)
        );
        return products.filter((p) => !p.deleted);
      }
    }
  };

  if (moment().isSameOrAfter(moment.unix(hideAfter), "day")) return null;

  if (direction === "scrollable") {
    const buttonProps = content[buttonArea?.nodes?.[0]];
    return (
      <ProductsSectionWrapper>
        <CustomSlider
          title={title}
          linkButton={buttonProps && <Button singleBlock={buttonProps} />}
          items={allProducts.filter((p) => p)}
          rows={rows}
          renderer={(data) => <SingleProduct module={module} product={data} />}
        />
      </ProductsSectionWrapper>
    );
  }

  return (
    <ProductsSectionWrapper
      title={title}
      buttonProps={content[buttonArea?.nodes?.[0]]}
    >
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {allProducts
          .filter((p) => p)
          .map((p) => (
            <SingleProduct
              clickInfo={clickInfo}
              key={p.id}
              product={p}
              module={module}
            />
          ))}
      </ul>
    </ProductsSectionWrapper>
  );
};

export default Products;
