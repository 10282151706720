import { Divider } from "antd";

const ReceiptDivider = () => {
  return (
    <div className="py-3 relative overflow-hidden">
      <div
        style={{ transform: "translate(-50%, -50%)" }}
        className="rounded-full absolute h-full aspect-square left-0 top-2/4 bg-background-primary border-2"
      ></div>
      <Divider
        dashed={true}
        style={{ borderColor: "#e2e8f0" }}
        className="m-0 border-t-2"
      />
      <div
        style={{ transform: "translate(50%, -50%)" }}
        className="rounded-full absolute h-full aspect-square right-0 top-2/4 bg-background-primary border-2"
      ></div>
    </div>
  );
};

export default ReceiptDivider;
