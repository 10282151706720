import { useTranslation } from "react-i18next";
import AddChild from "./forms/addChild";

const FirstChild = () => {
  const { t } = useTranslation(["components"]);
  return (
    <div className="text-center py-16 text-text-primary">
      <h2 className="text-2xl mb-1 h700">{t("components:let-get-started")}</h2>
      <p className="text-text-primary">{t("components:add-first-user")}</p>
      <div className="py-4 max-w-xs m-auto">
        <AddChild />
      </div>
    </div>
  );
};

export default FirstChild;
