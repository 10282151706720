import { ProductPreview } from "@kanpla/ui";
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  Dot,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useContext, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../..";
import ProductsCarouselContextListener from "../ProductsCarouselContextListener";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-duotone-svg-icons";
import classNames from "classnames";

const Carousel = () => {
  const { items, module } = useContainer(MealplanContext);

  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  const totalSlides = carouselContext?.state?.totalSlides;

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const buttonClassName =
    "absolute top-1/2 transform -translate-y-full md:-translate-y-1/2 z-20 disabled:opacity-50 transition-opacity";

  return (
    <div className="relative">
      <ButtonNext className={classNames([buttonClassName, "right-5"])}>
        <Button icon={<FontAwesomeIcon icon={faArrowRight} />} shape="circle" />
      </ButtonNext>
      <ButtonBack className={classNames([buttonClassName, "left-5"])}>
        <Button icon={<FontAwesomeIcon icon={faArrowLeft} />} shape="circle" />
      </ButtonBack>
      <Slider classNameTrayWrap="pl-8 pr-8">
        <ProductsCarouselContextListener />
        {items.map((item, index) => (
          <Slide
            key={item?.productId}
            index={index}
            className={`transition-all transform duration-500 origin-center ${
              currentSlide === index ? "scale-[1.1] z-10" : "-z-10"
            }`}
          >
            <div
              className={`p-0 rounded-lg mt-6 md:mt-12 transition-all delay-100 overflow-hidden duration-750 ${
                currentSlide === index
                  ? "shadow-xl"
                  : "blur-[2px] opacity-75 pointer-events-none"
              } `}
            >
              <ProductPreview product={item} isBig={true} module={module} />
            </div>
          </Slide>
        ))}
      </Slider>
      {/* Dots */}
      <div className="flex items-center justify-center -mt-6 mb-4 z-30 relative">
        {Array(totalSlides)
          .fill(1)
          .map((_, index) => (
            <Dot
              key={`dot-receipt-${index}`}
              slide={index}
              className="m-1 group"
            >
              <span className="block h-2 w-2 rounded-sm bg-background-secondary m-1 group-disabled:bg-primary-main transition-colors border group-disabled:border-primary-dark" />
            </Dot>
          ))}
      </div>
    </div>
  );
};

export default Carousel;
