/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { capitalize } from "lodash";
import { useContainer } from "unstated-next";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { AppContext } from "../contextProvider";
import Product from "./Product";
import { useTranslation } from "react-i18next";

interface Props {
  categoryName: string;
  items: CombinedOfferItem[];
  overrideDayIndex?: DayIndex;
}

const DayDetailCategory = ({
  items,
  categoryName,
  overrideDayIndex,
}: Props) => {
  const { t } = useTranslation(["translation"]);
  const { dayIndex } = useContainer(AppContext);
  const cleanName: string =
    categoryName && categoryName !== "undefined"
      ? capitalize(categoryName)
      : t("translation:dish-of-the-day");

  return (
    <div className="pt-8">
      <div className="h500 pb-2 md:pb-0 md:px-6 font-bold text-text-primary">
        {cleanName}
      </div>
      {items.map((product) => {
        return (
          <Product
            product={product}
            key={product?.id}
            dayIndex={overrideDayIndex ?? dayIndex}
            detailed
          />
        );
      })}
    </div>
  );
};

export default DayDetailCategory;
