import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "antd";
import React from "react";

interface Props {
  /** Label to display */
  label?: string;
  /** Button classes */
  buttonClassName?: string;
  /** Label classes */
  labelClassName?: string;
  /** Icon classes */
  iconClassName?: string;
  /** Back method */
  onClick: () => void;
  /** Button props, see this link: https://ant.design/components/button/#:~:text=after%20antd%204.0.-,API,-%23 */
  buttonProps: {
    type: "link" | "text" | "ghost" | "default" | "primary" | "dashed";
  };
}

const BackButton = ({
  label = "Forside",
  buttonClassName = "",
  labelClassName = "",
  iconClassName = "",
  onClick,
  buttonProps,
}: Props) => {
  return (
    <Button onClick={onClick} className={buttonClassName} {...buttonProps}>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className={`${iconClassName} mr-2`}
      />
      <span className={labelClassName}>{label}</span>
    </Button>
  );
};

export default BackButton;
