import { useEffect, useState, useCallback } from "react";
import { callFunction } from "@kanpla/system";
import { EventTracking, Popup, PopupConstructor } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";
import { fetchMultipleDocuments } from "@kanpla/system";

export interface NormalizedPopupData {
  id: string;
  popup: Popup;
  title: string;
  type: string;
  image?: string;
  createdAt: number;
  votedFor?: string;
  closedAt?: number;
}

const useInteractionPopup = () => {
  const { childId, schoolId } = useContainer(AppContext);
  const [eventData, setEventData] = useState<NormalizedPopupData[]>([]);

  const fetchPopup = useCallback(async () => {
    try {
      // Clear out some errors
      if (!schoolId || !childId) return;

      // Fetch all the active popup events of the child
      const events: EventTracking[] =
        (await callFunction("popups-fetchPopupEvent", {
          schoolId,
          childId,
        })) || [];

      const popups = await fetchMultipleDocuments<Popup>("popups", [
        ...(events.map((e) => e.componentId) || []),
      ]);

      const normalizeData = events.reduce((acc, event) => {
        const targetPopup = popups.find((p) => p.id === event.componentId);
        if (!targetPopup) return acc;

        const [title, text, type, options] = (targetPopup?.blocks ||
          []) as PopupConstructor.Interaction["blocks"];

        const choice =
          event?.votedFor &&
          (options?.values || []).find((o) => o.key === event.votedFor);

        return [
          ...acc,
          {
            id: event.id,
            votedFor: choice?.text || null,
            closedAt: event?.closedAt || null,
            createdAt: event.created_at,
            type: type.selected,
            title: title.text,
            image: choice?.image || null,
            popup: targetPopup,
          },
        ];
      }, [] as NormalizedPopupData[]);

      setEventData(normalizeData);
    } catch (err) {
      if (err?.message === "There are no popups") return;
      if (err?.message === "Mangler brugerens ID!") return;
      console.error(err);
    }
  }, [childId, schoolId]);

  useEffect(() => {
    fetchPopup();
  }, [fetchPopup]);

  return { eventData, refreshEventsData: fetchPopup };
};

export default useInteractionPopup;
