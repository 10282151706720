import { faShoppingBag } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
  priceFormatter,
} from "@kanpla/system";
import { Module, OrderMealplan } from "@kanpla/types";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { groupBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import OrderOptions from "./OrderOptions";

interface Props {
  orders: OrderMealplan[];
  module?: Module;
}

const Table = ({ orders, module }: Props) => {
  const groupedOrders = groupBy(orders, (o) =>
    moment.unix(o.dateSeconds).format("MMMM, YYYY")
  );

  return (
    <div className="w-full relative">
      {Object.entries(groupedOrders || {}).map(([monthName, currentOrders]) => {
        return (
          <>
            <h3 className="h300 px-14 pt-8 text-text-disabled pb-2 sticky top-0 bg-background-primary z-30 border-b">
              {monthName}
            </h3>
            {currentOrders.map((order, index) => (
              <TableRow
                order={order}
                key={order.id}
                index={index}
                module={module}
              />
            ))}
          </>
        );
      })}
    </div>
  );
};

export const TableRow = ({ order, index, module }) => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { hidePrices } = module?.config || {};

  const amount = calculateAmountOfOrderItems(order.order);
  const totalPrice = calculateOrderTotal(order.order);
  const time = order?.info?.timeInput;
  const dateMoment = moment.unix(order.dateSeconds);
  const formatDay = dateMoment.format("dddd D. M. Y");
  const orderName =
    order?.info?.name ||
    t("mealplan2:order-to-d", { value: dateMoment.format("D/M Y") });

  return (
    <div className="w-full py-6 px-8 md:px-14 bg-background-primary rounded-lg my-1 border border-divider-main">
      <div className="w-full flex-wrap max-w-6xl mx-auto flex justify-start items-baseline lg:text-lg text-text-primary">
        <span className="w-full lg:w-3/12">{formatDay}</span>
        <p className="w-full lg:w-5/12 pb-4 lg:pb-0 pr-3">
          <span className="font-semibold">{orderName}</span>
          {time && (
            <span>, {moment.utc(time * 1000 || 0).format("HH:mm")}</span>
          )}
        </p>
        <div className="flex items-center w-1/4 lg:w-1/12">
          <b>{amount}</b>
          <FontAwesomeIcon icon={faShoppingBag} className="ml-2 opacity-70" />
        </div>
        <span className="w-1/4 lg:w-1/12">
          {priceFormatter(
            totalPrice,
            { language: i18n.language },
            false,
            hidePrices,
            { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
          )}
        </span>
        <div className="w-2/4 lg:w-2/12 flex justify-end">
          <OrderOptions order={order} />
        </div>
      </div>
    </div>
  );
};

export default Table;
