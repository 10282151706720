import { calculateOrderTotal } from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import { ReceiptWrapper } from "@kanpla/ui";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { MealplanContext } from "./..";
import OrderItem from "./OrderItem";

const Receipt = () => {
  const { t } = useTranslation(["mealplan2", "plugins", "translation"]);

  const { isSaving, pulseCounter, customBranding, mobile } =
    useContainer(AppContext);
  const {
    hasOrdered,
    isPastDeadline,
    hasKanplaGo,
    ignoredOrderIds,
    orders,
    setAllowedOrderIds,
  } = useContainer(MealplanContext);

  const [editingOrderId, setEditingOrderId] = useState<string>(null);

  const ordersTrigger = orders.map((order) => order?.id).join("-");

  useEffect(() => {
    setAllowedOrderIds(ordersTrigger.split("-"));
    setEditingOrderId(null);
  }, [ordersTrigger]);

  const editingDisabled = isPastDeadline || hasKanplaGo;

  if (!hasOrdered && ignoredOrderIds.length === 0) return null;

  const customColor =
    customBranding?.custom?.palette?.receipt?.background || "#ffffff";

  const setReceiptConfig = (
    order: OrderMealplan,
    ordersAmount: number,
    index: number,
    createdAtSeconds: number
  ) => {
    const date = moment.unix(createdAtSeconds);

    return {
      title:
        ordersAmount > 1
          ? order?.info?.name ||
            t("plugins:order-number-kl", {
              index: `#${index}`,
              time: date.format(
                date.isSame(moment(), "day") ? "HH:mm" : "L HH:mm"
              ),
            })
          : t("mealplan2:my-order"),
      subtitle: t("mealplan2:order-confirmed"),
    };
  };

  return (
    <div className="flex flex-col pb-6 md:p-0 md:float-right">
      {orders?.map((order, index) => (
        <ReceiptWrapper
          pulseCounter={pulseCounter}
          isSaving={isSaving > 0}
          fullWidth
          svgColor={customColor}
          mobile={mobile}
          ordersAmount={orders?.length || 0}
          orderDocument={order}
          orderNumber={index + 1}
          receiptConfig={setReceiptConfig(
            order,
            orders?.length || 0,
            index + 1,
            order.createdAtSeconds
          )}
          className="pt-0 md:pt-8"
        >
          <OrderItem
            editingDisabled={
              editingDisabled ||
              (editingOrderId && editingOrderId !== order?.id)
            }
            index={index}
            ordersAmount={orders?.length || 0}
            key={order?.id}
            orderDocument={order}
            disabled={editingOrderId && editingOrderId !== order?.id}
            setEditingOrderId={setEditingOrderId}
            totalOrder={calculateOrderTotal(order.order)}
          />
        </ReceiptWrapper>
      ))}
    </div>
  );
};

export default Receipt;
