import {
  callFunction,
  checkLanguage,
  checks,
  deconstructSlugs,
  getLanguageLevel,
  hasAccessToModule,
} from "@kanpla/system";
import { Child, Module, PopupConstructor, School } from "@kanpla/types";
import Authentication from "apps/frontend/components/anonymous/Authentication";
import { AppContext } from "apps/frontend/components/contextProvider";
import FlexComponent from "apps/frontend/components/flex";
import FlexBulk from "apps/frontend/components/flexBulk";
import Homescreen from "apps/frontend/components/homescreen";
import SignupCarouselWrapper from "apps/frontend/components/introduction/SignupCarouselWrapper";
import Wrapper from "apps/frontend/components/Wrapper";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import { isEmpty, omit } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import Mealplan from "../../components/mealplan2";
import MenuPreview from "../../components/menuPreview";
import PopupProvider from "../../components/popups/Provider";
import Subscription from "../../components/subscription";

const checkAccess = (
  child: Child,
  module: Module,
  school: School,
  isBulk?: boolean
) => {
  if (!child || !school || !module) return false;
  const hasAcToMod = hasAccessToModule({ child, school, module });

  if (module?.type === "flex" && isBulk && hasAcToMod.bulk) return true;

  if (module?.type === "flex" && !isBulk && hasAcToMod.individual) return true;

  if (hasAcToMod?.other) return true;

  return false;
};

const OrderingPage = () => {
  const { i18n } = useTranslation();
  const {
    child,
    userId,
    user,
    school,
    schoolId,
    moduleId,
    setModuleId,
    module,
    modules,
    timeNavigation,
    isBulk,
    setIsBulk,
    appLoading,
    innerAppLoading,
    customBranding,
    localeFrom,
    setLocaleFrom,
    auth,
    dataAuthenticationModal,
    setFromAPrivateModule,
  } = useContainer(AppContext);

  const router = useRouter();

  const slugs = router.query?.slugs as unknown as Array<string>;

  const additionalQueries = omit(router.query, "slugs");

  const slugsTrigger = (slugs || []).join();

  const queriesTrigger = Object.values(additionalQueries).length;

  const modulesTrigger = modules.length;

  const [allowAccess, setAllowAccess] = useState(false);

  const hasAnsweredToNotificationPermissions =
    typeof window !== "undefined" &&
    window?.["hasAnsweredToNotificationPermissions"] !== undefined
      ? JSON.parse(window?.["hasAnsweredToNotificationPermissions"])
      : undefined;

  /** Signup carousel logic */
  const [signupScreens, setSignupScreens] = useState<
    PopupConstructor.Fullscreen[]
  >([]);

  const loadSignupScreens = async (schoolId: string) => {
    try {
      const screens = (await callFunction("popups-fetchPopupScreens", {
        schoolId,
      })) as PopupConstructor.Fullscreen[];

      setSignupScreens(screens);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!schoolId) return;
    loadSignupScreens(schoolId);
  }, [schoolId]);

  const showCarousel = useMemo(
    () =>
      isDesktop
        ? !user?.hasSeenSignupCarousel?.desktop &&
          !user?.hasSeenSignupCarousel?.mobile &&
          !isEmpty(signupScreens)
        : isMobile
        ? !user?.hasSeenSignupCarousel?.mobile ||
          (hasAnsweredToNotificationPermissions !== undefined &&
            !hasAnsweredToNotificationPermissions)
        : false,
    [user, signupScreens, hasAnsweredToNotificationPermissions]
  );

  // Temporary

  useEffect(() => {
    updateTranslations();
  }, [userId, schoolId, JSON.stringify(customBranding)]);

  useEffect(() => {
    const rawSlug = deconstructSlugs({
      slugs,
    });
    const slug = {
      ...rawSlug,
      schoolId: rawSlug.schoolId || schoolId || null,
    };

    /** Redirect to the landing page in the anonymous user flow */
    if (router.isReady && !auth?.loading) {
      if (
        !auth.user &&
        !slug.moduleId &&
        !slug.schoolId &&
        router.asPath.includes("/app")
      ) {
        router.replace("/");
        return;
      }
    }

    if (
      slug.isBulk === isBulk &&
      slug.moduleId === moduleId &&
      slug?.moduleId !== null &&
      slug.schoolId === schoolId &&
      slug?.schoolId !== null
    )
      return;

    if (!slug.moduleId || !slug.schoolId) {
      const childSchoolId = child?.schoolId;
      const childModules = modules.filter(
        (m) => checkAccess(child, m, school, isBulk) && !m.hidden
      );
      const childModuleId = childModules?.[0]?.id;

      if (!childModuleId || !childSchoolId) return;

      const newSlugs = constructNewUrl(
        childSchoolId || schoolId,
        childModuleId,
        {
          isBulk,
        }
      );

      router.replace({ pathname: newSlugs, query: additionalQueries });
      return;
    }

    checkAccess(child, module, school, isBulk);
    setIsBulk(slug.isBulk);

    if (
      (slug.moduleId && moduleId !== slug.moduleId) ||
      (slug.schoolId && schoolId !== slug.schoolId)
    ) {
      document.body.scrollTo(0, 0);
    }

    if (slug.moduleId && moduleId !== slug.moduleId) setModuleId(slug.moduleId);

    // if (slug.schoolId && schoolId !== slug.schoolId) setSchoolId(slug.schoolId);
  }, [
    slugsTrigger,
    queriesTrigger,
    modulesTrigger,
    child?.id,
    router.isReady,
    auth?.loading,
  ]);

  // Module
  const isLoading = appLoading || innerAppLoading;

  useEffect(() => {
    setAllowAccess(isLoading || checkAccess(child, module, school, isBulk));
  }, [child?.id, module?.id, school?.id, isBulk, isLoading]);

  const updateTranslations = () => {
    const currentLanguageObj = checkLanguage({
      user,
      school,
      customBranding,
      languages: i18n.languages,
    });
    // Only updates if the level is equal or higher (User > School > Supplier > default)
    const newLanguageLevel = getLanguageLevel(currentLanguageObj.from);
    const savedLanguageLevel = getLanguageLevel(localeFrom);
    const updateComesFromHigherLevel = newLanguageLevel >= savedLanguageLevel;
    if (updateComesFromHigherLevel) {
      setLocaleFrom(currentLanguageObj.from);
      i18n.changeLanguage(currentLanguageObj.language);
    }
  };

  const switchModules = (module: Module) => {
    setFromAPrivateModule(false);
    switch (module.type) {
      case "mealplan":
        return <Mealplan />;
      // case "shop":
      //   return <Mealplan />;
      case "subscription":
        return <Subscription />;
      case "homescreen":
        return <Homescreen module={module} />;
      case "flex": {
        return isBulk ? (
          <FlexBulk />
        ) : module?.flow === "menuPreview" ? (
          <MenuPreview />
        ) : (
          <FlexComponent />
        );
      }
    }
  };

  const renderModules = (module: Module) => {
    const { isFlexAndMenuPreview, isHomescreen, isPaymentMethodCredit } =
      checks({ module });

    /** Deconstruct slugs */
    const slug = deconstructSlugs({
      slugs,
    });

    if (auth.user) {
      setFromAPrivateModule(false);
      return switchModules(module);
    }

    if (!auth.user) {
      if (module?.public) {
        setFromAPrivateModule(false);
        return switchModules(module);
      }
      if (module?.public === undefined) {
        if (isFlexAndMenuPreview || isHomescreen || isPaymentMethodCredit) {
          setFromAPrivateModule(false);
          return switchModules(module);
        }
      }
      if (
        module?.public === false ||
        module?.paymentMethod === "billing" ||
        slug.isBulk
      ) {
        setFromAPrivateModule(true);
        return (
          <Authentication
            action={dataAuthenticationModal.action}
            isFromAPrivateModule={true}
            isFromAnonymousFlow={false}
          />
        );
      }
    }
  };

  return (
    <>
      <Wrapper
        viewName={module?.name || "Kanpla"}
        timeNavigation={timeNavigation}
        withTabs
        newDynamicTabs
        noPadding={module?.flow === "meeting" || module?.flow === "menuPreview"}
      >
        {module && moduleId && <div>{renderModules(module)}</div>}
        <PopupProvider />
        {auth?.user && showCarousel && child?.id && (
          <SignupCarouselWrapper
            schoolId={schoolId}
            childId={child.id}
            signupScreens={signupScreens}
            pushToUrl="/app"
          />
        )}
      </Wrapper>
      {schoolId && (
        <link
          type="text/css"
          href={`/api/stylesheet?schoolId=${schoolId}`}
          key="stylesheet"
          rel="stylesheet"
        />
      )}
    </>
  );
};

export default OrderingPage;
