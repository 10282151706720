import {
  faCreditCard,
  faEdit,
  faExchangeAlt,
  faRepeat,
  faTimes,
  faXmark,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldValue, priceFormatter } from "@kanpla/system";
import {
  Module,
  SubscriptionOrder,
  SubscriptionPeriod,
  SubscriptionProduct,
} from "@kanpla/types";
import { ReceiptWrapper, TooltipHiddenPrices } from "@kanpla/ui";
import { Button, message } from "antd";
import classNames from "classnames";
import { DateTime } from "luxon";
import moment from "moment";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionContext } from "../.";
import { useConfirmation } from "../../confirmProvider";
import ReceiptDivider from "../../mealplan2/Receipt/ReceiptDivider";
import ChangeCard from "./ChangeCard";
import ChooseProduct from "./ChooseProduct";

interface Props {
  subscription: SubscriptionOrder;
  module: Module;
}

const Item = ({ subscription, module }: Props) => {
  const { t, i18n } = useTranslation(["subscription", "translation"]);

  const confirm = useConfirmation();

  const { name, autoRenew, renewedToValid } = subscription;
  const { hidePrices } = module?.config || {};

  const [changingCardOpen, setChangingCardOpen] = useState(false);

  // Data
  const { periods } = useContext(SubscriptionContext);

  const [subsequentPeriod, setSubsequentPeriod] = useState<
    Partial<SubscriptionPeriod>
  >({});
  const subsequentProducts = subsequentPeriod.products;

  // Find next period
  useEffect(() => {
    const { type, to } = subscription;

    const subsequentPeriods = periods[type]
      ? periods[type]
          .filter((p) => {
            return p.from.seconds > to.seconds;
          })
          .sort((a, b) => a.from.seconds - b.from.seconds)
      : [];

    const nextPeriod = subsequentPeriods ? subsequentPeriods[0] : {};
    if (nextPeriod) setSubsequentPeriod(nextPeriod);
  }, [periods, subscription]);

  // Format data from subscription
  const startDate = subscription.startDate
    ? DateTime.fromSeconds(subscription.startDate.seconds).toFormat("d/M")
    : "—";
  const to = DateTime.fromSeconds(subscription.to.seconds).toFormat("d/M");

  const toggleAutoRenew = () => {
    confirm({
      title: autoRenew
        ? t("subscription:sure-you-want-to-unsubscribe", { name, to })
        : t("subscription:renew", { name, to }),
      children: (
        <p className="text-text-primary text-center mb-4">
          {t("subscription:can-change-preferences-later")}
        </p>
      ),
    }).then(() => {
      subscription.ref.update({
        autoRenew: !autoRenew,
      });

      message.success(
        t("subscription:message.success.subscription-terminated-or-activated", {
          value: autoRenew
            ? t("subscription:values.terminated")
            : t("subscription:values.activated"),
        })
      );
    });
  };

  const changeProdukt = async () => {
    const nextStart = DateTime.fromSeconds(
      subsequentPeriod?.to?.seconds || 0
    ).toFormat("D");

    const selectedProduct = (await confirm({
      title: t("subscription:change-to"),
      children: (
        <ChooseProduct
          subsequentProducts={subsequentProducts}
          nextStart={nextStart}
          module={module}
        />
      ),
    })) as SubscriptionProduct;

    if (!selectedProduct?.id) return;
    await subscription.ref.update({
      changingProductId:
        selectedProduct?.id === subscription.productId
          ? FieldValue.delete()
          : selectedProduct.id,
    });

    message.success(
      t("subscription:message.success.subscription-product-changed")
    );
  };

  const changingProduct =
    autoRenew &&
    subscription.changingProductId &&
    subsequentProducts &&
    subsequentProducts.find((p) => p.id === subscription.changingProductId);

  // Hide if has been renewed
  if (renewedToValid) return null;

  // Hide if is over
  const isOver = subscription.to.seconds < moment().unix();

  const bottomActions = isOver
    ? [
        <Button
          size="small"
          disabled
          className="h300 ml-auto cursor-default mr-2"
        >
          {t("subscription:ended")}
        </Button>,
      ]
    : !autoRenew
    ? [
        <Button
          onClick={toggleAutoRenew}
          className="h300 text-primary-dark"
          type="link"
        >
          {t("subscription:redraw")}
        </Button>,
      ]
    : [
        !subsequentProducts && (
          <Button onClick={changeProdukt} className="font-semibold" type="link">
            <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
            {t("subscription:change-product")}
          </Button>
        ),
        <Button
          onClick={() => setChangingCardOpen(true)}
          className="font-semibold"
          type="link"
        >
          <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
          {t("subscription:select-payment-card")}
        </Button>,
        <Button onClick={toggleAutoRenew} className="font-semibold" type="link">
          <span className="text-danger-main">
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            {t("subscription:cancel-subscription")}
          </span>{" "}
        </Button>,
      ];

  return (
    <>
      <div className="border-x-2 px-6 pt-6 pb-3 flex flex-wrap">
        <SubscriptionInfo title={t("subscription:product-name")}>
          {subscription.name}
        </SubscriptionInfo>
        <SubscriptionInfo title={t("subscription:price-subscription")}>
          {priceFormatter(
            subscription.resultingPrice,
            {
              language: i18n.language,
            },
            false,
            hidePrices,
            {
              wrapper: () => "-",
              component: () => <TooltipHiddenPrices />,
            }
          )}
        </SubscriptionInfo>
        <SubscriptionInfo title={t("subscription:period")}>
          <>
            {startDate} -{" "}
            <span className={`${!autoRenew && "text-danger-main"}`}>{to}</span>{" "}
            {changingProduct &&
              t("subscription:changed-to", { value: changingProduct.name })}
          </>
        </SubscriptionInfo>
        <SubscriptionInfo title={t("subscription:auto-renew")}>
          <span
            className={classNames({
              uppercase: true,
              "text-danger-main": !subscription.autoRenew,
              "text-primary-main": subscription.autoRenew,
            })}
          >
            {subscription.autoRenew
              ? t("subscription:on")
              : t("subscription:off")}
          </span>
        </SubscriptionInfo>

        <ChangeCard open={changingCardOpen} setOpen={setChangingCardOpen} />
      </div>

      <ReceiptDivider />

      <ReceiptWrapper.BottomActions bottomActions={bottomActions} />
    </>
  );
};

interface SubscriptionInfoProps {
  children: ReactNode;
  title: string;
}

const SubscriptionInfo = ({ children, title }: SubscriptionInfoProps) => {
  return (
    <div className="w-2/4 p-2">
      <p className="uppercase text-sm font-medium text-secondary-dark">
        {title || "-"}
      </p>
      <p className="font-semibold">{children}</p>
    </div>
  );
};

export default Item;
