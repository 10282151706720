import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLastFlexOrderChange } from "@kanpla/system";
import { Divider, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { AppContext } from "../../contextProvider";
import LineWrapper from "../LineWrapper";
import StandardOrderButton from "../StandardOrderButton";
import MenuView from "./MenuView";

const MenuBulk = () => {
  const { t } = useTranslation(["flex", "translation", "flex-bulk"]);

  const { date } = useContainer(AppContext);
  const {
    pastDate,
    deadline,
    softDeadlineMaxAmount,
    order,
    orderDocument,
    numberOfItems,
    isBulk,
    setSettingsOpen,
    weekProducts,
    module,
  } = useContainer(FlexContext);

  const { availableChanges } = getLastFlexOrderChange({
    order: orderDocument,
    date,
    deadline,
    localOrder: order,
    softDeadlineMaxAmount,
  });

  // Check soft deadlines max order changes
  useEffect(() => {
    if (!isBulk || !pastDate) return;

    if (availableChanges < 0) message.error(t("flex:message.error.exceeded"));
  }, [order]);

  return (
    <div className="w-full">
      <div className="pb-6 pt-1">
        <div
          style={{
            columns: "1",
            breakInside: "avoid",
            columnFill: "balance",
            WebkitColumnFill: "balance",
            MozColumnFill: "balance",
            pageBreakInside: "avoid",
            columnGap: 32,
            MozColumnGap: 32,
            WebkitColumnGap: 32,
          }}
        >
          <MenuView selectedProducts={weekProducts} />
        </div>

        {isBulk && (
          <LineWrapper>
            <p className="text-text-secondary pt-3 pb-4 items-center flex justify-center relative">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl" />
              {t("flex:ordering-has-been-confirmed", { value: numberOfItems })}
            </p>
          </LineWrapper>
        )}

        <Divider className="my-8" />

        <div>
          <h3 className="h500">{t("flex-bulk:default-selection")}</h3>
          <p>{t("flex:default-selection-description")}</p>
          <StandardOrderButton
            setOpen={setSettingsOpen}
            full={false}
            flex={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuBulk;
