import "pure-react-carousel/dist/react-carousel.es.css";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import { BasketListItem } from "../BasketList";

type Props = {};

const ProductsAdvanced = (props: Props) => {
  const { basket, setBasket } = useContainer(AppContext);
  const { items, order, orderInfo, module } = useContainer(MealplanContext);

  const itemsTrigger = items.length;
  useEffect(() => {
    const itemEntries = items.map((item) => [
      item.productId,
      {
        name: item.name,
        price: item.price,
        productId: item.productId,
        amount: basket?.[item.productId]?.amount || 0,
        config: [
          {
            amount: basket?.[item.productId]?.amount || 0,
            options: {},
            name: item.name,
          },
        ],
        photo: item.photo,
      },
    ]);

    setBasket(Object.fromEntries(itemEntries));
  }, [itemsTrigger]);

  return (
    <div className="mx-4">
      {items.map((item) => {
        return (
          <div key={item.productId}>
            <BasketListItem
              item={{
                name: item.name,
                price: item.price,
                productId: item.productId,
                config: {
                  amount: basket?.[item.productId]?.amount || 0,
                  options: {},
                  name: item.name,
                },
                photo: item.photo,
              }}
              editMode={true}
              basket={basket}
              setBasket={setBasket}
              hideDeleteButton={true}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProductsAdvanced;
