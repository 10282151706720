import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface BasketResultProps {
  amount: number;
  price: number;
}

/** Draws the basket result: i.e. summary amount of items and summary price. */
export const BasketResult: FC<BasketResultProps> = ({ amount, price }) => {
  const { i18n } = useTranslation();

  const { module } = useContainer(AppContext);

  const { hidePrices } = module?.config || {};

  return (
    <div className="flex justify-between items-center mt-6 border-t-2 border-divider-main py-4 text-text-primary -mx-8 px-8">
      <div className="font-semibold text-lg">
        {amount}
        <FontAwesomeIcon icon={faShoppingBag} className="ml-2" />
      </div>
      <div>
        i alt:
        <b className="text-lg ml-2">
          {priceFormatter(
            price,
            { language: i18n.language },
            false,
            hidePrices,
            {
              wrapper: () => "-",
              component: () => <TooltipHiddenPrices />,
            }
          )}
        </b>
      </div>
    </div>
  );
};
