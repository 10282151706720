import { CarouselContext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useContext, useEffect } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";

type Props = {};

const ProductsCarouselContextListener = ({}: Props) => {
  const { basket, setBasket } = useContainer(AppContext);
  const { items } = useContainer(MealplanContext);

  const carouselContext = useContext(CarouselContext);

  // Initially load first product
  const firstProductId = items?.[0]?.id;
  useEffect(() => {
    const firstProduct = items?.[0];
    if (!firstProduct) return;
    setBasket({
      [firstProduct.productId]: {
        amount: 1,
        name: firstProduct.name,
        price: firstProduct.price,
        config: [],
      },
    });
  }, [firstProductId]);

  // Listen to changes in the carousel
  useEffect(() => {
    function onChange() {
      const currentSlide = Math.round(carouselContext.state.currentSlide);
      const orderedProduct = items[currentSlide];
      if (!orderedProduct) return;

      setBasket({
        [orderedProduct.productId]: {
          amount: 1,
          name: orderedProduct.name,
          price: orderedProduct.price,
          config: [],
        },
      });
    }

    carouselContext?.subscribe(onChange);
    return () => carouselContext?.unsubscribe(onChange);
  }, [carouselContext]);

  return <></>;
};

export default ProductsCarouselContextListener;
