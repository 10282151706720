import {
  calculateAmountOfOrderItems,
  isDatePastDeadline,
} from "@kanpla/system";
import { DayIndex, FlexOption, OrderConfig, OrderOrder } from "@kanpla/types";
import { Radio, Space } from "antd";
import { setWith } from "lodash";
import React, { useEffect } from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import { AppContext } from "../contextProvider";
import { firstColumnStyles } from "./Menu";

const GlobalVariant = () => {
  const { module } = useContainer(AppContext);

  const globalVariantIds =
    module?.plugins?.globalVariant?.active &&
    module?.plugins?.globalVariant?.productOptionIds;

  if (!globalVariantIds) return null;

  return (
    <>
      {globalVariantIds.map((id) => (
        <Option id={id} />
      ))}
    </>
  );
};

const Option = ({ id }) => {
  const { week } = useContainer(AppContext);
  const { mealOptions } = useContainer(FlexBulkContext);

  const option = mealOptions.find((o) => o.id === id) as FlexOption;

  if (!option) return null;

  return (
    <div className="border border-divider-main rounded-lg mb-2">
      <div className="h400 pt-4 px-4 pb-1">{option.name}</div>

      <div className="flex flex-wrap mb-2 px-4">
        <div className={`${firstColumnStyles}`}>
          {option.choices.map((choice) => (
            <div key={choice.id} className="py-1">
              {choice.name}
            </div>
          ))}
        </div>
        {week.map((date, dayIndex: DayIndex) => (
          <GlobalVariantDay
            key={`${id}-${date.seconds}`}
            dayIndex={dayIndex}
            option={option}
          />
        ))}
      </div>
    </div>
  );
};

const GlobalVariantDay = ({ dayIndex, option }) => {
  const { week } = useContainer(AppContext);
  const {
    orders,
    setOrders,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  } = useContainer(FlexBulkContext);
  const order = orders?.[dayIndex] || ({} as OrderOrder);
  const amountOfProducts = calculateAmountOfOrderItems(order);

  const overDeadline = isDatePastDeadline({
    date: week[dayIndex],
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  const optionChoiceIds = option.choices.map((c) => `${c.id}`);

  const detectGlobalVariant = (order: OrderOrder) => {
    const todayProducts = Object.values(order || {});
    const todayConfigIds = todayProducts
      .map((p) => (p.config || []).map((c) => Object.keys(c.options || {})))
      .flat(2);

    const value = todayConfigIds.find((id) => optionChoiceIds.includes(id));
    return value;
  };

  const setVariantToOrder = (newChoiceId: string, dayIndex: DayIndex) => {
    setOrders((o) => {
      const oldTodaysOrder = o?.[dayIndex] || {};
      const newTodaysOrder = oldTodaysOrder;

      // For each product of that day
      Object.entries(oldTodaysOrder).forEach(([productId, orderProduct]) => {
        const hasConfig =
          orderProduct["config"] && orderProduct["config"].length > 0;
        const oldConfigs = hasConfig
          ? orderProduct["config"]
          : [
              {
                amount: orderProduct["amount"],
                options: {},
              } as OrderConfig,
            ];

        // For each config of that product
        const newConfigs = oldConfigs.map((config) => {
          // Update choices
          const oldChoicesOptions = Object.entries(
            config.options || ({} as OrderConfig["options"])
          );
          const oldChoices = oldChoicesOptions.map(([choiceId, value]) => ({
            ...value,
            id: choiceId,
          }));
          const restOfChoices = oldChoices.filter(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            (o) => {
              return !option.choices.some((ch) => `${ch.id}` === `${o.id}`);
            }
          );

          const newChoice = option.choices.find(
            (ch) => `${ch.id}` === newChoiceId
          );

          const orderChoices = [
            ...restOfChoices,
            {
              name: newChoice?.name,
              id: `${newChoiceId}`,
              extraPrice: newChoice?.price,
              amount: 1,
            },
          ];

          const newTargetConfigOptions: OrderConfig["options"] =
            orderChoices.reduce((acc, choice) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              if (choice.amount > 0) acc[choice.id] = choice;
              return acc;
            }, {} as OrderConfig["options"]);

          return {
            ...config,
            options: newTargetConfigOptions,
          };
        });

        setWith(newTodaysOrder, `${productId}.config`, newConfigs, Object);
      });

      return {
        ...o,
        [dayIndex]: newTodaysOrder,
      };
    });
  };

  const value = detectGlobalVariant(order);

  const handleChange = (e) => {
    const newChoiceId = e.target.value;
    setVariantToOrder(newChoiceId, dayIndex);
  };

  // Update variants when order changes
  useEffect(() => {
    setVariantToOrder(value, dayIndex);
  }, [amountOfProducts]);

  return (
    <div className="flex-1 text-xs font-medium text-center">
      <Radio.Group onChange={handleChange} value={value}>
        <Space direction="vertical">
          {option.choices.map((choice) => {
            return (
              <Radio value={`${choice.id}`} disabled={overDeadline}></Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default GlobalVariant;
