import React from "react";
import DashboardSkeleton from "./DashboardSkeleton";

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

const ModuleLoadingWrapper = ({ loading, children }: Props) => {
  return (
    <div className="relative">
      <div
        className={`absolute inset-x-0 top-0 pointer-events-none z-0 transition-opacity ${
          loading ? "" : "opacity-0"
        }`}
      >
        <DashboardSkeleton noHeader />
      </div>
      <div className={`${loading ? "opacity-0" : ""} transition-opacity`}>
        {children}
      </div>
    </div>
  );
};

export default ModuleLoadingWrapper;
