import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React, { ReactNode } from "react";

interface Props {
  pageTitle: string;
  children: ReactNode;
  backButtonText?: string;
  hideBackButton?: boolean;
  hideAction?: boolean;
  action?: ReactNode;
  onBack?: () => void;
}

const StepWrapper = (props: Props) => {
  const { t } = useTranslation(["translation"]);
  const {
    children,
    pageTitle,
    onBack,
    backButtonText = t("translation:back"),
    hideBackButton = false,
    hideAction = false,
    action = <></>,
  } = props;

  return (
    <div className="flex flex-col">
      {!hideBackButton && (
        <button
          onClick={onBack}
          className="-mb-6 whitespace-nowrap w-min font-regular text-text-secondary hover:opacity-70 cursor-pointer flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {backButtonText}
        </button>
      )}
      <div className="flex w-full justify-between items-center mt-8">
        <h1 className="h600">{pageTitle}</h1>
        {!hideAction && action}
      </div>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default StepWrapper;
