import { db, fetchDocument } from "@kanpla/system";
import { User } from "@kanpla/types";
import { Button, Form, Input, message, Space } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const SuperadminSwitch = () => {
  const { userId, setOverrideUser, isSuperadmin } = useContainer(AppContext);

  const [loading, setLoading] = useState(false);

  const findUser = async (uid: string) => {
    console.log(
      "🚀 ~ file: SuperadminSwitch.tsx ~ line 18 ~ findUser ~ uid",
      uid
    );
    try {
      setLoading(true);
      if (!uid) throw new Error("No user ID");
      if (!isSuperadmin) throw new Error("You are not a superadmin!");
      if (uid === userId) return;

      const user = await fetchDocument<User>(db.collection("users").doc(uid));
      if (isEmpty(user)) throw new Error("User doesn't exist");

      setOverrideUser(user || ({} as User));
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isSuperadmin) return null;

  return (
    <div className="text-text-primary mr-1 p-4 print:hidden bg-background-secondary border-2 border-primary-main flex flex-col justify-between">
      <h2 className="text-sm text-primary-dark mb-2">Superadmin</h2>
      <Form
        onFinish={({ userId: tableUserId }) => findUser(tableUserId)}
        onReset={() => findUser(userId)}
      >
        <Form.Item name="userId">
          <Input className="" placeholder="Add user ID" disabled={loading} />
        </Form.Item>
        <Space>
          <Button htmlType="submit" loading={loading} type="primary">
            Mask
          </Button>
          <Button htmlType="reset">Clear</Button>
        </Space>
      </Form>
    </div>
  );
};

export default SuperadminSwitch;
