import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, getMidnightSeconds, useCollectionListener } from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import { Input, Segmented, Space } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { isEmpty, orderBy } from "lodash";
import moment from "moment";
import { BooleanParam, useQueryParam } from "next-query-params";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "..";
import { MealplanContext } from "../..";
import Table from "../Table";
import Export from "./Export";

const { Search } = Input;

const History = () => {
  const { t, i18n } = useTranslation([
    "mealplan2",
    "translation",
    "modals",
    "settings",
  ]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { childId, userId, module } = useContainer(AppContext);
  const { moduleId } = useContainer(MealplanContext);
  const { setCurrentView } = useContext(MealplanMeetingContext);

  const [query, setQuery] = useState("");

  const todaySeconds = getMidnightSeconds(moment().unix());

  const [backInTime, setBackInTime] = useQueryParam("historical", BooleanParam);

  const [allOrders = [], ordersLoading] = useCollectionListener<OrderMealplan>(
    db
      .collection("orders")
      .where("childId", "==", childId)
      .where("userId", "==", userId)
      .where("moduleId", "==", moduleId)
      .where("dateSeconds", backInTime ? "<" : ">=", todaySeconds)
      .orderBy("dateSeconds")
  );

  const orders = allOrders.filter((o) => !o.deleted && !isEmpty(o.order));

  // Search List
  const filteredOrders = useMemo(() => {
    if (query === "" || query.length < 3) return orders;

    const pattern = new RegExp(query, "i");

    const queryOrders = orders.filter((c) => {
      const dateMoment = moment.unix(c?.dateSeconds);

      return (
        pattern.test(dateMoment.format("dddd")) ||
        pattern.test(dateMoment.format("D/M")) ||
        pattern.test(dateMoment.format("D.M.")) ||
        pattern.test(
          c?.info?.name ||
            t("mealplan2:order-to-d", { value: dateMoment.format("D.M.") })
        )
      );
    });

    return queryOrders;
  }, [query, orders]);

  return (
    <div className="flex flex-col w-full">
      <div className="p-8 md:px-14 flex-col">
        <p
          onClick={() => setCurrentView("overview")}
          className="whitespace-nowrap w-min font-regular text-text-secondary hover:opacity-70 cursor-pointer flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {t("translation:back")}
        </p>
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mt-4">
          <h1 className="text-2xl font-semibold h800 mb-4 md:mb-0">
            {t("mealplan2:order-history")}
          </h1>
          <Space className="flex-col md:flex-row items-start">
            <Export />
            <Segmented
              value={backInTime ? "back" : "forward"}
              options={[
                { value: "back", label: t("settings:history") },
                { value: "forward", label: t("modals:coming") },
              ]}
              onChange={(value) => setBackInTime(value === "back")}
            />
            <Search
              style={{ maxWidth: "300px" }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("mealplan2:form.placeholders.search-by-name-date")}
            />
          </Space>
        </div>
      </div>
      {ordersLoading ? (
        <div className="w-full flex-col mt-8">
          <div className="h-20 loader w-full rounded-lg bg-background-secondary border my-1" />
          <div className="h-20 loader w-full rounded-lg bg-background-secondary border my-1 opacity-70" />
          <div className="h-20 loader w-full rounded-lg bg-background-secondary border my-1 opacity-50" />
          <div className="h-20 loader w-full rounded-lg bg-background-secondary border my-1 opacity-20" />
        </div>
      ) : (
        <div className="mt-8 w-full">
          <Table
            orders={orderBy(
              filteredOrders,
              "dateSeconds",
              backInTime ? "desc" : "asc"
            )}
            module={module}
          />
        </div>
      )}
    </div>
  );
};

export default History;
