import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, removeUndefinedKeys } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Allergens, ChildView, sectionClasses } from "@kanpla/ui";
import { Button, message } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChildForm from "../forms/childForm";
import AdhocSettings from "./AdhocSettings";
import DeleteKid from "./deleteKid";

interface Props {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isChildSalesplace?: boolean;
}

const EditKid = ({
  child,
  open,
  setOpen,
  isChildSalesplace = false,
}: Props) => {
  const { t } = useTranslation(["translation"]);

  return (
    <ChildView open={open} setOpen={setOpen} title={t("translation:edit-user")}>
      <Content
        child={child}
        open={open}
        isChildSalesplace={isChildSalesplace}
      />
    </ChildView>
  );
};

const Content = ({
  child,
  open,
  isChildSalesplace = false,
}: {
  child: Child;
  open: boolean;
  isChildSalesplace: boolean;
}) => {
  const { t } = useTranslation(["modals", "translation"]);

  const { school, customBranding, updateCurrentChild } =
    useContainer(AppContext);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const extendedClasses = `${sectionClasses} mt-6`;

  const submit = async (data) => {
    try {
      removeUndefinedKeys(data);
      await updateCurrentChild(data);
      message.success(t("modals:message.success.info-about-user"));
    } catch (e) {
      message.error(e.message);
    }
  };

  if (!open) return null;

  return (
    <>
      <div className="pb-4 flex items-center">
        <FontAwesomeIcon
          icon={faUserCircle}
          size="3x"
          className="text-text-primary mr-3"
        />
        <div>
          <h3 className="h600 leading-tight">{child.name}</h3>
          <p className="text-text-secondary text-sm">
            {!isChildSalesplace ? (
              <>
                {child.school_name}
                {child?.groupName ? `, ${child.groupName}` : ``}
              </>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
      <div className={extendedClasses}>
        <h3 className="h500 mb-2">Information</h3>
        <div className="">
          <ChildForm
            submit={submit}
            child={child}
            isChildSalesplace={isChildSalesplace}
            edit
          />
        </div>
      </div>

      {school?.hasCards && (
        <div className={extendedClasses}>
          <AdhocSettings child={child} />
        </div>
      )}

      <Allergens
        onSave={() => {
          return;
        }}
        child={child}
        customBranding={customBranding}
      />

      <div className={extendedClasses}>
        <h3 className="h500 mb-2">{t("modals:other-options")}</h3>
        <Button
          className="mt-1"
          onClick={() => setDeleteOpen(true)}
          size="small"
          danger
        >
          {t("translation:delete-dynamic", { value: child.name })}
        </Button>
      </div>
      <DeleteKid open={deleteOpen} setOpen={setDeleteOpen} child={child} />
    </>
  );
};

export default EditKid;
