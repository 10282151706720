import { db, getProductsPerSchool } from "@kanpla/system";
import { Module, ProductBank } from "@kanpla/types";
import { Print as PrintWrapper } from "@kanpla/ui";
import getMenus from "apps/frontend/lib/flex/getMenus";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  children: JSX.Element;
  productBank: ProductBank;
  module: Module;
  isDigital?: boolean;
}

const SinglePrint = ({ children, productBank, module, isDigital }: Props) => {
  const { week, school } = useContainer(AppContext);

  const products = getProductsPerSchool({
    schoolId: school?.id,
    productBank: productBank,
    module: module,
    db,
  });

  const { menus: allMenus } = getMenus({ products });
  const menus = allMenus.filter((m) => m);

  return (
    <PrintWrapper menus={menus} products={[]} week={week} isDigital={isDigital}>
      {children}
    </PrintWrapper>
  );
};

export default SinglePrint;
