import { db } from "@kanpla/system";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";

const useNotifications = () => {
  const { school } = useContainer(AppContext);

  // Get data
  const [notifications = []] = useCollectionDataOnce(
    school?.id
      ? db
          .collection("notifications")
          .where("scope.generatedSchoolIds", "array-contains", school.id)
      : null
  );

  return { notifications };
};

export default useNotifications;
