import moment from "moment";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const UIJumpLink = ({ defaultDate }) => {
  const { i18n } = useTranslation();
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { setDate } = useContainer(AppContext);

  return (
    <button
      className="text-primary-main font-medium border-b-2 -mb-1 border-primary-light"
      onClick={() => setDate(defaultDate)}
    >
      {defaultDate &&
        moment
          .unix(defaultDate?.seconds)
          .locale(i18n.language)
          .format("dddd, D/M")}
    </button>
  );
};

export default UIJumpLink;
