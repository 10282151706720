import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  callInternalApi,
  deadlineDisplay,
  deadlineFormatter,
  hasAccessToModule,
  isBelowMinimum,
  isDatePastDeadline,
  useFetch,
  useSubmit,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  flexBulkOrder,
  FlexBulkStandard,
  OrderOrder,
} from "@kanpla/types";
import { ButtonSave } from "@kanpla/ui";
import { Button, message, Space } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import {
  LoadFlexBulkProps,
  LoadFlexBulkReturn,
} from "apps/frontend/pages/api/internal/load/flexBulk";
import {
  SubmitFlexBulkOrderProps,
  SubmitFlexBulkOrderReturn,
} from "apps/frontend/pages/api/internal/submit/flexBulkOrder";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import AdminOverview from "./AdminOverview";
import Menu from "./Menu";

interface WeekOrders {
  [dayIndex: number]: OrderOrder;
}

interface WeekDocuments {
  [dayIndex: number]: flexBulkOrder;
}

const ContextState = () => {
  const { t } = useTranslation(["translation", "flex-bulk"]);

  const {
    school,
    week,
    child,
    userId,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    offer,
  } = useContainer(AppContext);

  const router = useRouter();

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(false);
      router.replace(newUrl);
    }
  }, []);

  useEffect(() => {
    setTimeNavigation("calendarWeekly");
  }, []);

  // Standard
  const [standardOrders, setStandardOrders] =
    useState<FlexBulkStandard["standard"]>(null);

  // Order
  const [weeklyOrders, setWeeklyOrders] = useState<WeekOrders>(null);
  const [orderDocuments, setOrderDocuments] = useState<WeekDocuments>(null);

  const keys = {
    moduleId,
    groupName: child?.groupName,
    schoolId,
    weekSeconds: week?.[0]?.seconds,
  };
  console.log("🚀 ~ file: index.tsx ~ line 98 ~ ContextState ~ keys", keys);
  const { data, setData } = useFetch<LoadFlexBulkProps, LoadFlexBulkReturn>(
    "load/flexBulk",
    keys
  );
  const orderLoading = !data;

  const dataTrigger = JSON.stringify(data);
  useEffect(() => {
    if (!data) return;
    const { weekOrders, weekDocuments, standard } = data;
    // Reset everything on load
    setStandardOrders(standard);
    setWeeklyOrders(weekOrders);
    setOrderDocuments(weekDocuments);
  }, [dataTrigger, router.pathname]);

  // Standard View
  const [saved, setSaved] = useState(true);
  const [isStandardView, setStandardView] = useState(false);
  const orders = isStandardView ? standardOrders : weeklyOrders;
  const setOrders = (newValue: WeekOrders) => {
    setSaved(false);
    if (isStandardView) {
      setStandardOrders(newValue);
    } else {
      setWeeklyOrders(newValue);
    }
  };

  useEffect(() => {
    setSaved(true);
  }, [week?.[0]?.seconds]);

  // Offers
  const { items, holidayDates, deadlineInfo, mealOptions } = offer || {};

  // Deadline Jump
  useDeadlineJump({ defaultDate: deadlineInfo?.defaultDate });

  const isProductOrdered = (product: CombinedOfferItem) => {
    const productId = product.id;
    const isOrdered = Object.values(orders || {}).some(
      (order) => order?.[productId]?.amount > 0
    );
    return isOrdered;
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
    defaultSoftDate,
  } = deadlineInfo;
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  // Submit standard
  const { submit } = useSubmit({
    path: "submit/flexBulkStandard",
    setData,
    requestConstructor: (standardOrders) => ({
      standard: standardOrders,
      groupName: child.groupName,
      schoolId,
      moduleId,
      childId,
    }),
    responseDestructor: (standard) => ({
      standard,
    }),
  });
  const saveStandard = () => {
    submit(standardOrders);
    setSaved(true);
    setStandardView(false);
    message.success(t("translation:message.success.standards-updated"));
  };

  // Submit orders
  // const { submit: submitOrders } = useSubmit({
  //   path: "submit/flexBulkOrders",
  //   setData,
  //   requestConstructor: (weeklyOrders) => ({
  //     weeklyOrders,
  //   }),
  //   responseDestructor: (weeklyOrders) => ({
  //     weeklyOrders,
  //   }),
  // });
  const saveOrder = async () => {
    try {
      message.loading(t("flex-bulk:message.loading.orders-saved"), 0);

      const promises = week.map(async (date, dayIndex) => {
        const targetOrder = weeklyOrders[dayIndex];
        if (!targetOrder) return;

        const isOrderPastDeadline = isDatePastDeadline({
          date,
          deadline,
          deadlineExcludingWeekends,
          deadlineWeekRelative,
        });

        if (isOrderPastDeadline) return;
        console.log(
          "🚀 ~ file: index.tsx ~ line 217 ~ promises ~ child.groupName",
          child.groupName
        );

        await callInternalApi<
          SubmitFlexBulkOrderProps,
          SubmitFlexBulkOrderReturn
        >("submit/flexBulkOrder", {
          schoolId,
          dateSeconds: date.seconds,
          order: targetOrder,
          moduleId,
          groupName: child.groupName,
        });
      });

      await Promise.all(promises);

      message.destroy();
      message.success(t("flex-bulk:message.success.order-saved"));
      setSaved(true);
    } catch (err) {
      message.destroy();
      message.error(
        err?.message || t("flex-bulk:message.error.order-could-not-saved")
      );
    }
  };

  const daysHolidays = week.map((_, dayIndex: DayIndex) => {
    const dateSeconds = week[dayIndex]?.seconds;
    const holidayOrNull = holidayDates?.[dateSeconds];
    return !!holidayOrNull;
  });

  return {
    module,
    moduleId: module.id,
    items,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    defaultDate,
    softDeadline: deadlineSoft,
    defaultSoftDate,
    softDeadlineMaxAmount,
    deadlineFormatted,
    orders,
    orderDocuments,
    setOrders,
    isStandardView,
    daysHolidays,
    isProductOrdered,
    saved,
    saveStandard,
    saveOrder,
    setStandardView,
    mealOptions,
  };
};

export const FlexBulkContext = createContainer(ContextState);

const Index = () => {
  return (
    <FlexBulkContext.Provider>
      <View />
    </FlexBulkContext.Provider>
  );
};

const View = () => {
  const { t } = useTranslation(["flex-bulk", "translation"]);

  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    isStandardView,
    saved,
    saveStandard,
    saveOrder,
    module,
    setStandardView,
    orders,
  } = useContainer(FlexBulkContext);
  const { innerAppLoading, school, week } = useContainer(AppContext);

  const someDaysAreBelow = () => {
    const someAreBelow = week.some((_, dayIndex) => {
      const isBelow = isBelowMinimum({
        school,
        localOrder: orders?.[dayIndex] || {},
      });

      return isBelow;
    });

    return someAreBelow;
  };

  const disabledProp = useMemo(() => {
    const disabled = someDaysAreBelow();

    return disabled ? { disabled } : {};
  }, [JSON.stringify(week), JSON.stringify(orders), school]);

  return (
    <>
      <NavbarSecondary
        timeNavigation="weekly"
        deadlineFormatted={deadlineDisplay({
          deadline,
          deadlineWeekRelative,
          deadlineExcludingWeekends,
        })}
        extraContent={
          <ButtonSave
            saved={saved}
            onClick={() => (isStandardView ? saveStandard() : saveOrder())}
            {...disabledProp}
            size="large"
            className="w-full md:w-auto"
          />
        }
      />
      <div className="wrapper">
        <ModuleDescription align="left" module={module} />
        <ModuleLoadingWrapper loading={innerAppLoading}>
          {isStandardView && (
            <Space direction="vertical">
              <Button
                onClick={() => setStandardView(false)}
                className="-mt-3 mb-3"
              >
                <Space>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {t("flex-bulk:back-to-order")}
                </Space>
              </Button>
              <p className="text-text-secondary max-w-2xl pb-3">
                <Trans t={t} i18nKey="flex-bulk:here-you-can">
                  Her kan du
                  <span className="font-semibold">
                    tilføje og redigere din automatiske standard bestilling
                  </span>
                  på vegne af din virksomhed. Vi anbefaler desuden, at du altid
                  gennemgår dine kommende bestillinger efter ændring i
                  standardvalget.
                </Trans>
              </p>
            </Space>
          )}

          <Menu />
          {!isStandardView && (
            <div className="lg:grid grid-cols-2 gap-4 px-3 lg:px-0">
              <div className="mb-4">
                <h4 className="h500 pb-2">
                  {t("flex-bulk:default-selection")}
                </h4>
                <p className="text-sm text-text-primary">
                  {t("flex-bulk:set-default-choice")}
                </p>
                <Button
                  type="primary"
                  className="mt-3"
                  onClick={() => setStandardView(true)}
                >
                  {t("translation:edit-default-selection")}
                </Button>
              </div>
              <div className="mb-4">
                <AdminOverview availableModules={[module]} />
              </div>
            </div>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};

export default Index;
