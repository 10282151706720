import React from "react";
import { cloneDeep } from "lodash";
import { Checkbox, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { TFunction, Trans } from "react-i18next";
const { confirm } = Modal;

interface Props {
  childAllergies: Array<string>;
  productAllergens: Array<string>;
  t: TFunction<("translation" | "allergy" | "libs")[], undefined>;
}

const processAllergy = async ({
  childAllergies = [],
  productAllergens = [],
  t,
}: Props) => {
  const dontAskForAllergenes =
    typeof window !== "undefined" &&
    window.localStorage.getItem("prompt:dont-ask-for-allergenes");

  const setDontAskForAllergenes = (value: boolean) =>
    window.localStorage.setItem(
      "prompt:dont-ask-for-allergenes",
      JSON.stringify(value)
    );

  if (!JSON.parse(dontAskForAllergenes)) {
    // Get intersection allergens
    const intersectionAllergens = childAllergies.filter((allergy) =>
      productAllergens.includes(allergy)
    );

    // If the child's allergies match the product allergens
    if (intersectionAllergens?.length) {
      // Deep cloning since it's mutable
      const allergensCopy = cloneDeep(
        intersectionAllergens.map((allergen) =>
          // @ts-ignore
          t(`libs:allergens-list.${allergen}`)
        )
      ) as Array<string>;
      const last = allergensCopy.pop();
      const resultAllergyText =
        intersectionAllergens.length === 1
          ? last
          : `${allergensCopy.join(", ")} ${t("translation:and")} ${last}`;

      const promise = new Promise((resolve, reject) => {
        confirm({
          centered: true,
          title: t("allergy:you-might-be"),
          icon: (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-warning-main text-5xl mb-3"
            />
          ),
          content: (
            <div>
              <p className="text-text-secondary">
                <Trans t={t} i18nKey="allergy:product-contains">
                  This products contains
                  <b>{{ value: resultAllergyText.toLowerCase() }}</b>, which
                  you've selected you are allergic to. Would you like to still
                  add this product to the basket?
                </Trans>
              </p>
              <Checkbox
                className="mt-2 text-text-secondary"
                onChange={(value) =>
                  setDontAskForAllergenes(value.target.checked)
                }
              >
                {t("translation:dont-ask-me-again")}
              </Checkbox>
            </div>
          ),
          okText: t("translation:add-to-basket"),
          onOk() {
            resolve(() => null);
          },
          onCancel() {
            reject(new Error(t("allergy:product-not-added")));
          },
        });
      });

      await Promise.resolve(promise);
    }
  }
};

export default processAllergy;
