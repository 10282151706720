import { Child } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChildActions from "./ChildActions";

interface Props {
  child: Child;
}

const Kid = ({ child }: Props) => {
  const { childId } = useContainer(AppContext);

  const selectedChild = child?.id === childId;
  const initials = child.name
    ?.split(" ")
    .map((word) => word[0])
    .filter((ch) => /([A-Za-z])/.test(ch))
    .join("");

  return (
    <div className="border-b pt-2 pb-2">
      <div className="flex justify-between items-center py-4 flex-wrap gap-3">
        {/* Icon */}
        <div
          className={`${
            selectedChild
              ? "border border-primary-main p-0.5"
              : "border border-divider-main p-0.5 bg-background-secondary"
          } rounded-full w-12 h-12 flex-shrink-0 flex items-center justify-center`}
        >
          <div
            className={`${
              selectedChild
                ? "text-background-primary bg-primary-main"
                : "text-text-secondary bg-background-secondary"
            } rounded-full flex justify-center items-center text-xl font-medium w-full h-full`}
          >
            {initials?.[0]}
            {initials?.[1]}
          </div>
        </div>

        <div className="mr-auto">
          <h3 className="text-text-primary h400">{child.displayName}</h3>
          <p
            className="text-sm text-text-secondary -mt-1 overflow-hidden overflow-ellipsis whitespace-nowrap"
            style={{ maxWidth: "30ch" }}
          >
            {child.school_name}
          </p>
        </div>

        {/* Actions */}
        <ChildActions selectedChild={selectedChild} child={child} />
      </div>
    </div>
  );
};

export default Kid;
