/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter, useWindowSize } from "@kanpla/system";
import { AutoScrollContainer, Category, HorizontalSlider } from "@kanpla/ui";
import { Modal } from "antd";
import { capitalize } from "lodash";
import React, { useContext, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "../..";
import { MealplanContext } from "../../..";
import Product from "../../../Product";
import { useCategories } from "../../../useCategories";
import StepWrapper from "../../Wrapper";
const { confirm } = Modal;

const ProductSelection = () => {
  const { t } = useTranslation(["translation", "mealplan2"]);

  const { setCurrentView } = useContext(MealplanMeetingContext);
  const { module } = useContainer(MealplanContext);
  const { isMobileSize } = useWindowSize();

  const categories = useCategories();

  const categoryItems = useMemo(
    () =>
      Object.entries(categories)
        .filter(([name, items]) => items?.length)
        .map(
          ([name]) =>
            capitalize(name) ||
            module?.text?.["noCategory/text"] ||
            t("translation:dish-of-the-day")
        ),
    [categories, t, module?.text]
  );

  const itemsTotalCount: number = useMemo(() => {
    return Object.values(categories).flat().length;
  }, [categories]);

  const showCategoriesSlider =
    categoryItems.length > 1 &&
    (isMobileSize
      ? categoryItems.length >= 4 || itemsTotalCount > 20
      : categoryItems.length >= 6 || itemsTotalCount > 25);

  /**
   * Needed to keep 2 separate variables.
   * - One for sending the changes from AutoScrollContainer to HorizontalSlider
   * - and the second one for HorizontalSlider to AutoScrollContainer.
   */
  const [highlightedCategoryIndex, setHighlightedCategoryIndex] =
    useState(null);
  const [intoViewCategoryIndex, setIntoViewCategoryIndex] = useState(null);

  return (
    <StepWrapper
      pageTitle={t("translation:select-products")}
      onBack={() => {
        confirm({
          title: t("mealplan2:order-will-be-canceled"),
          icon: <ExclamationCircleOutlined />,
          content: t("mealplan2:sure-continue"),
          onOk() {
            setCurrentView("overview");
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }}
    >
      {showCategoriesSlider && (
        <div className="sticky top-0 w-full bg-background-primary box-content -ml-6 pl-6 z-30">
          <HorizontalSlider
            items={categoryItems}
            selectedIndex={intoViewCategoryIndex}
            onClick={setHighlightedCategoryIndex}
            classNames="pb-4 pt-2 mt-2 w-full"
          />
        </div>
      )}
      {Object.entries(categories).map(([name, items], index) => {
        if (name === "skjult") return null;

        return (
          <AutoScrollContainer
            className="boundary-element"
            scrollIntoView={highlightedCategoryIndex === index}
            onIntercept={() => setIntoViewCategoryIndex(index)}
            key={name}
          >
            <Category
              name={
                capitalizeFirstLetter(name) ||
                module?.text?.["noCategory/text"] ||
                t("translation:dish-of-the-day")
              }
            >
              <div className="mt-2 flex flex-col z-0">
                {items.map((offerProduct) => {
                  return <Product product={offerProduct} hidePriceInLabel />;
                })}
              </div>
            </Category>
          </AutoScrollContainer>
        );
      })}
    </StepWrapper>
  );
};

export default ProductSelection;
