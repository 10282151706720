import { Timestamp } from "@kanpla/types";
import { isEmpty } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import JumpLink from "./JumpLink";

interface Props {
  defaultDate: Timestamp;
  holidayDesc?: {
    title: string;
    text: string;
    image?: string;
  };
  /** Disables jumping link, e.g. in case of Kanpla Go */
  disableJumpLink?: boolean;
}

const CanteenClosed = ({
  defaultDate,
  holidayDesc,
  disableJumpLink,
}: Props) => {
  const { t } = useTranslation(["translation", "components", "mealplan2"]);

  return (
    <section className="max-w-screen-xl mx-auto pt-20 md:pt-32 lg:pt-48 pb-12 text-center">
      {isEmpty(holidayDesc) && (
        <h2 className="h600 font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
          {t("components:canteen-closed")}
        </h2>
      )}
      {holidayDesc && !isEmpty(holidayDesc) && holidayDesc?.title && (
        <>
          <h2 className="h600 font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
            {holidayDesc?.title || t("components:canteen-closed")}
          </h2>
          {holidayDesc?.text && (
            <p className="text-text-secondary max-w-prose mb-4 mx-auto">
              {holidayDesc?.text}
            </p>
          )}
        </>
      )}

      {!disableJumpLink &&
        (defaultDate?.seconds ? (
          <Trans t={t} i18nKey="mealplan2:if-you-are-hungry">
            Hvis du er sulten kan du allerede nu bestille til
            <JumpLink defaultDate={defaultDate} />!
          </Trans>
        ) : (
          <span>{t("translation:there-are-no-meal-plans")}</span>
        ))}

      <img
        src={`/images/${holidayDesc?.image || "no_mealplan"}.svg`}
        alt=""
        className="w-full mx-auto mt-8 md:mt-6 lg:-mt-20 pointer-events-none"
      />
    </section>
  );
};

export default CanteenClosed;
