import {
  db,
  getTodayTimestamp,
  Timestamp,
  useCollectionListener,
} from "@kanpla/system";
import { SubscriptionPeriod } from "@kanpla/types";
import { groupBy } from "lodash";

const useSubscriptionPeriod = (moduleId?: string) => {
  const today = getTodayTimestamp({ Timestamp });
  const [all = []] = useCollectionListener<SubscriptionPeriod>(
    db
      .collection("subscriptionPeriods")
      .where("moduleId", "==", moduleId || "—")
  );
  const periods = groupBy(
    all.filter((p) => p.to.seconds > today.seconds),
    (period) => period.type
  );
  return periods;
};

export default useSubscriptionPeriod;
