import React, { useState } from "react";
import OrderInfoFields from "../../OrderInfoFields";
import { FormInstance, Button } from "antd";
import { useContainer } from "unstated-next";
import { AppContext } from "apps/frontend/components/contextProvider";
import { Module } from "@kanpla/types";
import useCollapse from "react-collapsed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  form: FormInstance;
  dateSeconds: number;
  module: Module;
}

const OrderInfoItem = (props: Props) => {
  const { basketContainerUtils } = useContainer(AppContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { getCollapseProps } = useCollapse({ isExpanded, collapsedHeight: 50 });
  const { t } = useTranslation(["mealplan2"]);

  const { form, module, dateSeconds } = props;

  return (
    <div className="w-full relative">
      <div {...getCollapseProps()}>
        <OrderInfoFields
          form={form}
          initialValues={Object.entries(
            basketContainerUtils.findTargetBasket(module.id, dateSeconds)
              ?.info || {}
          )}
          extraKey={`.--${module.id}.${dateSeconds}--`}
          moduleFromProps={module}
          dateSeconds={dateSeconds}
        />
      </div>
      {!isExpanded && (
        <div className="w-full h-16 absolute bottom-8 left-0 flex bg-gradient-to-t from-background-primary" />
      )}
      <div className="py-1 flex justify-center w-full">
        <Button
          type="link"
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          {t("mealplan2:toggle-order-info", {
            value: isExpanded
              ? t("mealplan2:values.hide")
              : t("mealplan2:view"),
          })}
          <FontAwesomeIcon
            icon={isExpanded ? faCircleMinus : faCirclePlus}
            className="ml-2"
          />
        </Button>
      </div>
    </div>
  );
};

export default OrderInfoItem;
