import React, { useEffect, useState } from "react";
import moment from "moment";
import { NormalizedPopupData } from "../../lib/useInteractionPopup";
import { Button } from "antd";
import Interaction from "./Interaction";
import { Popup, PopupConstructor } from "@kanpla/types";
import { useLocalstorageState } from "rooks";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  events: NormalizedPopupData[];
  refresh: () => Promise<void>;
}

const PopupNotification = (props: Props) => {
  const { events, refresh } = props;
  const { t } = useTranslation(["translation"]);
  const [open, setOpen] = useState<boolean>(false);
  const [targetPopup, setTargetPopup] = useState<Popup>(null);

  const [_, setSavedPopup] = useLocalstorageState<Popup>("saved-popup", null);

  useEffect(() => {
    if (!open) refresh();
  }, [open]);

  if (isEmpty(events)) return null;

  return (
    <>
      {events.map((data, i) => {
        const canVoteAgain = data?.closedAt && !data?.votedFor;

        return (
          <div
            key={data.id}
            className={`py-0 border-l-4 pl-3 ${
              i === 0 ? "mb-6" : "my-6"
            } pr-2 ${
              canVoteAgain ? "border-primary-main" : "border-divider-main"
            }`}
          >
            <div className="h300 mb-1 font-medium">
              {moment.unix(data.createdAt).format("ll")}
            </div>
            <p className="text-text-primary leading-relaxed font-medium">
              {data.title}
            </p>
            {canVoteAgain ? (
              <Button
                type="primary"
                className="mt-1"
                onClick={() => {
                  setTargetPopup(data.popup);
                  setOpen(true);
                }}
              >
                {t("translation:vote-now")}
              </Button>
            ) : (
              <div className="flex flex-row items-center mt-1">
                {data.type === "image" && data?.image && (
                  <img
                    className="rounded-full w-8 h-8 object-cover mr-2"
                    src={data.image}
                    alt={data.votedFor}
                  />
                )}
                <span className="text-text-primary leading-relaxed">
                  {data.votedFor}
                </span>
              </div>
            )}
          </div>
        );
      })}
      <Interaction
        popup={targetPopup as PopupConstructor.Interaction}
        closePopup={() => setSavedPopup(null)}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default PopupNotification;
