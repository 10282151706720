import { Module, OrderInfo, OrderOrder, Plugins, Product } from "@kanpla/types";
import { Product as ProductTemplate } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import React from "react";
import { useContainer } from "unstated-next";

interface WrapperProps extends Pick<Props, "clickInfo" | "product"> {
  children: JSX.Element;
}

const ProductWrapper = ({ children, clickInfo, product }: WrapperProps) => {
  const { schoolId } = useContainer(AppContext);

  if (clickInfo?.onClick === "nothing") return children;

  const hasModuleId = clickInfo?.moduleId?.includes("/m/");
  if (!hasModuleId) return children;

  const newLink = `${clickInfo?.moduleId}/s/${schoolId}`;

  if (clickInfo?.onClick === "module" && clickInfo?.moduleId)
    return (
      <Link href={newLink}>
        <a className="cursor-pointer">{children}</a>
      </Link>
    );

  if (
    clickInfo?.onClick === "product_module" &&
    clickInfo?.moduleId &&
    product?.id
  )
    return (
      <Link href={`${newLink}?productId=${product.id}`}>
        <a className="cursor-pointer">{children}</a>
      </Link>
    );

  if (
    clickInfo?.onClick === "product_module_day" &&
    clickInfo?.targetDay &&
    clickInfo?.moduleId &&
    product?.id
  )
    return (
      <Link
        href={`${newLink}?productId=${product.id}&date=${clickInfo?.targetDay}`}
      >
        <a className="cursor-pointer">{children}</a>
      </Link>
    );

  return children;
};

interface Props {
  product: Product;
  module: Module;
  clickInfo?: {
    moduleId: string;
    targetDay: number;
    onClick: "nothing" | "module" | "product_module" | "product_module_day";
  };
}

const SingleProduct = ({ product, module, clickInfo }: Props) => {
  const { t } = useTranslation(["translation"]);
  const { defaultReference, user, school, customBranding } =
    useContainer(AppContext);

  const onPurchase = async () => null;

  const plugins = module?.plugins || ({} as Plugins.Array);

  const order = {} as OrderOrder;
  const orderInfo = {} as OrderInfo;

  /** Signup offer plugin */
  const isSignupOfferProduct =
    plugins?.signupOffer?.active &&
    plugins?.signupOffer?.productId === product.id;
  const signupOfferExpired =
    isSignupOfferProduct && user?.pluginData?.signupOffer?.taken === true;
  if (signupOfferExpired) return null;

  return (
    <ProductWrapper clickInfo={clickInfo} product={product}>
      <ProductTemplate
        // isBig={isDish || product?.isLeftover}
        /** @ts-ignore */
        product={product}
        // stock={stock || 0}
        previewOnly
        onPurchase={onPurchase}
        purchaseLabel={t("translation:add-to-basket")}
        fromAdmin
        previewProps={{
          children: <></>,
        }}
        customClassName="w-full"
        schoolId={school?.id}
        plugins={plugins}
        hasRequiredProduct={false}
        order={order}
        orderInfo={orderInfo}
        mealOptions={{}}
        customBranding={customBranding}
        module={module}
        // maxAmount={isSignupOfferProduct ? 1 : stock}
        defaultReference={defaultReference}
      />
    </ProductWrapper>
  );
};

export default SingleProduct;
