/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from "react";
import { capitalize, Dictionary } from "lodash";
import moment from "moment";
import { useContainer } from "unstated-next";
import { CombinedOfferItem } from "@kanpla/types";
import { AppContext } from "../contextProvider";
import DayDetailCategory from "./DayDetailCategory";
import { useCategoriesDictionary } from "./useCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlateUtensils } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";

const DayDetail: FC = () => {
  const { t } = useTranslation(["mealplan2"]);
  const { dayIndex, week } = useContainer(AppContext);
  const categories: Dictionary<CombinedOfferItem[]> = useCategoriesDictionary();
  // current day's date
  const date = week[dayIndex];

  const menuIsEmpty = !Object.values(categories || {}).length;

  return (
    <div
      className="z-10 cursor-pointer shadow-xl p-16 justify-between border rounded-lg w-full bg-main-100 flex-column overflow-hidden bg-background-primary"
      style={{ flex: 3 }}
    >
      <div className="text-primary-main font-medium h500 px-5 md:px-6">
        {capitalize(moment.unix(date.seconds).format("dddd [d.] D/M"))}
      </div>
      {menuIsEmpty && (
        <div className="flex items-center justify-center p-8 flex-col h-full text-text-disabled">
          <FontAwesomeIcon icon={faPlateUtensils} className="mb-4 text-7xl" />
          <p>{t("mealplan2:menu.not-available")}</p>
        </div>
      )}
      {!menuIsEmpty &&
        Object.entries(categories).map(([categoryName, categoryMenus]) => {
          return (
            <DayDetailCategory
              key={categoryName}
              categoryName={categoryName}
              items={categoryMenus}
            />
          );
        })}
    </div>
  );
};

export default DayDetail;
