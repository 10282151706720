import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { Button } from "antd";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import MultiLayers from "./MultiLayers";
import OneLayer from "./OneLayer";
import WeekInputs from "./WeekInputs";

interface Props {
  item: CombinedOfferItem;
  firstColumnStyles: string;
}

const ProductComponent = ({ item, firstColumnStyles }: Props) => {
  const { orders, mealOptions } = useContainer(FlexBulkContext);

  const [expanded, setExpanded] = useState(false);

  const { options = [] } = item;
  const availableOptions = mealOptions.filter(
    (o) => options.includes(o.id) && !o.extension?.includes("hidden")
  );
  const hasOptions = availableOptions.length > 0;
  const hasMultiOptions = availableOptions.length > 1;

  return (
    <div className="border-divider-main border-b border-r">
      <div
        className="flex flex-wrap py-4 border-l-4 px-4 "
        style={{ borderColor: item.color }}
      >
        <div className={`h400 ${firstColumnStyles}`}>
          {item.name}

          {hasOptions && (
            <Button
              className={`ml-3 text-sm text-text-secondary transition-all duration-500`}
              style={{
                transform: expanded ? "rotate(-180deg)" : "rotate(0deg)",
              }}
              shape="circle"
              size="small"
              onClick={() => setExpanded(!expanded)}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          )}
        </div>
        <WeekInputs
          expandProduct={() => setExpanded(true)}
          disabled={hasOptions}
          getValue={(dayIndex: DayIndex) =>
            orders?.[dayIndex]?.[item.id]?.amount || 0
          }
          product={item}
          choice={null}
        />
      </div>
      {expanded &&
        (hasMultiOptions ? (
          <MultiLayers
            availableOptions={availableOptions}
            firstColumnStyles={firstColumnStyles}
            product={item}
          />
        ) : (
          <OneLayer
            availableOptions={availableOptions}
            product={item}
            firstColumnStyles={firstColumnStyles}
          />
        ))}
    </div>
  );
};

export default ProductComponent;
