import { Homescreen } from "@kanpla/types";
import React from "react";
import HTMLReactParser from "react-html-parser";

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Text = ({ singleBlock }: Props) => {
  if (!singleBlock.props?.text) return null;

  return (
    <p className="text-text-primary">
      {HTMLReactParser(singleBlock.props?.text)}
    </p>
  );
};

export default Text;
