import { faArrowLeftLong } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@kanpla/system";
import { Button } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "..";
import { useBasketResult } from "../../useBasketResult";
import { MobileBasket } from "../MobileBasket";
import BasketStep from "./steps/Basket";
import Finalize from "./steps/Finalize";
import ProductSelection from "./steps/ProductSelection";

const Ordering = () => {
  const { openBasket, setOpenBasket } = useContainer(AppContext);
  const { isConfirming, setIsConfirming } = useContext(MealplanMeetingContext);
  const { t } = useTranslation(["mealplan2"]);

  const { width: screenWidth } = useWindowSize();
  const { totalAmount } = useBasketResult();

  const [editMode, setEditMode] = useState(false);

  const commonStyles = `max-w-4xl p-8 lg:p-16`;
  const majorStyles = `w-full md:w-1/2 lg:w-2/3 mx-auto ${commonStyles}`;
  const minorStyles = `md:w-1/2 lg:w-2/5 xl:w-1/3 sticky top-8 h-fit border rounded-lg shadow-sm mt-16 mb-8 hidden md:block p-2 md:p-4 overflow-hidden`;

  useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
    setOpenBasket(false);
  }, [isConfirming]);

  return (
    <>
      <div className="w-full h-full min-h-screen flex border-b">
        {isConfirming ? (
          <>
            <div className="hidden md:block float-left absolute mt-2">
              <Button className="mt-4" onClick={() => setIsConfirming(false)}>
                <FontAwesomeIcon icon={faArrowLeftLong} className="mr-4" />
                {t("mealplan2:order-more-products")}
              </Button>
            </div>
            <div className={minorStyles}>
              <BasketStep
                hideButton
                editMode={editMode}
                setEditMode={setEditMode}
                openModalWithEdit
              />
            </div>
            <div className={majorStyles}>
              <Finalize setEditMode={setEditMode} />
            </div>
          </>
        ) : (
          <>
            <div className={majorStyles}>
              <ProductSelection />
            </div>
            <div className={minorStyles}>
              <BasketStep
                editMode={editMode}
                setEditMode={setEditMode}
                openModalWithEdit
              />
            </div>
          </>
        )}
      </div>
      {screenWidth < 768 && (
        <MobileBasket
          open={
            totalAmount &&
            (!isConfirming || (isConfirming && (openBasket || editMode)))
          }
        />
      )}
    </>
  );
};

export default Ordering;
