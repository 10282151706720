import { faShoppingBasket } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocalStorage } from "@kanpla/system";
import { Badge } from "@kanpla/ui";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  amountOfItems: number;
  shouldHide?: boolean;
  onClick: () => void;
  className: string;
}

interface ButtonProps {
  amountProducts: number;
  onClick: () => void;
}

const BasketButton = ({ amountProducts, onClick }: ButtonProps) => {
  const { t } = useTranslation(["design"]);

  return (
    <Badge count={amountProducts}>
      <Button type="primary" onClick={onClick} data-cy="basket-btn">
        <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />
        {t("design:basket")}
      </Button>
    </Badge>
  );
};

const BasketDisplay = (props: Props) => {
  const { shouldHide = false, amountOfItems, onClick } = props;
  const { t } = useTranslation(["mealplan2"]);

  const [hasSeenButtonBefore, setHasSeenButtonBefore] = useLocalStorage(
    "seen-basket-button-2",
    false
  );

  if (amountOfItems === 0 || shouldHide) return null;

  if (!hasSeenButtonBefore || hasSeenButtonBefore === "undefined")
    return (
      <Tooltip
        visible
        title={t("mealplan2:buttons.add-to-basket-help")}
        placement="bottomRight"
        overlayStyle={{
          transform: "translateX(3px) translateY(-10px)",
        }}
      >
        <BasketButton
          amountProducts={amountOfItems}
          onClick={() => {
            setHasSeenButtonBefore(true);
            onClick();
          }}
        />
      </Tooltip>
    );

  return <BasketButton amountProducts={amountOfItems} onClick={onClick} />;
};

export default BasketDisplay;
