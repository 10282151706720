import { PaymentMethod } from "@kanpla/types";
import { Modal } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NewPayment from "./payment/index";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deficit: number;
  paymentMethod: PaymentMethod;
}

const PleasePay = ({ open, setOpen, deficit, paymentMethod }: Props) => {
  const { t } = useTranslation(["translation", "modals"]);
  const [paymentWindow, setPaymentWindow] = useState(false);
  const { posonePartnerData, isPosone } = useContainer(AppContext);

  // Getting feedback from payment
  const paymentCallback = (value) => {
    if (value === false) {
      setOpen(false);
      setPaymentWindow(false);
    }
  };

  const cancel = () => {
    setOpen(false);
  };

  return (
    <>
      {!paymentWindow && (
        <Modal
          open={open}
          zMax
          setOpen={setOpen}
          // containerClassName="z-max"
          title={t("translation:not-enough-credit")}
          actions={[
            {
              label: t("translation:cancel"),
              onClick: cancel,
              type: "default",
            },
            {
              label: t("modals:refuel-here"),
              onClick: () =>
                isPosone
                  ? window.open(
                      `//${posonePartnerData?.prefix}.smartbetaling.dk/#addfunds`,
                      "_blank"
                    )
                  : setPaymentWindow(true),
              className: "primary",
            },
          ]}
          style={{ zIndex: 100 }}
        >
          <p className="text-text-primary text-center mb-4">
            {t("modals:you-are-missing-kr", { value: deficit })}
          </p>
        </Modal>
      )}
      <NewPayment open={paymentWindow} setOpen={paymentCallback} />
    </>
  );
};

export default PleasePay;
