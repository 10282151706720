import { priceFormatter } from "@kanpla/system";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import PosoneBalance from "./Posone";

interface Props {
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
}

const BalanceDisplay = ({ setPaymentOpen }: Props) => {
  const { t, i18n } = useTranslation(["translation"]);
  const { balance, isPosone, appLoading } = useContainer(AppContext);

  if (isPosone) return <PosoneBalance />;
  if (appLoading)
    return (
      <div className="flex flex-col items-end w-12 mr-1 ml-3">
        <div className="loader w-8 h-3 rounded-sm" />
        <div className="mt-2 loader w-12 h-4 rounded-sm" />
      </div>
    );

  return (
    <button
      className="ml-1 text-sm bg-background-secondary text-primary-main text-center rounded px-2 transition duration-200 py-0.5"
      title={t("translation:insert-money")}
      aria-label={t("translation:insert-money")}
      id="cy-open-credit-modal"
      onClick={() => setPaymentOpen(true)}
    >
      <span className="font-semibold" style={{ fontSize: 13 }}>
        {priceFormatter(balance, i18n)}
      </span>
    </button>
  );
};

export default BalanceDisplay;
