import { getWeekArray, getWeekSeconds, Timestamp } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { useEffect } from "react";
import { AppContext } from "../components/contextProvider";
import { useContainer } from "unstated-next";

const useDeadlineJump = ({ defaultDate }) => {
  const { week, dayIndex, setWeek, setDayIndex } = useContainer(AppContext);
  const defaultDateSeconds = defaultDate?.seconds;

  // Jump forward to first available day
  useEffect(() => {
    const secondsInView = week[dayIndex]?.seconds;
    if (!defaultDateSeconds || defaultDateSeconds < secondsInView) return; // ignore if in the past

    // Set week
    const mondaySeconds = getWeekSeconds(defaultDateSeconds);
    const differentWeek = week[0].seconds !== mondaySeconds;
    if (differentWeek) {
      const targetWeek = getWeekArray(mondaySeconds, Timestamp);
      setWeek(targetWeek);
    }

    // Set dayIndex
    let daysFromMonday = ((defaultDateSeconds - mondaySeconds) /
      86400) as DayIndex;
    if (daysFromMonday > 4) daysFromMonday = 4;
    if (daysFromMonday < 0) daysFromMonday = 0;
    setDayIndex(daysFromMonday);
  }, [defaultDateSeconds]);
};

export default useDeadlineJump;
