import {
  displayDayString,
  isDatePastDeadline,
  isLastItem,
  Timestamp,
} from "@kanpla/system";
import { GroupedCombinedOffer, IBaseProducts } from "@kanpla/types";
import { MenuViewMobile, ProductsList } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { AppContext } from "../../contextProvider";

interface Props {
  selectedProducts?: IBaseProducts;
}

const MenuView = ({ selectedProducts }: Props) => {
  const { t } = useTranslation(["translation"]);

  const {
    groupedItems,
    isBulk,
    getCurrentProducts,
    items,
    selectProductOpen,
    setSelectedProduct,
    setOpenProductInfos,
    setOpenVariants,
    setDayDateSeconds,
    selectProductByDay,
    onPurchase,
    submit,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    weekProducts,
  } = useContainer(FlexContext);
  const { mobile, week, module, schoolId, supplier } = useContainer(AppContext);

  const { hidePrices } = module?.config || {};

  if (mobile)
    return (
      <MenuViewMobile
        getCurrentProducts={getCurrentProducts}
        selectedProducts={selectedProducts}
        plainProducts={items}
        week={week}
        deadline={deadline}
        deadlineWeekRelative={deadlineWeekRelative}
        deadlineExcludingWeekends={deadlineExcludingWeekends}
        selectProductOpen={selectProductOpen}
      />
    );

  return (
    <div>
      {Object.entries((groupedItems as GroupedCombinedOffer) || {}).map(
        ([date, categories], index) => {
          const day = displayDayString({
            dateSeconds: date,
            todayString: t("translation:today"),
          });

          const isLastDay = isLastItem({
            array: Object.keys(groupedItems),
            index,
          });

          const isPast = isDatePastDeadline({
            date: new Timestamp(Number(date), 0),
            deadline,
            deadlineWeekRelative,
            deadlineExcludingWeekends,
          });

          return (
            <div className={`${isLastDay ? "" : "mb-8"}`}>
              <h3 className="h500 mb-2">{day}</h3>

              <ProductsList
                categories={categories}
                isBulk={isBulk}
                date={date}
                selectedProducts={weekProducts}
                weekProducts={weekProducts}
                hidePrices={hidePrices}
                schoolId={schoolId}
                setSelectedProduct={setSelectedProduct}
                setOpenProductInfos={setOpenProductInfos}
                setOpenVariants={setOpenVariants}
                setDayDateSeconds={setDayDateSeconds}
                selectProductByDay={selectProductByDay}
                onPurchase={onPurchase}
                submit={submit}
                isPast={isPast}
                supplier={supplier}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default MenuView;
