import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onClick?: () => void;
  label: string;
  className?: string;
  icon?: IconProp;
  id?: string;
  disabled?: boolean;
  danger?: boolean;
  dataCy?: string;
}

const ButtonLine = ({
  onClick = () => null,
  label,
  className = "",
  icon = null,
  id = null,
  disabled = false,
  dataCy = "",
  danger,
}: Props) => {
  return (
    <button
      disabled={disabled}
      id={id && id}
      className={`py-4 px-4 block w-full text-left border-b transition duration-200 bg-background-primary border-divider-main ${
        disabled
          ? "disabled:cursor-not-allowed text-text-disabled"
          : danger
          ? "hover:bg-danger-light hover:bg-opacity-10 active:bg-opacity-20 text-danger-main"
          : "hover:bg-background-secondary hover:bg-opacity-50 active:bg-opacity-100 text-text-primary"
      } ${className}`}
      onClick={onClick}
      data-cy={dataCy}
    >
      {icon && <FontAwesomeIcon icon={icon} className="mr-4 ml-1 w-6" />}
      {label}
    </button>
  );
};

export default ButtonLine;
