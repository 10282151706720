import { faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const ShowReceiptButton = () => {
  const { t } = useTranslation(["mealplan2"]);

  return (
    <button
      className="text-sm bg-background-secondary border border-dashed rounded-md border-text-disabled py-1 mt-2"
      data-cy="show-receipt-history-button"
    >
      <FontAwesomeIcon icon={faReceipt} className="mr-2" />
      {t("mealplan2:show-receipt")}
    </button>
  );
};

export default ShowReceiptButton;
