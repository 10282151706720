import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { PopupConstructor } from "@kanpla/types";
import { Modal as UIModal } from "@kanpla/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { Button, message } from "antd";
import { fn } from "@kanpla/system";
import { AppContext } from "../contextProvider";
import { useContainer } from "unstated-next";
import { useTranslation } from "react-i18next";

interface Props {
  popup: PopupConstructor.Interaction;
  closePopup: () => void;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const Interaction = ({ popup, closePopup, open = true, setOpen }: Props) => {
  const { t } = useTranslation(["translation"]);
  const { childId, schoolId, setShowPopupInteractionAnimation } =
    useContainer(AppContext);

  const [_open, _setOpen] = useState<boolean>(open);
  const [choice, setChoice] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const openedFromNotifications = typeof setOpen === "function";

  useEffect(() => {
    if (!_open) {
      setTimeout(closePopup, 500);
    }
  }, [_open]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const [title, text, type, options] = popup?.blocks || [];
  const hasImages = type?.selected === "image";

  const sizes = {
    h1: "h700",
    h2: "h600",
    h3: "h500",
    h4: "h400",
  };

  const submit = async ({
    hasSkippedVote = false,
  }: {
    hasSkippedVote?: boolean;
  }) => {
    try {
      if (hasSkippedVote) {
        // Close popup immediatly, prevent the user from waiting
        openedFromNotifications ? setOpen(false) : _setOpen(false);
        !openedFromNotifications && setShowPopupInteractionAnimation(true);
      }

      !hasSkippedVote && setLoading(true);

      if (!hasSkippedVote && !choice)
        throw new Error(t("translation:select-an-option"));

      const castPopupVote = fn.httpsCallable("popups-castVote");
      await castPopupVote({
        childId,
        schoolId,
        choiceId: choice,
        popupId: popup.id,
        hasSkippedVote,
      });

      if (!hasSkippedVote) {
        message.success(t("translation:message.success.thanks-vote"));
      }

      openedFromNotifications ? setOpen(false) : _setOpen(false);
    } catch (err) {
      console.error(err);
      message.error(err?.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UIModal
      open={_open}
      containerClassName="flex items-center"
      className="overflow-hidden"
      setOpen={() =>
        openedFromNotifications ? setOpen(false) : _setOpen(false)
      }
      closableOutside={false}
    >
      {/* Close button */}
      <button
        aria-label={t("translation:close-banner")}
        className="absolute z-max right-3 top-3 p-1 px-2 rounded-full bg-primary-main hover:bg-primary-main transition-colors flex items-center justify-center w-10 h-10 shadow-lg"
        onClick={() => submit({ hasSkippedVote: true })}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-background-primary text-lg"
        />
      </button>

      <div className="w-full">
        {/* Title */}
        <p
          className={`${sizes[title?.size || "h2"]}`}
          style={{ maxWidth: "370px" }}
        >
          {title?.text || t("translation:questions")}
        </p>

        {/* Subtitle */}
        {text && <p>{text.text}</p>}

        {/* All available options */}
        <div className="flex flex-wrap gap-4 mt-6">
          {(options?.values || []).map((o) => {
            const selected = choice === o.key;
            return (
              <div
                key={o.key}
                className={`${
                  hasImages ? "h-32" : "h-24"
                } w-full overflow-hidden relative rounded-md ${
                  hasImages
                    ? ""
                    : `border ${
                        selected
                          ? "border-primary-main"
                          : "border-secondary-dark"
                      }`
                }`}
                style={{ flex: "1 0 33%" }}
                onClick={() => {
                  if (selected) {
                    setChoice("");
                  } else {
                    setChoice(o.key);
                  }
                }}
              >
                <div
                  className={`w-full h-full bg-white cursor-pointer hover:scale-110 transition-all ease-in-out`}
                  style={{
                    backgroundImage: `url(${hasImages ? o?.image : null})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <div
                    className={`w-full h-full flex justify-center items-center p-6 text-center font-semibold ${
                      hasImages ? "text-white" : ""
                    }`}
                    style={{
                      backgroundColor:
                        selected && hasImages
                          ? `rgba(6, 120, 69, 0.5)`
                          : `${hasImages ? "rgba(0, 0, 0, 0.3)" : "#FFFFFF"}`,
                    }}
                  >
                    {selected && hasImages ? (
                      <FontAwesomeIcon icon={faCircleCheck} size="lg" />
                    ) : (
                      o?.text
                    )}
                  </div>
                </div>
                {!hasImages && selected && (
                  <div className="absolute flex justify-center rounded-tl-md rounded-br-md items-center px-4 py-2 h-auto -top-1 -left-1 bg-primary-main text-primary-contrast">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Button
        type="primary"
        loading={loading}
        className="mt-6 w-full"
        onClick={() => submit({ hasSkippedVote: false })}
      >
        {t("translation:confirm")}
      </Button>
    </UIModal>
  );
};

export default Interaction;
