import { Alert } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";

const PaidFromAlert = () => {
  const { module, balance, posoneBalance } = useContainer(AppContext);
  const { t } = useTranslation(["mealplan2"]);

  return (
    <Alert
      showIcon
      message={
        module.paymentMethod === "billing"
          ? t("mealplan2:order-invoiced")
          : module.paymentMethod === "credit"
          ? t("mealplan2:paid-from", { value: Math.round(balance * 100) / 100 })
          : t("mealplan2:paid-from-posone", {
              value: Math.round(posoneBalance * 100) / 100,
            })
      }
    />
  );
};

export default PaidFromAlert;
