import { Homescreen } from "@kanpla/types";
import React, { useContext } from "react";
import { HomescreenContext } from "..";
import serializer from "../utils/serializer";

const Root = ({ singleBlock }: { singleBlock: Homescreen.ContentInner }) => {
  const { content } = useContext(HomescreenContext);

  const editorNodes = singleBlock.nodes;

  return (
    <div className="w-full max-w-7xl wrapper mt-4">
      {editorNodes.map((nodeId) => {
        const node = content[nodeId];

        return serializer(node, nodeId);
      })}
    </div>
  );
};

export default Root;
