import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUrlRelative } from "@kanpla/system";
import { Homescreen, Module } from "@kanpla/types";
import { AppContext } from "apps/frontend/components/contextProvider";
import { redirectToModule } from "apps/frontend/lib/router";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { FlexMenuContent } from "../FlexMenu";
import BannerFive from "./Five";
import BannerFour from "./Four";
import BannerOne from "./One";
import BannerSix from "./Six";
import BannerThree from "./Three";
import BannerTwo from "./Two";

interface Props {
  singleBlock: Homescreen.ContentInner;
  index?: string;
}

const BannerInner = ({ singleBlock }: Props) => {
  const {
    type,
    title,
    topTitle,
    subTitle,
    imageUrl,
    link,
    linkText,
    moduleId,
    productId,
    overlayOpacity = 70,
  } = singleBlock.props;

  const doc = new DOMParser().parseFromString(title, "text/html");
  const escapedTitle = doc.documentElement.textContent;

  if (type === 1)
    return (
      <BannerOne
        overlayOpacity={overlayOpacity}
        title={escapedTitle}
        imageUrl={imageUrl}
      />
    );

  if (type === 2)
    return (
      <BannerTwo
        title={escapedTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 3)
    return (
      <BannerThree
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 4)
    return (
      <BannerFour
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        topTitle={topTitle}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 5)
    return (
      <BannerFive
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
      />
    );

  if (type === 6)
    return (
      <BannerSix
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === "flexMenu")
    return (
      <FlexMenuContent productId={productId} moduleId={moduleId} link={link} />
    );

  return null;
};

const Banner = ({ singleBlock, index }: Props) => {
  const router = useRouter();

  const [closedBanners, setClosedBanners] = useLocalstorageState<Array<string>>(
    "homescreen-banners-closed"
  );

  const { schoolId, modules } = useContainer(AppContext);

  if (!singleBlock || !singleBlock.props) return null;

  const { link, closeable } = singleBlock.props;

  const CloseableButton = () =>
    closeable ? (
      <button
        className="absolute right-3 top-3 z-40 w-8 h-8 rounded-full bg-transparent hover:bg-background-secondary text-background-primary hover:text-text-primary transition-colors"
        onClick={() => setClosedBanners((b = []) => [...b, index])}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    ) : null;

  if (closeable && closedBanners?.includes(index)) return null;

  if (!link || link === "")
    return (
      <div className="relative z-0 h-full">
        <CloseableButton />
        <BannerInner singleBlock={singleBlock} />
      </div>
    );

  if (link && link !== "") {
    /** Outer links */
    if (!isUrlRelative(link))
      return (
        <div className="relative z-0 h-full">
          <CloseableButton />
          <a
            className="cursor-pointer text-current relative no-underline focus:no-underline h-full block"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <BannerInner singleBlock={singleBlock} />
          </a>
        </div>
      );

    const hasModuleId = link.includes("/m/");
    const moduleId = () => {
      const newLink = link.split("/") as Array<string>;
      const mIndex = newLink.findIndex((i) => i === "m");
      return newLink[mIndex + 1];
    };
    const linkModule =
      modules.find((m) => m.id === moduleId()) || ({} as Module);

    const newLink = hasModuleId ? `${link}/s/${schoolId}` : link;

    /** Inner links */
    if (
      !hasModuleId ||
      linkModule?.scope?.generatedSchoolIds?.includes(schoolId)
    )
      return (
        <div className="relative z-0 h-full">
          <CloseableButton />
          <p
            onClick={() => redirectToModule({ router, link: newLink })}
            className="cursor-pointer text-current relative h-full block"
          >
            <BannerInner singleBlock={singleBlock} />
          </p>
        </div>
      );
  }

  return (
    <div className="relative z-0 h-full">
      <CloseableButton />
      <BannerInner singleBlock={singleBlock} />
    </div>
  );
};

export default Banner;
