import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { Item as ItemType } from "./../../UseHistory";
import PaymentDescription from "./PaymentDescription";
import ShowReceiptButton from "./ShowReceiptButton";

interface Props {
  item: ItemType;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const BigItem = ({ item, setOpen }: Props) => {
  const hasDetails = item.order;

  return (
    <div
      className={`flex flex-col w-full bg-background-secondary rounded-lg my-1.5 px-3 pb-3 pt-3 text-text-primary ${
        hasDetails && "cursor-pointer"
      }`}
      onClick={() => {
        if (hasDetails) setOpen(true);
      }}
    >
      <div className="flex w-full items-center">
        <div>
          <div
            className={`${item.order?.refundIds?.length ? "line-through" : ""}`}
          >
            {item?.order?.displayName
              ? `${item.order.displayName.trim()}, `
              : ""}
            {item.title}, {moment.unix(item.dateSeconds).format("DD. MMM YYYY")}
          </div>
          <div className="text-xs text-text-secondary">
            {item.salesplaceName}
          </div>
          <PaymentDescription item={item} />
        </div>
        <div className="bg-background-secondary rounded-lg w-12 h-12 flex items-center justify-center ml-auto">
          <FontAwesomeIcon icon={item.icon} className="text-xl" />
        </div>
      </div>
      {hasDetails && <ShowReceiptButton />}
    </div>
  );
};

export default BigItem;
