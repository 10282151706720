import { NewLogo } from "@kanpla/ui";
import Helpcenter from "./Helpcenter";

const FooterKanpla = () => {
  return (
    <div className="px-5 pb-3 md:py-4 md:px-6 ">
      <Helpcenter />
      <div className="flex flex-col justify-between items-center">
        {/* Logo */}
        <div className="text-primary-main pb-2 text-center">
          <NewLogo />
        </div>
      </div>
    </div>
  );
};

export default FooterKanpla;
