import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const Header = () => {
  const { t } = useTranslation(["mealplan2"]);
  const { module } = useContainer(AppContext);

  return (
    <div className="text-center mt-12 mb-8">
      <div className="h600 text-text-secondary">
        {module?.text?.["description/title"] ||
          t("mealplan2:registration.it-is-lunchtime")}
      </div>
      <div className="text-sm text-text-secondary">
        {module?.text?.["description/text"] ||
          t("mealplan2:registration.how-to")}
      </div>
    </div>
  );
};

export default Header;
