import { Timestamp as TimestampType } from "@kanpla/types";
import { Modal } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import UIJumpLink from "./JumpLink";

// Wraps the offer and determines if it should be displayed or not.
// Otherwise, shows a link to the next available date.

interface Props {
  children: any; // everything that should hide if after deadline
  pastDate: boolean; // is the current day in view after deadline?
  defaultDate: TimestampType; // fist available date to buy
  showChildrenAnyway?: boolean; // show mealplan even after deadline
  /** Function to tell the parent the extra modal is open, used for non-grayscale menu in overlay */
  setOpenModal?: (open: boolean) => void;
}

interface BannerProps extends Partial<Props> {
  openMealplan?: boolean;
  setOpenMealplan?: Dispatch<SetStateAction<boolean>>;
}

export const PastDateBanner = ({
  defaultDate,
  children,
  showChildrenAnyway,
  setOpenMealplan,
  openMealplan,
}: BannerProps) => {
  const { t } = useTranslation(["components", "translation"]);
  return (
    <>
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center relative">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8 relative z-10"
        >
          <span style={{ fontSize: 120 }} role="img">
            ⌛️
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary h800">
            {t("components:too-late-to-book-for-this-day")}
          </h2>
          {defaultDate ? (
            <p>
              {t("components:however")} <UIJumpLink defaultDate={defaultDate} />
            </p>
          ) : (
            t("translation:there-are-no-meal-plans")
          )}
        </div>
        <img
          src="/images/no_mealplan.svg"
          alt=""
          className="w-full mx-auto mt-8 md:mt-6 lg:-mt-20 pointer-events-none absolute bottom-0"
        />
      </section>
      {showChildrenAnyway && (
        <Modal
          open={openMealplan}
          setOpen={setOpenMealplan}
          style={{
            width: "80%",
            maxWidth: "unset",
            pointerEvents: openMealplan ? "auto" : "none",
          }}
          title={t("components:modal.titles.browse-today-menu")}
        >
          {children}
        </Modal>
      )}
    </>
  );
};

const PastDateWrapper = (props: Props) => {
  const { children, pastDate, defaultDate, setOpenModal } = props;
  const [openMealplan, setOpenMealplan] = useState(false);

  useEffect(() => {
    setOpenModal && setOpenModal(openMealplan);
  }, [openMealplan]);

  if (pastDate)
    return (
      <PastDateBanner
        pastDate={pastDate}
        defaultDate={defaultDate}
        openMealplan={openMealplan}
        setOpenMealplan={setOpenMealplan}
      >
        {children}
      </PastDateBanner>
    );

  return children;
};

export default PastDateWrapper;
