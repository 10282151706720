import { db, fb } from "@kanpla/system";
import { DrawerOrModal } from "@kanpla/ui";
import { Form, Input, message } from "antd";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ChangeEmail = ({ open, setOpen }) => {
  const { t } = useTranslation(["translation", "modals"]);
  const { auth } = useContainer(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const verifyEmail = fb.functions("europe-west1").httpsCallable("verifyEmail");

  const updateEmailInUserDoc = async (email: string) => {
    const userDoc = db.collection("users").doc(auth.user.uid);
    return await userDoc.update({ email: email });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password } = values;

    try {
      if (email === auth.user.email) {
        message.error(t("modals:message.error.already-using-this-address"));
        return;
      }

      const credential = EmailAuthProvider.credential(
        auth.user.email,
        password
      );

      await reauthenticateWithCredential(auth.user, credential);
      await updateEmail(auth.user, email);
      await verifyEmail({ email });
      await updateEmailInUserDoc(email);

      form.resetFields();

      message.success(t("modals:message.success.email-modified"));
      setOpen(false);
    } catch (err) {
      if (err?.code === "auth/wrong-password") {
        message.error(t("modals:message.error.old-password-incorrect"));
        return;
      }
      if (err?.code === "auth/email-already-in-use") {
        message.error(t("modals:message.error.email-already-exist"));
        return;
      }
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("modals:modal.titles.change-email")}
      subtitle={
        <Trans t={t} i18nKey="modals:current-email-address">
          Din nuværende e-mail adresse er
          <b>{{ value: auth.user && auth.user.email }}</b>. Vil du ændre det?
        </Trans>
      }
      actions={[
        {
          label: t("modals:modal.titles.change-email"),
          onClick: () => form.submit(),
          className: "primary",
          loading,
          dataCy: "change-email-button",
        },
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={t("modals:new-email")}
          id="email"
          name="email"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("modals:new-email")} autoComplete="off" />
        </Form.Item>

        <Form.Item
          label={t("modals:confirm-email")}
          id="emailConfirm"
          name="emailConfirm"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("email") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("modals:form.errors.emails-are-not-the-same"))
                );
              },
            }),
          ]}
        >
          <Input placeholder={t("modals:confirm-email")} autoComplete="off" />
        </Form.Item>

        <Form.Item
          label={t("translation:password")}
          id="password"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            placeholder={t("translation:password")}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangeEmail;
