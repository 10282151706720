import {
  calculateOrderTotal,
  getOrderConfigs,
  priceFormatter,
} from "@kanpla/system";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { Item as ItemType } from "./../../UseHistory";

interface Props {
  item: ItemType;
}

const PaymentDescription = ({ item }: Props) => {
  const { t, i18n } = useTranslation(["translation", "mealplan2"]);

  const configs = getOrderConfigs(item.order?.order || {});
  const orderTotal = calculateOrderTotal(item.order?.order || {});

  const hasDetails = item.order;

  const isRefunded = orderTotal === 0 && !configs.length && hasDetails;

  const { hidePrices } = item.module?.config || {};

  if (isRefunded)
    return (
      <div className="text-sm mt-2 text-text-secondary">
        {t("mealplan2:this-order-is-refunded")}
      </div>
    );

  return (
    <div className="text-sm mt-2 text-text-secondary">
      {priceFormatter(
        item.paymentAmount ?? "-",
        { language: i18n.language },
        false,
        hidePrices,
        {
          wrapper: () => <span className="text-primary-dark">-</span>,
          component: () => <TooltipHiddenPrices color="text-primary-dark" />,
        }
      )}
      {item.paymentDescription ? `, ${item.paymentDescription}` : ""}
    </div>
  );
};

export default PaymentDescription;
