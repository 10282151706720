import {
  faFileMagnifyingGlass,
  faPrint,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import {
  db,
  getModuleList,
  getProductsPerSchool,
  hasAccessToModule,
  sortProducts,
  useCollectionListener,
} from "@kanpla/system";
import { Module, ProductBank } from "@kanpla/types";
import { Print as PrintWrapper } from "@kanpla/ui";
import getMenus from "apps/frontend/lib/flex/getMenus";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import MultipleModulePrint from "../flex/MultipleModulePrint";
import EmployeesOrders from "../modals/EmployeesOrders";
import ButtonLine from "../settings/UserInfo/ButtonLine";

interface Props {
  availableModules: Array<Module>;
}

const AdminOverview = (props: Props) => {
  const { t } = useTranslation(["flex-bulk", "translation"]);
  const { modules, partnerId, school, child, week, module } =
    useContainer(AppContext);

  const [productBank = []] = useCollectionListener<ProductBank>(
    partnerId
      ? db
          .collection("productBanks")
          .where("partnerId", "==", partnerId)
          .limit(1)
      : null
  );

  // Offering
  const products = getProductsPerSchool({
    schoolId: school?.id,
    productBank: productBank[0],
    module,
    db,
  });
  const { menus: allMenus } = getMenus({ products });
  const menus = allMenus.filter((m) => m);

  const [openOrdersHistory, setOpenOrdersHistory] = useState(false);

  const moduleTrigger = sortBy(props?.availableModules, "name")
    ?.filter((m) => m.type === "flex")
    ?.map((m) => m.id)
    .join("-");

  const [access, setAccess] = useState<{
    hasBulk: boolean;
    hasIndividual: boolean;
  }>({
    hasBulk: false,
    hasIndividual: false,
  });

  useEffect(() => {
    let hasBulk = false,
      hasIndividual = false;

    props.availableModules.map((m) => {
      const { bulk, individual } = hasAccessToModule({
        module: m,
        child,
        school,
      });

      if (bulk) hasBulk = true;
      if (individual) hasIndividual = true;

      return;
    });

    setAccess({
      hasBulk,
      hasIndividual,
    });
  }, [moduleTrigger]);

  if (!access?.hasBulk && !access?.hasIndividual) return null;
  if (!week && !products && !menus) return null;

  const orderedProducts = sortProducts({
    items: products,
    list: getModuleList({ module }),
  });

  const flexModules = modules.filter((m) => m.type === "flex");

  return (
    <div>
      <h2 className="h500 border-b-2 border-divider-main pb-2">
        {access?.hasBulk
          ? t("flex-bulk:admin-overview")
          : t("flex-bulk:breakfast-arrangement")}
      </h2>

      {access?.hasBulk ? (
        <ButtonLine
          onClick={() => setOpenOrdersHistory(true)}
          label={t("translation:employee-orders")}
          icon={faUsers}
        />
      ) : null}

      {flexModules?.length > 1 ? (
        <MultipleModulePrint productBanks={productBank} />
      ) : null}

      {flexModules?.length === 1 ? (
        <>
          <PrintWrapper menus={menus} products={orderedProducts} week={week}>
            <ButtonLine
              label={t("translation:print-week-menu")}
              icon={faPrint}
            />
          </PrintWrapper>
          <PrintWrapper
            menus={menus}
            products={orderedProducts}
            week={week}
            isDigital
          >
            <ButtonLine
              label={t("translation:see-week-menu")}
              icon={faFileMagnifyingGlass}
            />
          </PrintWrapper>
        </>
      ) : null}

      <EmployeesOrders
        open={openOrdersHistory}
        setOpen={setOpenOrdersHistory}
      />
    </div>
  );
};

export default AdminOverview;
