import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import AddChild from "../forms/addChild";

const NewKid = () => {
  const { t } = useTranslation(["modals"]);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        type="default"
        className="w-full my-3"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        {t("modals:add-another-user")}
      </Button>
      <DrawerOrModal open={open} setOpen={setOpen} title={t("modals:add-user")}>
        <AddChild callback={() => setOpen(false)} />
      </DrawerOrModal>
    </>
  );
};

export default NewKid;
