import { CombinedOfferItem, FlexOption, OrderConfig } from "@kanpla/types";
import React from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import WeekInputs from "./WeekInputs";

interface Props {
  availableOptions: FlexOption[];
  product: CombinedOfferItem;
  firstColumnStyles: string;
}

const OneLayer = (props: Props) => {
  const { availableOptions, product, firstColumnStyles } = props;
  const { orders } = useContainer(FlexBulkContext);
  const optionLayer: FlexOption = availableOptions[0];

  const choices = optionLayer?.choices || [];

  return (
    <div>
      {choices.map((choice) => {
        const isTargetConfig = (c: OrderConfig) =>
          c.options?.[choice.id]?.amount > 0;

        return (
          <div className="flex flex-wrap border-t py-2" key={choice.id}>
            <div className={`${firstColumnStyles}`}>{choice.name}</div>
            <WeekInputs
              getValue={(dayIndex) => {
                const config = orders?.[dayIndex]?.[product.id]?.config || [];

                const amount =
                  config.find((c) => isTargetConfig(c))?.amount || 0;

                return amount;
              }}
              product={product}
              choice={{
                [choice.id]: {
                  name: choice.name,
                  extraPrice: choice.price,
                  amount: 1,
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default OneLayer;
