import {
  callFunction,
  db,
  fetchCollection,
  fetchMultipleDocuments,
  KanplaError,
} from "@kanpla/system";
import { Child, School, Scope, SignupDomain } from "@kanpla/types";
import { ButtonAdd, DrawerOrModal, SelectorsInput, Spinner } from "@kanpla/ui";
import { Divider, message } from "antd";
import {
  getSchool,
  getSchoolsOrProductBanksByCode,
} from "apps/frontend/lib/utils";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import SelectSchool from "../forms/SelectSchool";

const NewSalesplace = () => {
  const {
    customBranding,
    partnerId: appContextPartnerId,
    children,
    isChildSalesplace,
    userId,
    triggerReload,
  } = useContainer(AppContext);

  const { t } = useTranslation(["modals", "translation", "register"]);

  const [school, setSchool] = useState<School>(null);
  const [schoolFromCanteenId, setSchoolFromCanteenId] = useState<School>(null);
  const [schools, setSchools] = useState<School[]>([]);
  const [open, setOpen] = useState(false);
  const [selectors, setSelectors] = useState<Child["selectors"]>({});
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mainChildScope, setMainChildScope] = useState<Scope>(null);

  const salesplacesAlreadyAdded = children.map((child) => child.schoolId);

  const getCompanySchools = async () => {
    try {
      // 1. Get signup domains of the partner
      const signupDomains = await fetchCollection<SignupDomain>(
        db
          .collection("signupDomains")
          .where("partnerId", "==", appContextPartnerId)
      );

      // 2. Find same domains (will be displayed in the school selection)
      const multipleDomain = signupDomains.find((domain, index) => {
        return (
          signupDomains.findIndex((dom) => dom.domain === domain.domain) !==
          index
        );
      });

      if (isEmpty(multipleDomain)) {
        setSchools([]);
        return;
      }

      const schoolsIds = signupDomains
        .filter((dom) => dom.domain === multipleDomain.domain)
        .flatMap((domain) => domain.schoolId)
        .filter((schoolId) => !salesplacesAlreadyAdded.includes(schoolId));

      if (isEmpty(schoolsIds)) {
        setSchools([]);
        return;
      }

      const getSchools = await fetchMultipleDocuments<School>(
        "schools",
        schoolsIds
      );

      setSchools(getSchools);
    } catch (err) {
      console.error(err);
    }
  };

  /** Fallback if the school from code doesn't exist */
  const targetSchool = schoolFromCanteenId ? schoolFromCanteenId : school;

  const submit = async () => {
    try {
      setLoading(true);

      if (targetSchool?.selectors && isEmpty(selectors)) {
        message.error({
          key: "error-choose-selector",
          content: t("register:message.error.choose-selector"),
        });
        throw new Error(t("register:message.error.choose-selector"));
      }

      await callFunction("signup-createChild", {
        name: targetSchool?.name || "",
        schoolId: targetSchool?.id,
        selectors: selectors || {},
        userId,
        codes: [],
        isChildSalesplace,
        scope: mainChildScope,
      });

      setOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const submitCanteenCode = async () => {
    try {
      setLoading(true);

      if (!code)
        throw new KanplaError("kanpla/fill-canteen-id", "Fill canteen ID out!");

      const partnerId =
        customBranding?.partnerId || appContextPartnerId || null;

      const { allSchools, allProductBanks } =
        await getSchoolsOrProductBanksByCode({
          partnerId,
          code,
        });

      const targetEntity = (
        allSchools.length ? allSchools : allProductBanks
      )?.[0];

      if (
        children.find(
          (c) =>
            c.schoolId ===
            (targetEntity?.["defaultSignupSchoolId"] || targetEntity?.id)
        )
      )
        throw new Error(t("modals:message.error.child-has-location-already"));

      if (targetEntity) {
        /** Find scope of the main child */
        const mainChild = children.find(
          // Fallback to if the child doesn't have a mainChild property
          (child) => child.isMainChild || !child.copyFromChildId
        );

        /** Destructure properties */
        const { scope: mainChildScope = null } = mainChild || {};

        /** Find school ids of the current children (fallback if the scope is not present) */
        const schoolIds = [...new Set(children.map((child) => child.schoolId))];

        /** Create merged scope */
        const newScope = await callFunction("salesplaces-setScopeByMainChild", {
          partnerId,
          scope: mainChildScope,
          schoolIds,
          ids: [targetEntity.id],
        });

        setMainChildScope(newScope);

        await db
          .collection("children")
          .doc(mainChild?.id)
          .set({ scope: newScope, isMainChild: true }, { merge: true });

        /** Get current school through a product bank or a school  */
        const targetSchool = await getSchool({
          id: targetEntity["defaultSignupSchoolId"] || targetEntity.id,
        });
        setSchoolFromCanteenId(targetSchool || null);
      } else {
        throw new KanplaError("kanpla/wrong-canteen-id", "Wrong canteen ID");
      }

      triggerReload();
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSchool(null);
    setSchoolFromCanteenId(null);
    setCode("");
  }, [open]);

  useEffect(() => {
    if (code.length < 4) return;
    submitCanteenCode();
  }, [code]);

  useEffect(() => {
    getCompanySchools();
  }, [children.length]);

  return (
    <>
      <ButtonAdd
        label={t("modals:add-another-location") as string}
        onClick={() => setOpen(true)}
        className="w-full mt-2"
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("modals:add-another-location") as string}
        actions={
          // !isEmpty(targetSchool?.selectors)
          targetSchool?.id &&
          (isEmpty(targetSchool?.selectors) || !isEmpty(selectors))
            ? [
                {
                  label: t("translation:confirm"),
                  onClick: submit,
                  type: "primary",
                  loading,
                  disabled: !school && !schoolFromCanteenId,
                },
              ]
            : []
        }
      >
        {!isEmpty(schools) && (
          <>
            <label htmlFor="school-input">
              <label className="form-label text-text-secondary mb-1">
                {t("modals:add-salesplaces-from-email")}
              </label>
              <div className="text-left mt-2 mb-6">
                <SelectSchool
                  school={school}
                  schools={schools}
                  setSchool={setSchool}
                  company={!isEmpty(customBranding?.logo)}
                />
              </div>
            </label>

            <SelectorsInput
              selectors={selectors}
              setSelectors={setSelectors}
              school={school}
            />

            <div className="relative my-2">
              <Divider />
              <div className="absolute -top-3 left-1/2 -ml-6 text-sm p-1 px-4 bg-background-primary text-text-secondary">
                {t("translation:or")}
              </div>
            </div>
          </>
        )}

        <label className="form-label text-text-secondary mb-1">
          {t("modals:add-salesplaces-from-canteen-id")}
        </label>
        <div className="flex justify-center mb-4">
          {loading && isEmpty(targetSchool?.selectors) ? (
            <div className="mt-4">
              <Spinner size="medium" useCurrentColor />
            </div>
          ) : (
            <ReactCodeInput
              type="number"
              className="w-full grid grid-cols-4"
              inputStyle={{
                fontFamily: "inherit",
                padding: 12,
                border: "1px solid",
                borderRadius: 6,
                margin: 6,
                textAlign: "center",
                fontSize: 22,
                fontWeight: "600",
              }}
              style={{
                display: "grid !important",
              }}
              value={code}
              onChange={(newValue) => {
                setCode(newValue);
              }}
              fields={4}
              name="emailCode"
              inputMode="numeric"
            />
          )}
        </div>
        <SelectorsInput
          selectors={selectors}
          setSelectors={setSelectors}
          school={schoolFromCanteenId}
        />
      </DrawerOrModal>
    </>
  );
};

export default NewSalesplace;
