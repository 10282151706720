/* eslint-disable react-hooks/rules-of-hooks */
import { PlusOutlined } from "@ant-design/icons";
import { CombinedOfferItem, DayIndex, Timestamp } from "@kanpla/types";
import classnames from "classnames";
import { capitalize, Dictionary, get, isEmpty } from "lodash";
import moment from "moment";
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { scroll2Elem } from "@kanpla/system";
import { AppContext } from "../contextProvider";
import DayDetailCategory from "./DayDetailCategory";
import { MenuPreviewContext } from "./MenuPreviewContext";
import Product from "./Product";
import { useCategoriesDictionary } from "./useCategories";
import { isMobile } from "react-device-detect";

interface Props {
  date: Timestamp;
  dayIndex: DayIndex;
}

const Day = ({ date, dayIndex }: Props) => {
  const { i18n, t } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { highlightedItems } = useContainer(MenuPreviewContext);
  const {
    setDayIndex,
    dayIndex: selectedDayIndex,
    innerAppLoading,
    schoolId,
    week,
  } = useContainer(AppContext);

  const categories: Dictionary<CombinedOfferItem[]> =
    useCategoriesDictionary(dayIndex);
  const menuIsEmpty = useMemo(
    () =>
      !Object.values(categories || {})
        .flat()
        .filter((item) => {
          const menuNotAvailable = isEmpty(
            get(item, ["dates", week[dayIndex].seconds, "menu"])
          );
          const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
          const schoolDayDisabled = get(item, [
            "schools",
            schoolId,
            "days",
            dayIndex,
            "disabled",
          ]);

          return !dayDisabled && !schoolDayDisabled && !menuNotAvailable;
        })?.length,
    [categories, dayIndex, week]
  );

  const expandable = !menuIsEmpty;

  const isCurrentSelected = dayIndex === selectedDayIndex;
  const isToday = useMemo(
    () => moment(date.toDate()).isSame(moment(), "days"),
    [date]
  );
  const [opened, setOpened] = useState(isCurrentSelected || isToday);
  const titleRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setDayIndex(dayIndex);

    if (!isMobile) return;

    setOpened((state) => !state);

    /** ScrollIntoView works doesn't work correct and the box is being covered by header in some cases. */
    scroll2Elem(titleRef.current, { offsetY: 200 });
  }, [dayIndex, setDayIndex, isMobile]);

  useEffect(() => {
    if (!isToday || innerAppLoading) return;

    setDayIndex(dayIndex);

    if (isMobile) {
      scroll2Elem(titleRef.current, { offsetY: 200 });
    }
  }, [innerAppLoading]);

  const dayName = moment.unix(date.seconds).format("dddd [d.] D/M");

  const showFullInfo: boolean = isMobile ? opened : false;

  return (
    <div
      className={classnames({
        "p-4 pt-8 md:pt-4 border-b md:border md:border-r-0 border-divider-main md:border-transparent cursor-pointer hover:md:border-divider-main transition":
          true,
        "md:bg-background-secondary": isCurrentSelected,
        "bg-background-secondary": isMobile && expandable,
        "bg-gradient-to-b from-background-primary to-background-secondary":
          (!opened || !expandable) && isMobile,
        "pointer-events-none": !expandable && isMobile,
        "rounded-l-lg": !isMobile,
      })}
      onClick={handleClick}
    >
      <div
        ref={titleRef}
        className="flex justify-between items-center mb-2 md:mb-0"
      >
        <div
          className={classnames([
            "h300",
            "text-primary-main font-medium transition",
          ])}
        >
          {isMobile ? dayName?.toUpperCase() : capitalize(dayName)}
        </div>
        {isMobile && expandable && (
          <PlusOutlined
            className={classnames([
              opened && "rotate-45",
              "transition text-text-primary",
            ])}
            style={{ fontSize: "1.2em" }}
          />
        )}
      </div>

      {/* The item is expandable and opened. */}
      <AnimateHeight
        duration={500}
        height={showFullInfo ? "auto" : 0}
        animateOpacity
      >
        {Object.entries(categories).map(([categoryName, categoryMenus]) => {
          return (
            <DayDetailCategory
              overrideDayIndex={dayIndex}
              key={categoryName}
              categoryName={categoryName}
              items={categoryMenus}
            />
          );
        })}
      </AnimateHeight>

      {/* The expandable item is closed or the item isn't expandable. */}
      <AnimateHeight
        duration={500}
        height={showFullInfo ? (!expandable ? "auto" : 0) : "auto"}
        animateOpacity
      >
        {menuIsEmpty && (
          <p className="text-text-disabled pt-1">
            {t("mealplan2:menu.not-available")}
          </p>
        )}
        {!menuIsEmpty &&
          highlightedItems
            .slice(0, 2)
            .map((product) => (
              <Product
                product={product}
                dayIndex={dayIndex}
                key={product?.id}
              />
            ))}
      </AnimateHeight>
    </div>
  );
};

export default Day;
